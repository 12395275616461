import { Flex } from 'cdk'
import { SPACING_XS } from 'design-tokens'
import { Icon } from 'icons'
import { type PropsWithChildren } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link } from 'ui'
import { type Route } from '~/utils/getRoute'

type Props = PropsWithChildren<{
  to: Route
}>

export const MenuLink = ({ children, to }: Props) => (
  <Link as={ReactRouterLink} colorName="darker" colorNameHover="primary" to={to}>
    <Flex $align="center" $direction="row" $gap={SPACING_XS} $py={8}>
      <Flex $align="center" $direction="row" $grow={1} $shrink={1}>
        {children}
      </Flex>
      <Flex>
        <Icon colorName="primary" name="arrow-right" size={24} />
      </Flex>
    </Flex>
  </Link>
)
