import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEventView } from '~/domains/analytics/hooks/useTrackEventView'
import { ModalTitle } from '~/domains/modals/components/ModalTitle'
import { ReactHookFormRadioField, useReactHookFormContext } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { type TranslationId } from '~/i18n/types'
import { type ChangeTherapistReason } from '~/types/graphql'
import { getChangeTherapistModalRoutes } from '../utils/routes'

type FormValues = {
  changeTherapistReason: ChangeTherapistReason
}

type Option = {
  id: TranslationId
  value: ChangeTherapistReason
}

export const changeTherapistReasonManualValues: Option[] = [
  { id: 'changeTherapist.reason.variant.badExperience', value: 'BAD_EXPERIENCE' },
  { id: 'changeTherapist.reason.variant.therapistLocation', value: 'THERAPIST_LOCATION' },
  { id: 'changeTherapist.reason.variant.lackOfExperience', value: 'LACK_OF_EXPERIENCE' },
  { id: 'changeTherapist.reason.variant.needToChange', value: 'NEED_TO_CHANGE' },
  { id: 'changeTherapist.reason.variant.other.step.2', value: 'OTHER' },
]

const ChangeTherapistReasonVariantManualReasons = () => {
  useTrackEventView('changeTherapist.modal.manual')

  const {
    errors: { required },
  } = useFormTranslations()

  const { formState } = useReactHookFormContext<FormValues>()

  const hasError = !!formState.errors.changeTherapistReason?.message

  return (
    <Flex>
      {hasError && (
        <Flex pt={16}>
          <Text colorName="red-80" kind="caption">
            {required}
          </Text>
        </Flex>
      )}

      {changeTherapistReasonManualValues.map(({ id, value }) => (
        <Flex key={id} as="label" pt={16}>
          <ReactHookFormRadioField name="changeTherapistReason" rules={{ required }} value={value}>
            <Flex grow={1} justify="center">
              <Text kind="paragraph">
                <Translation id={id} />
              </Text>
            </Flex>
          </ReactHookFormRadioField>
        </Flex>
      ))}
    </Flex>
  )
}

export const ChangeTherapistReasonManual = () => {
  const history = useHistory()

  const form = useReactHookFormContext<FormValues>()

  const onSubmit = useCallback(() => {
    history.push(getChangeTherapistModalRoutes('/changeTherapistReasonOpen'))
  }, [history])

  const onBackClick = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <OverflowAuto>
        <ModalTitle id="changeTherapist.modal.variant.step.2.title" />

        <TranslationMarkdown id="changeTherapist.modal.variant.step.2.description" kind="paragraph" />

        <Flex pt={16}>
          <ChangeTherapistReasonVariantManualReasons />
        </Flex>
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="primary" type="submit">
          <Translation id="actions.proceed" />
        </Button>
        <Flex pt={8}>
          <Button kind="secondary" onClick={onBackClick}>
            <Translation id="actions.back" />
          </Button>
        </Flex>
      </Flex>
    </Form>
  )
}
