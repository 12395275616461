import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisTicket = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.503 22.8619c-1.03027.1966-1.98587-.3226-2.34935-1.3086-.27869-.764-.53689-1.5354-.77432-2.3133-.12783-.4156.06383-.853.37017-1.1607.34932-.3494.6008-.7848.72913-1.2623.12834-.4775.129-.9802.00194-1.4577-.12766-.4773-.37849-.9123-.72725-1.2612-.34876-.349-.78316-.5997-1.25952-.7268-.41857-.113-.80222-.3955-.89822-.8192-.22781-1.0089-.37979-1.7935-.48159-2.3936-.175825-1.03604.39336-1.96565 1.38332-2.31337 1.32032-.46341 3.61845-1.19636 7.44488-2.2264C13.7686 4.5887 16.1235 4.06862 17.4973 3.80731c1.0302-.19659 1.9858.32257 2.3493 1.30856.2104.57041.4688 1.32637.7735 2.31407.1283.41545-.0639.85247-.3701 1.16062-.3491.34955-.6004.78492-.7285 1.26235-.1282.47739-.1288.98009-.0017 1.45749.1277.4773.3785.9124.7273 1.2614.3487.3491.7832.5998 1.2596.727.4185.1126.8021.3951.8981.8187.1819.7934.3425 1.5916.4816 2.3937.1758 1.036-.3934 1.9656-1.3833 2.3133-1.3199.4633-3.6186 1.1959-7.4446 2.2259-3.8264 1.03-6.18176 1.5502-7.5555 1.8115Z"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="m16.294 18.5087.455 1.7032M15.0807 13.9667l.4853 1.8167M13.8678 9.42468l.4853 1.81672M12.6847 4.99695l.4549 1.70316"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)
