import { useCallback, useState } from 'react'
import { matchPath } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { type ModalId, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useJournalingContext } from '~/routes/journaling/hooks/useJournalingContext'
import { getRoute } from '~/utils/getRoute'
import { type FormValues } from '../types/formValues'

type TherapistInformations = {
  firstName: string
  fullName: string
  id: string
  profileImage: {
    s: string
    m: string
    l: string
  } | null
}

type Props = {
  journalingLogId: string
  sharedWithTherapistUserIds?: string[] | null
}

export const StepBookedSecond = ({ journalingLogId, sharedWithTherapistUserIds }: Props) => {
  const { therapy, therapists } = usePatientTherapies()

  const { close } = useModals()

  const history = useRootHistory()

  const {
    journalingLogShare: [journalingLogShare, { loading }],
  } = useJournalingContext()

  const modalId: ModalId = `journalingLogShareWithMultipleTherapists:${journalingLogId}`

  const form = useReactHookFormContext<FormValues>()

  const formValues = form.watch()

  const therapistIdsToShareWith = Object.entries(formValues)
    .filter(([, val]) => val)
    .map(([key]) => key)
    .filter((id) => sharedWithTherapistUserIds != null && !sharedWithTherapistUserIds.includes(id))

  const [therapistsToShareWith] = useState<TherapistInformations[]>(
    therapists.filter((therapist) => therapistIdsToShareWith.includes(therapist.id)),
  )

  const [sharedSuccess, setSharedSuccess] = useState(false)

  const isTipsOfLove = matchPath(history.location.pathname, { path: getRoute('/journaling/tips-of-love') }) != null

  const onSubmit = useCallback(() => {
    if (therapy?.therapist == null) {
      return
    }

    journalingLogShare({
      journalingLogId,
      therapistId: therapy?.therapist.id,
      therapistIds: therapistIdsToShareWith,
      onSuccess: () => {
        setSharedSuccess(true)
      },
    })
  }, [journalingLogId, journalingLogShare, therapy?.therapist, therapistIdsToShareWith])

  const onCloseClick = useCallback(() => {
    close(modalId)

    if (isTipsOfLove) {
      history.goBack()
    }
  }, [close, isTipsOfLove, history, modalId])

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <ModalCloseButton id={modalId} />

      {!sharedSuccess && (
        <>
          <OverflowAuto>
            <Text fontWeight="600" kind="h2">
              <Translation id="journaling.tipsOfLove.share.confirm.title" />
            </Text>
            <Flex pt={16}>
              {therapistsToShareWith.length === 1 && (
                <TranslationMarkdown
                  id="journaling.tipsOfLove.share.confirm.body"
                  kind="paragraph"
                  values={{
                    therapistFullName: therapistsToShareWith[0].fullName,
                  }}
                />
              )}
              {therapistsToShareWith.length === 2 && (
                <TranslationMarkdown
                  id="journaling.tipsOfLove.share.confirm.2.body"
                  kind="paragraph"
                  values={{
                    firstTherapistFullName: therapistsToShareWith[0].fullName,
                    secondTherapistFullName: therapistsToShareWith[1].fullName,
                  }}
                />
              )}
              {therapistsToShareWith.length === 3 && (
                <TranslationMarkdown
                  id="journaling.tipsOfLove.share.confirm.3.body"
                  kind="paragraph"
                  values={{
                    firstTherapistFullName: therapistsToShareWith[0].fullName,
                    secondTherapistFullName: therapistsToShareWith[1].fullName,
                    thirdTherapistFullName: therapistsToShareWith[2].fullName,
                  }}
                />
              )}
            </Flex>
          </OverflowAuto>
          <Button kind="primary" loading={loading} type="submit">
            <Translation id="journaling.tipsOfLove.share.confirm.confirm" />
          </Button>
          <Flex pt={8}>
            <Button disabled={loading} kind="secondary" onClick={onCloseClick}>
              <Translation id="journaling.tipsOfLove.share.confirm.dismiss" />
            </Button>
          </Flex>
        </>
      )}

      {sharedSuccess && (
        <>
          <Flex grow={1} mdPb={48}>
            <Text fontWeight="600" kind="h2">
              <Translation id="journaling.tipsOfLove.share.success.title" />
            </Text>
            {therapistsToShareWith.length === 1 && (
              <TranslationMarkdown
                id="journaling.tipsOfLove.share.success.body"
                kind="paragraph"
                pt={16}
                values={{
                  therapistFullName: therapistsToShareWith[0].fullName,
                }}
              />
            )}
            {therapistsToShareWith.length === 2 && (
              <TranslationMarkdown
                id="journaling.tipsOfLove.share.success.2.body"
                kind="paragraph"
                pt={16}
                values={{
                  firstTherapistFullName: therapistsToShareWith[0].fullName,
                  secondTherapistFullName: therapistsToShareWith[1].fullName,
                }}
              />
            )}
            {therapistsToShareWith.length === 3 && (
              <TranslationMarkdown
                id="journaling.tipsOfLove.share.success.3.body"
                kind="paragraph"
                pt={16}
                values={{
                  firstTherapistFullName: therapistsToShareWith[0].fullName,
                  secondTherapistFullName: therapistsToShareWith[1].fullName,
                  thirdTherapistFullName: therapistsToShareWith[2].fullName,
                }}
              />
            )}
          </Flex>
          <Button kind="primary" loading={loading} onClick={onCloseClick}>
            <Translation id="journaling.tipsOfLove.share.success.close" />
          </Button>
        </>
      )}
    </Form>
  )
}
