import config from 'next/config'

type Environment = 'development' | 'production' | 'local'

type EnvironmentVariable =
  | 'API_GATEWAY_PROXY_URL'
  | 'API_GATEWAY_URL'
  | 'COGNITO_AUTH_COOKIE_DOMAIN'
  | 'COGNITO_AUTH_COOKIE_SECURE'
  | 'COGNITO_IDP_DOMAIN'
  | 'COGNITO_REDIRECT_SIGN_IN'
  | 'COGNITO_REDIRECT_SIGN_OUT'
  | 'COGNITO_REGION'
  | 'COGNITO_USER_POOL_CLIENT_ID'
  | 'COGNITO_USER_POOL_ID'
  | 'COURIER_CLIENT_KEY'
  | 'FLAGSMITH_ENVIRONMENT_ID'
  | 'GESTO_APP_URL'
  | 'GTM_ID'
  | 'INTERCOM_APP_ID'
  | 'LANGUAGE_COOKIE_DOMAIN'
  | 'MIXPANEL_TOKEN'
  | 'SENTRY_DSN'
  | 'SENTRY_ENVIRONMENT'
  | 'SERENIS_APP_STORE'
  | 'SERENIS_APP_URL'
  | 'SERENIS_AVAILABILITY_ADD_POLICY'
  | 'SERENIS_EMAIL_VERIFICATION_MAX_ATTEMPTS'
  | 'SERENIS_PHONE_NUMBER_VERIFICATION_MAX_ATTEMPTS'
  | 'SERENIS_PLAY_STORE'
  | 'SERENIS_THERAPY_SESSION_DEFAULT_AVAILABILITY_DURATION'
  | 'SERENIS_THERAPY_SESSION_NUTRITION_AVAILABILITY_DURATION'
  | 'SERENIS_THERAPY_SESSION_CANCELLATION_POLICY_PSYCHIATRY'
  | 'SERENIS_THERAPY_SESSION_CANCELLATION_POLICY'
  | 'SERENIS_THERAPY_SESSION_COST_ONLINE_COUPLE'
  | 'SERENIS_THERAPY_SESSION_COST_ONLINE_DCA_FIRST'
  | 'SERENIS_THERAPY_SESSION_COST_ONLINE_DCA'
  | 'SERENIS_THERAPY_SESSION_COST_ONLINE_MYSELF'
  | 'SERENIS_THERAPY_SESSION_COST_ONLINE_COACHING'
  | 'SERENIS_THERAPY_SESSION_COST_ONLINE_PSYCHIATRY_FIRST'
  | 'SERENIS_THERAPY_SESSION_COST_ONLINE_PSYCHIATRY'
  | 'SERENIS_THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS_FIRST'
  | 'SERENIS_THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS'
  | 'SERENIS_THERAPY_SESSION_MEETING_DURATION_COUPLES'
  | 'SERENIS_THERAPY_SESSION_MEETING_DURATION_MYSELF'
  | 'SERENIS_THERAPY_SESSION_MEETING_DURATION_PSYCHIATRY'
  | 'SERENIS_TRUST_PILOT'
  | 'SERENIS_WEBSITE_URL'
  | 'STREAM_CHAT_KEY'
  | 'STRIPE_API_KEY_PUBLISHABLE'
  | 'STS_PUBLIC_KEY'
  | 'STYLE_COOKIE_DOMAIN'

type Variable = Record<EnvironmentVariable, string | number | boolean>

const variables: Record<Environment, Partial<Variable>> = {
  production: {
    API_GATEWAY_PROXY_URL: 'https://api.serenis.it/proxy',
    API_GATEWAY_URL: 'https://api.serenis.it/graphql',
    COGNITO_AUTH_COOKIE_DOMAIN: 'app.serenis.it',
    COGNITO_AUTH_COOKIE_SECURE: true,
    COGNITO_IDP_DOMAIN: 'login.serenis.it',
    COGNITO_REDIRECT_SIGN_IN: 'https://app.serenis.it/token',
    COGNITO_REDIRECT_SIGN_OUT: 'https://app.serenis.it',
    COGNITO_REGION: 'eu-central-1',
    COGNITO_USER_POOL_CLIENT_ID: '61ltj1mu7tlv37oj2r2m76imj6',
    COGNITO_USER_POOL_ID: 'eu-central-1_0vuQMZitZ',
    COURIER_CLIENT_KEY: 'YmViOTA0NTEtZGI5MS00ODQzLTgxOTYtNDYzMWFlNTFkZmQy',
    FLAGSMITH_ENVIRONMENT_ID: '7QeBPri7creENzXiTwcFey',
    GESTO_APP_URL: 'https://app.gesto.it',
    GTM_ID: 'GTM-TFFTF9G',
    INTERCOM_APP_ID: 'm4m3pgdo',
    LANGUAGE_COOKIE_DOMAIN: 'app.serenis.it',
    STYLE_COOKIE_DOMAIN: 'app.serenis.it',
    MIXPANEL_TOKEN: 'ae759021b58d1146ac16dad6af765cd3',
    SENTRY_ENVIRONMENT: 'production',
    SERENIS_APP_URL: 'https://app.serenis.it',
    STREAM_CHAT_KEY: 'pgrheeq6fu75',
    STS_PUBLIC_KEY:
      '-----BEGIN RSA PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhb2jhJYdga+bceaNBSLz\nqny6WTPIdqditMEMUwXtO5xrQxFkZCAC+fJhA8E9k82L8hDAOkFsZD5jTuEiCdPq\n68oP4yXGy76YcjS4fkhz5Uzj0uv1AyX82N0FaOqgeAosrOPSRI3A8qTJ/CNPw0n/\nM84GtwkTlkFkN4bHAM4yOJ+jBj/Kecd3gn/AtFpVDvO7uR/d2jH8/YgbVZ+hE3N6\nELqMEM4kMC0kMjynHE+CuHD1PwbcLtbwz1dx9sdmSjwzG3NSSSdW5M2cM5mnZI9j\nZ/aKqqEFi73EdV8fDyto/SLWA30DmgyYvjO8RbQ3b18LMYkYreO6OCEpdW6v4oln\njwIDAQAB\n-----END RSA PUBLIC KEY-----',
    STRIPE_API_KEY_PUBLISHABLE:
      'pk_live_51IyyhSHOgUx3p2AGff19x6ns3jwSp7w4IHmHzOFLNBc3USQS6B28nmn1m6r4qaafDodRnvZlvssWECJ3E0uycpGW00p1rGO6Qb',
  },
  development: {
    API_GATEWAY_PROXY_URL: 'https://api.development.serenis.it/proxy',
    API_GATEWAY_URL: 'https://api.development.serenis.it/graphql',
    COGNITO_AUTH_COOKIE_DOMAIN: 'development.serenis.it',
    COGNITO_AUTH_COOKIE_SECURE: true,
    COGNITO_IDP_DOMAIN: 'serenis-development.auth.eu-central-1.amazoncognito.com',
    COGNITO_REDIRECT_SIGN_IN: 'https://app.development.serenis.it/token',
    COGNITO_REDIRECT_SIGN_OUT: 'https://app.development.serenis.it',
    COGNITO_REGION: 'eu-central-1',
    COURIER_CLIENT_KEY: 'YmViOTA0NTEtZGI5MS00ODQzLTgxOTYtNDYzMWFlNTFkZmQy',
    FLAGSMITH_ENVIRONMENT_ID: 'HshfZmtVsgzSSvvNHYDQ79',
    GESTO_APP_URL: 'https://app.development.gesto.it',
    LANGUAGE_COOKIE_DOMAIN: 'app.development.serenis.it',
    STYLE_COOKIE_DOMAIN: 'app.development.serenis.it',
    SENTRY_ENVIRONMENT: 'staging',
    SERENIS_APP_URL: 'https://app.development.serenis.it',
    STREAM_CHAT_KEY: 'ja5h27sx6us7',
    STS_PUBLIC_KEY:
      '-----BEGIN RSA PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAs1aQ4IacwupSJCuM1O8d\ntoeOJt19bFlVjIWOSgsK9bpg3GBqU/UJH2kRYJlIIrnOel2pt8eLoQ6McmyZ/cJY\nY/qlj7z5GdIoQUKGQe5jJHPh+syydpDRWPBnkfnUUvDnUxL8VPH3BFeYWeEdRNv5\n1Mv1+3lF9LJBVSLSIWi0vPo6mf8FJtO98gEg7RkwfcxvC86UyCWFONmpg9ib1zOn\n7WoJ3kQpsrp5455OgP1rGSRfzOvC9ZcDIerduyR9ZZ9L5KDTb6ZIbSS77kmpgVCn\n7MwQZi0Auw2Xv0Xcn2WzhxiTVyYaiblUUDcLleyChJKl4OzLPHfWmIDq5tvGTy8a\n8wIDAQAB\n-----END RSA PUBLIC KEY-----',
  },
  local: {
    API_GATEWAY_PROXY_URL: 'http://localhost:3000/proxy',
    API_GATEWAY_URL: 'http://localhost:3000/graphql',
    COGNITO_AUTH_COOKIE_DOMAIN: 'localhost',
    COGNITO_AUTH_COOKIE_SECURE: false,
    COGNITO_IDP_DOMAIN: 'serenis-development.auth.eu-central-1.amazoncognito.com',
    COGNITO_REDIRECT_SIGN_IN: 'http://localhost:5000/token',
    COGNITO_REDIRECT_SIGN_OUT: 'http://localhost:5000',
    COGNITO_REGION: 'eu-central-1',
    COGNITO_USER_POOL_CLIENT_ID: '11unbdpgsv3f9rfojdtqop2hq9',
    COGNITO_USER_POOL_ID: 'eu-central-1_eSoaEP7SX',
    COURIER_CLIENT_KEY: 'YmViOTA0NTEtZGI5MS00ODQzLTgxOTYtNDYzMWFlNTFkZmQyL3Rlc3Q=',
    FLAGSMITH_ENVIRONMENT_ID: 'UZzqbUxxA6CVrqLY9yU9UC',
    GESTO_APP_URL: 'http://localhost:5003',
    GTM_ID: 'GTM-TVPKM52',
    INTERCOM_APP_ID: 'krn3jhep',
    MIXPANEL_TOKEN: 'b69d759eee3367ba78b69ee1bdceb02d',
    LANGUAGE_COOKIE_DOMAIN: 'localhost',
    STYLE_COOKIE_DOMAIN: 'localhost',
    SENTRY_DSN: 'https://4a74a902f52646c9afa0b9d34e3290b8@o913412.ingest.sentry.io/5869155',
    SENTRY_ENVIRONMENT: 'development',
    SERENIS_APP_STORE: 'https://apps.apple.com/app/serenis-psicoterapia-online/id1631510145',
    SERENIS_APP_URL: 'http://localhost:5000',
    SERENIS_AVAILABILITY_ADD_POLICY: 259200,
    SERENIS_EMAIL_VERIFICATION_MAX_ATTEMPTS: 3,
    SERENIS_PHONE_NUMBER_VERIFICATION_MAX_ATTEMPTS: 3,
    SERENIS_PLAY_STORE: 'https://play.google.com/store/apps/details?id=it.serenis.app',
    SERENIS_THERAPY_SESSION_DEFAULT_AVAILABILITY_DURATION: 3600,
    SERENIS_THERAPY_SESSION_NUTRITION_AVAILABILITY_DURATION: 1800,
    SERENIS_THERAPY_SESSION_CANCELLATION_POLICY_PSYCHIATRY: 172800,
    SERENIS_THERAPY_SESSION_CANCELLATION_POLICY: 86400,
    SERENIS_THERAPY_SESSION_COST_ONLINE_COUPLE: 5900,
    SERENIS_THERAPY_SESSION_COST_ONLINE_MYSELF: 4900,
    SERENIS_THERAPY_SESSION_COST_ONLINE_COACHING: 5500,
    SERENIS_THERAPY_SESSION_COST_ONLINE_DCA_FIRST: 7700,
    SERENIS_THERAPY_SESSION_COST_ONLINE_DCA: 5500,
    SERENIS_THERAPY_SESSION_COST_ONLINE_PSYCHIATRY_FIRST: 8900,
    SERENIS_THERAPY_SESSION_COST_ONLINE_PSYCHIATRY: 7700,
    SERENIS_THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS_FIRST: 7700,
    SERENIS_THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS: 4000,
    SERENIS_THERAPY_SESSION_MEETING_DURATION_COUPLES: 3300,
    SERENIS_THERAPY_SESSION_MEETING_DURATION_MYSELF: 2700,
    SERENIS_THERAPY_SESSION_MEETING_DURATION_PSYCHIATRY: 1800,
    SERENIS_TRUST_PILOT: 'https://it.trustpilot.com/review/serenis.it',
    SERENIS_WEBSITE_URL: 'https://www.serenis.it',
    STREAM_CHAT_KEY: '8xhdfp9zv8q3',
    STRIPE_API_KEY_PUBLISHABLE:
      'pk_test_51IyyhSHOgUx3p2AG7tgYk5hThc0WLZy4v0fvch96eU6XMrtL5gDYQzvhqDzc1etABbJG6BH2oD5AH325NzUtk1Kj00Y7cwrG4M',
    STS_PUBLIC_KEY:
      '-----BEGIN RSA PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvfb2fIZ8U4WfGnYf64jr\nAI02Q9YBMSxtkSZefnjGSq7u1PWmNuQkM0I/eJo7Mvk75J3grQB91GZ9nEvbYRRV\nPv5Wz+cnqXXqTiUcHOt6oNM4B/GGZVN0JU1u5YNyGUj8PhrinqBRFN6hH7L71KXM\nOZ2605g9qh1ZVYMP2B/8X56Cs386X7QCnXHBsjXHPojUgpu7B8CgxCePljZUqwm0\n8Mr/xQ/1tPzOBQhLGgL/rryRy9xtVW80O+B7T0uySGDW70xepts1WABdJ2EzC71f\nfIYO2Rhgl4HlpvzMGmnWINsfC4OPr8XqyPP3y8INaanrBh+Z5ChSIFApIJiqU05Z\n2wIDAQAB-----END RSA PUBLIC KEY-----',
  },
}

const { publicRuntimeConfig = {} } = config()

const getEnvironment = (): Environment =>
  publicRuntimeConfig.ENVIRONMENT ?? process.env.ENVIRONMENT ?? process.env.NEXT_PUBLIC_ENVIROMENT ?? 'local'

export const getEnv = (variable: EnvironmentVariable) => {
  const environment = getEnvironment()

  if (environment === 'production') {
    return String(variables[environment][variable] ?? variables.development[variable] ?? variables.local[variable])
  }

  if (environment === 'development') {
    return String(variables[environment][variable] ?? variables.local[variable])
  }

  return String(variables[environment][variable])
}
