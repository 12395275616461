import { useCallback } from 'react'
import { useTrackEvent } from './useTrackEvent'

export type EventClickId =
  | 'agenda.download-clinical-bond-response'
  | 'agenda.download-form-flow-response'
  | 'booking.calendar-day'
  | 'booking.calendar-hour'
  | 'clinical-test.chat.patient.read-answers'
  | 'clinical-test.chat.patient.start'
  | 'clinical-test.chat.therapist.read-answers'
  | 'clinical-test.exit-confirmation-modal.cancel-closing'
  | 'clinical-test.exit-confirmation-modal.return-to-chat'
  | 'clinical-test.send-to-patient-chat.send'
  | 'clinical-test.step.end.return-to-chat'
  | 'clinical-test.step.intro.start'
  | 'clinical-test.step.question.next'
  | 'clinical-test.step.question.previous'
  | 'clinical-test.step.question.send'
  | 'request-nutritionist'
  | 'reservation.calendar-day'
  | 'reservation.calendar-hour'
  | 'reservation.hardcoded-unavailability-selected'
  | 'settings-invite.from-mgm-card'
  | 'settings-invite.from-mgm-icon'
  | 'settings-invite.from-menu'
  | 'share.journaling-reward'
  | 'share.journaling-score'
  | 'share.sentence'
  | 'share.sticker'
  | 'share.therapy-session'
  | 'support.i-cant-find-slot'
  | 'support.i-want-to-change-therapis'
  | 'support.i-want-to-edit-therapy-session'
  | 'support.leave-feedback'
  | 'support.open-intercom'
  | 'thank-you-page.download-calendar-event'
  | 'thank-you-page.download-app'
  | 'thank-you-page.open-chat'
  | 'thank-you-page.go-to-homepage'

type Payload = Parameters<ReturnType<typeof useTrackEvent>>[number]['payload']

export const useTrackEventClick = () => {
  const trackEvent = useTrackEvent('CLICK')

  return useCallback(
    (id: EventClickId, payload?: Payload) => {
      trackEvent({ name: `clicks.${id}`, payload })
    },
    [trackEvent],
  )
}
