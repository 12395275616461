import { gql, useQuery } from '@apollo/client'
import { type Interval } from 'date-fns/fp'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { type AvailabilitiesByUserIdQuery, type AvailabilitiesByUserIdQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query availabilitiesByUserId($userId: String!, $startAt: Date!, $endAt: Date!) {
    availabilitiesByUserId(userId: $userId, startAt: $startAt, endAt: $endAt) {
      id
      available
      endAt
      startAt
      therapySession {
        id
        patient {
          id
          firstName
          fullName
        }
        status
        therapyPathType
        typeCost
      }
      type
    }
  }
`

export const useAvailabilitiesByUserId = (interval: Interval) => {
  const { id: userId } = useCurrentUser()

  const { data, error, loading, refetch, updateQuery } = useQuery<
    AvailabilitiesByUserIdQuery,
    AvailabilitiesByUserIdQueryVariables
  >(QUERY, {
    variables: {
      endAt: new Date(interval.end),
      startAt: new Date(interval.start),
      userId,
    },
  })

  return {
    error: !!error,
    items: data?.availabilitiesByUserId || [],
    loading,
    refetch,
    updateQuery,
  }
}
