import { Flex, MountOn, Pressable } from 'cdk'
import { differenceInYears, setYear } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { SPACING_3XS, SPACING_MD, SPACING_XS } from 'design-tokens'
import { pipe } from 'fp-ts/function'
import { Icon } from 'icons'
import Zoom from 'react-medium-image-zoom'
import styled from 'styled-components'
import { Avatar, Button, Divider, Text, type TextProps } from 'ui'
import { CardBox } from 'ui-deprecated'
import { getTherapistJobTitleTranslationId } from '~/components/TherapistDetailCard/utils/getTherapistJobTitleTranslationId'
import { Translation } from '~/components/Translation'
import { useTherapistSpecializations } from '~/domains/agenda/hooks/useTherapistSpecializations'
import { TherapistBadgeCertifications } from '~/domains/therapist/components/TherapistBadgeCertifications'
import { TherapistOrientation } from '~/domains/therapist/components/TherapistOrientation'
import { TherapistOverviewCardDetail } from '~/domains/therapist/TherapistOverviewCard/Detail'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useTherapist } from '~/hooks/useTherapist'
import { useTherapistProfessionTypeValue } from '~/hooks/useTherapistProfessionTypeValue'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { shouldShowYearsOfExperience } from '~/utils/shouldShowYearsOfExperience'
import { useSuspenseTherapyPathByIdQuery } from '../../hooks/useTherapyPathByIdQuery'

const TherapistCardBox = styled(CardBox)`
  z-index: 1;
`

const TherapistFullName = ({ fullName, kind }: { fullName: string; kind: TextProps['kind'] }) => (
  <Text colorName="black" data-test-id="therapist-full-name" fontWeight="600" kind={kind} textAlign="left">
    {fullName}
  </Text>
)

type SuggestedTherapistMoreInfoButtonProps = {
  emailVerified: boolean
  therapyPath: TherapyTherapyPathType
}

const SuggestedTherapistMoreInfoButton = ({ emailVerified, therapyPath }: SuggestedTherapistMoreInfoButtonProps) => {
  if (!emailVerified) {
    return null
  }

  return (
    <TherapistOverviewCardDetail therapyPath={therapyPath}>
      <Flex $pt={SPACING_MD}>
        <Button kind="secondary" size="sm" type="button">
          <Translation id="suggestedTherapist.moreInfo" />
        </Button>
      </Flex>
    </TherapistOverviewCardDetail>
  )
}

type CertificationBadgesProps = {
  therapyPath: TherapyTherapyPathType
}

const Certifications = ({ therapyPath }: CertificationBadgesProps) => (
  <Flex $maxWidth="fit-content" style={{ margin: 0 }}>
    <TherapistOverviewCardDetail therapyPath={therapyPath ?? 'MYSELF_PSYCHOTHERAPY'}>
      <Pressable>
        <TherapistBadgeCertifications />
      </Pressable>
    </TherapistOverviewCardDetail>
  </Flex>
)

export const TherapistCard = () => {
  const {
    fullName,
    therapist: {
      numberOfPatients,
      profileImage,
      registerRegistrationYearPsychologist,
      sex,
      therapySchool: { orientation },
    },
  } = useTherapist()

  const { emailVerified } = useCurrentUser()

  const { therapyPath } = useSuspenseTherapyPathByIdQuery()

  const jobTitleTranslationId = getTherapistJobTitleTranslationId({
    sex,
    therapyPathType: therapyPath.type,
  })

  const years = !registerRegistrationYearPsychologist
    ? 0
    : pipe(
        nowInMilliseconds(),
        differenceInYears(pipe(nowInMilliseconds(), setYear(registerRegistrationYearPsychologist))),
      )

  const { specializations } = useTherapistSpecializations()

  const showYearsOfExperience = shouldShowYearsOfExperience(years)

  const isTherapyCoaching = therapyPath.type === 'PATH_COACHING'

  const { isNutritionist, isPsychiatrist } = useTherapistProfessionTypeValue()
  const showNumberOfPatients = !isPsychiatrist && !isTherapyCoaching && numberOfPatients >= 10

  const showOrientation =
    !isNutritionist && therapyPath.type !== 'PATH_COACHING' && therapyPath.type !== 'MYSELF_PSYCHIATRY' && !!orientation

  return (
    <>
      <TherapistCardBox background="white" borderColor="purple06" borderSize={1} grow={1} shrink={1}>
        <MountOn mediaQuery="lt-lg">
          <>
            <MountOn mediaQuery="sm">
              <Flex>
                <Flex $direction="row">
                  <Zoom zoomImg={{ src: profileImage?.l }}>
                    <Avatar image={profileImage?.s} name={fullName} size="sm" />
                  </Zoom>
                  <Flex $shrink={1}>
                    <Flex $px={SPACING_XS}>
                      <TherapistFullName fullName={fullName} kind="paragraph" />
                    </Flex>
                    <Flex $mdAlign="flex-start" $pt={SPACING_3XS} $px={SPACING_XS}>
                      <Text colorName="grey-80" kind="caption" textAlign="left">
                        <Translation id={jobTitleTranslationId} />
                      </Text>

                      {showOrientation && <TherapistOrientation orientation={orientation} textKind="caption" />}
                    </Flex>
                  </Flex>
                </Flex>

                {!isNutritionist && <Certifications therapyPath={therapyPath.type} />}
              </Flex>
            </MountOn>
            <MountOn mediaQuery="md">
              <Flex>
                <Flex $direction="row">
                  <Zoom zoomImg={{ src: profileImage?.l }}>
                    <Avatar image={profileImage?.s} name={fullName} size="lg" />
                  </Zoom>
                  <Flex $shrink={1}>
                    <Flex $px={SPACING_XS}>
                      <TherapistFullName fullName={fullName} kind="h3" />
                    </Flex>
                    <Flex $mdAlign="flex-start" $pt={SPACING_3XS} $px={SPACING_XS}>
                      <Text colorName="grey-80" kind="paragraph">
                        <Translation id={jobTitleTranslationId} />
                      </Text>

                      {showOrientation && <TherapistOrientation orientation={orientation} />}
                    </Flex>
                  </Flex>
                </Flex>
                {!isNutritionist && <Certifications therapyPath={therapyPath.type} />}
              </Flex>
            </MountOn>
            <Flex $direction="row" $py={SPACING_MD}>
              {showYearsOfExperience && (
                <Flex $align="center" $direction="row" $pr={SPACING_MD} $pt={SPACING_3XS} $shrink={1}>
                  <Flex $pr={SPACING_XS}>
                    <Icon colorName="grey-80" name="brain" size={24} />
                  </Flex>
                  <Text kind="caption" textAlign="left">
                    <Translation id="therapist.ourChoice.yearOfExperience" values={{ years }} />
                  </Text>
                </Flex>
              )}

              {!isTherapyCoaching && showNumberOfPatients && (
                <Flex $align="center" $direction="row" $pt={SPACING_3XS} $shrink={1}>
                  <Flex $pr={SPACING_XS}>
                    <Icon colorName="grey-80" name="group" size={24} />
                  </Flex>
                  <Text kind="caption" textAlign="left">
                    <Translation id="therapist.ourChoice.numberOfPatients" values={{ numberOfPatients }} />
                  </Text>
                </Flex>
              )}
              {isTherapyCoaching && (
                <Flex $align="center" $direction="row" $pt={SPACING_3XS}>
                  <Flex $pr={SPACING_XS}>
                    <Icon colorName="grey-80" name="user-star" size={24} />
                  </Flex>
                  <Text kind="caption" textAlign="left">
                    <Translation id="therapist.ourChoice.certification" />
                  </Text>
                </Flex>
              )}
            </Flex>
            {!isTherapyCoaching && (
              <>
                <Divider color="purple06" />

                {specializations.length > 0 && (
                  <Flex $pt={SPACING_MD}>
                    <Text fontWeight="600" kind="paragraph" textAlign="left">
                      <Translation id="therapist.ourChoice.specializations" />
                    </Text>
                    {specializations.slice(0, 3).map(({ name, id }) => (
                      <Flex key={id} $align="center" $direction="row" $pt={SPACING_3XS}>
                        <Flex $pr={SPACING_XS}>
                          <Icon colorName="grey-80" name="book-stack" size={24} />
                        </Flex>
                        <Text kind="caption">{name}</Text>
                      </Flex>
                    ))}
                  </Flex>
                )}
              </>
            )}
          </>
          <SuggestedTherapistMoreInfoButton emailVerified={emailVerified} therapyPath={therapyPath.type} />
        </MountOn>
        <MountOn mediaQuery="gt-md">
          <Flex $direction="row">
            <Flex $basis="40%">
              <Flex $direction="row">
                <Zoom zoomImg={{ src: profileImage?.l }}>
                  <Avatar image={profileImage?.s} name={fullName} size="lg" />
                </Zoom>
                <Flex $shrink={1}>
                  <Flex $px={SPACING_XS}>
                    <TherapistFullName fullName={fullName} kind="h3" />
                  </Flex>
                  <Flex $mdAlign="flex-start" $pt={SPACING_3XS} $px={SPACING_XS}>
                    <Text colorName="grey-80" kind="paragraph">
                      <Translation id={jobTitleTranslationId} />
                    </Text>

                    {showOrientation && <TherapistOrientation orientation={orientation} />}
                  </Flex>
                </Flex>
              </Flex>
              {!isNutritionist && <Certifications therapyPath={therapyPath.type} />}
            </Flex>

            <Flex $basis="30%" $pl={SPACING_MD} $shrink={1}>
              {(showYearsOfExperience || showNumberOfPatients) && (
                <Text fontWeight="600" kind="paragraph" textAlign="left">
                  <Translation id="therapist.ourChoice.shortExperience" />
                </Text>
              )}
              {showYearsOfExperience && (
                <Flex $align="center" $direction="row" $pt={SPACING_3XS}>
                  <Flex $pr={SPACING_XS}>
                    <Icon colorName="grey-80" name="brain" size={24} />
                  </Flex>
                  <Text kind="caption" textAlign="left">
                    <Translation id="therapist.ourChoice.yearOfExperience" values={{ years }} />
                  </Text>
                </Flex>
              )}
              {!isTherapyCoaching && showNumberOfPatients && (
                <Flex $align="center" $direction="row" $pt={SPACING_3XS}>
                  <Flex $pr={SPACING_XS}>
                    <Icon colorName="grey-80" name="group" size={24} />
                  </Flex>
                  <Text kind="caption" textAlign="left">
                    <Translation id="therapist.ourChoice.numberOfPatients" values={{ numberOfPatients }} />
                  </Text>
                </Flex>
              )}
              {isTherapyCoaching && (
                <Flex $align="center" $direction="row" $pt={SPACING_3XS}>
                  <Flex $pr={SPACING_XS}>
                    <Icon colorName="grey-80" name="user-star" size={24} />
                  </Flex>
                  <Text kind="caption" textAlign="left">
                    <Translation id="therapist.ourChoice.certification" />
                  </Text>
                </Flex>
              )}
            </Flex>
            {!isTherapyCoaching && (
              <Flex $basis="30%">
                {specializations.length > 0 && (
                  <Flex $pl={SPACING_MD} $shrink={1}>
                    <Text fontWeight="600" kind="paragraph" textAlign="left">
                      <Translation id="therapist.ourChoice.specializations" />
                    </Text>
                    {specializations.slice(0, 3).map(({ name }) => (
                      <Flex key={name} $align="center" $direction="row" $pt={SPACING_3XS}>
                        <Flex $pr={SPACING_XS}>
                          <Icon colorName="grey-80" name="book-stack" size={24} />
                        </Flex>
                        <Text kind="caption" textAlign="left">
                          {name}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
          <SuggestedTherapistMoreInfoButton emailVerified={emailVerified} therapyPath={therapyPath.type} />
        </MountOn>
      </TherapistCardBox>
    </>
  )
}
