import { Flex, Form, PageLayout } from 'cdk'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { PageRoute } from '~/components/PageRoute'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { usePhoneVerificationContext } from '~/domains/phoneNumbers/hooks/usePhoneVerificationContext'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { Head } from '../../components/Head'
import { type ReservationFormValues } from '../../types'
import { getReservationRoutes } from '../../utils/getReservationRoutes'
import { SendVerificationCodePhoneField } from './field/PhoneNumberField'

export const SendVerificationCode = () => {
  const history = useHistory()

  const form = useReactHookFormContext<ReservationFormValues>()

  const { sendVerificationCode } = usePhoneVerificationContext()

  const onSubmit = useCallback(
    async ({ phoneNumber }: ReservationFormValues) => {
      if (!phoneNumber) {
        return
      }

      const { ok } = await sendVerificationCode(phoneNumber, false)

      if (!ok) {
        return
      }

      history.push(getReservationRoutes('/check-verification-code'))
    },
    [history, sendVerificationCode],
  )

  return (
    <>
      <PageScrollEffect />

      <PageRoute id="reservation.send-verification-code">
        <PageLayout $px={24} maxWidth="640px">
          <Head translationId="reservation.sendVerificationCode" />

          <Flex $pb={32}>
            <Form $grow={1} $shrink={1} id="reservationFlow" onSubmit={form.handleSubmit(onSubmit)}>
              <Flex>
                <Text fontWeight="600" kind="h2">
                  <Translation id="reservation.sendVerification.title" />
                </Text>
              </Flex>
              <TranslationMarkdown colorName="black" id="reservation.sendVerification.body" kind="paragraph" pt={16} />

              <Flex $pt={24}>
                <SendVerificationCodePhoneField />
              </Flex>
            </Form>
          </Flex>
        </PageLayout>
      </PageRoute>
    </>
  )
}
