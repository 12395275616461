import { type ComponentProps } from 'react'
import { type TextFieldNew } from 'ui-deprecated'
import { type ReactHookFormTextField } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'
import { SignupFormTextField } from './SignupFormTextField'

type Props = {
  disabled?: boolean
  hideLabel?: boolean
  placeholderType?: 'text' | 'example-number'
  readOnly?: boolean
  textAlign?: ComponentProps<typeof ReactHookFormTextField>['textAlign']
  size?: ComponentProps<typeof TextFieldNew>['size']
  required?: boolean
}

export const SignupFormPhoneNumberField = ({
  disabled = false,
  hideLabel = false,
  placeholderType = 'example-number',
  readOnly = false,
  required = true,
}: Props) => {
  const { errors, labels, placeholders } = useFormTranslations()

  const { isMobilePhone } = useFormValidation()

  const placeholder = placeholderType === 'text' ? placeholders.phoneNumberLongText : placeholders.phoneNumberExample

  return (
    <SignupFormTextField
      autoComplete="tel-national"
      disabled={disabled}
      label={hideLabel ? '' : labels.phoneNumber}
      name="phoneNumber"
      placeholder={placeholder}
      readOnly={readOnly}
      rules={{
        ...(required && { required: errors.required }),
        validate: (value) => {
          if (!required && !value.length) {
            return true
          }

          return isMobilePhone(value)
        },
      }}
      type="tel"
    />
  )
}
