import { pipe } from 'fp-ts/function'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getEnv } from '~/utils/getEnv'

type Param = {
  firstTherapySession: boolean
  therapyPathType: TherapyTherapyPathType | null
}

export const getTherapySessionMeetingDurationInSeconds = ({ firstTherapySession, therapyPathType }: Param) => {
  switch (therapyPathType) {
    case 'COUPLES_PSYCHOTHERAPY':
      return pipe('SERENIS_THERAPY_SESSION_MEETING_DURATION_COUPLES', getEnv, Number)
    case 'MYSELF_PSYCHIATRY':
      return firstTherapySession
        ? pipe('SERENIS_THERAPY_SESSION_MEETING_DURATION_MYSELF', getEnv, Number)
        : pipe('SERENIS_THERAPY_SESSION_MEETING_DURATION_PSYCHIATRY', getEnv, Number)
    default:
      return pipe('SERENIS_THERAPY_SESSION_MEETING_DURATION_MYSELF', getEnv, Number)
  }
}
