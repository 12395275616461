import { type TherapyTherapyPathType } from '~/types/graphql'

const REFERRAL_PATH_NOT_SUPPORTED = new Set(['MYSELF_PSYCHIATRY', 'PATH_NUTRITION_WEIGHT_LOSS', 'PATH_NUTRITION_DCA'])
const MEMBER_GET_MEMBER_PATH_NOT_SUPPORTED = new Set([
  'MYSELF_PSYCHIATRY',
  'PATH_NUTRITION_WEIGHT_LOSS',
  'PATH_NUTRITION_DCA',
])

export const isReferralSupported = (therapyPathType: TherapyTherapyPathType): boolean =>
  !REFERRAL_PATH_NOT_SUPPORTED.has(therapyPathType)

export const isMemberGetMemberSupported = (therapyPathType: TherapyTherapyPathType): boolean =>
  !MEMBER_GET_MEMBER_PATH_NOT_SUPPORTED.has(therapyPathType)
