import { isNeitherNullNorUndefined } from 'functions'
import { noop } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { type ModalId, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useJournalingLogCanShare } from '~/routes/journalingLog/hooks/useJournalingLogCanShare'
import { type FormValues } from '../types/formValues'

type Props = {
  journalingLogId: string
  sharedWithTherapistUserIds?: string[] | null
}

export const StepBookedFirst = ({ journalingLogId, sharedWithTherapistUserIds }: Props) => {
  const modalId: ModalId = `journalingLogShareWithMultipleTherapists:${journalingLogId}`

  const { close } = useModals()

  const { ongoing } = usePatientTherapies()

  const sharableTherapists = ongoing
    .filter((therapy) => !['MYSELF_PSYCHIATRY', 'PATH_COACHING'].includes(therapy.therapyPath.type))
    .map((therapy) => therapy.therapist)
    .filter(isNeitherNullNorUndefined)

  const form = useReactHookFormContext<FormValues>()

  const formValues = form.watch()

  const therapistIdsToShareWith = Object.entries(formValues)
    .filter(([, val]) => val)
    .map(([key]) => key)
    .filter((id) => sharedWithTherapistUserIds != null && !sharedWithTherapistUserIds.includes(id))

  const { journalingLogCanShare } = useJournalingLogCanShare()

  const [therapistIdsWithBookFirst, setTherapistIdsWithBookFirst] = useState<(string | null)[]>([])

  useEffect(() => {
    const run = async () => {
      const { data } = await journalingLogCanShare()

      if (data == null) {
        return
      }

      const filteredTherapistsIds = data.journalingLogCanShare
        .filter(
          ({ therapistId, status }) =>
            therapistId != null && therapistIdsToShareWith.includes(therapistId) && status === 'BOOKED_FIRST',
        )
        .map(({ therapistId }) => therapistId)

      setTherapistIdsWithBookFirst(filteredTherapistsIds)
    }

    run().catch(noop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCloseClick = useCallback(() => {
    close(modalId)
  }, [close, modalId])

  const therapistsWithBookFirst = sharableTherapists.filter(
    (therapist) => therapist != null && therapistIdsWithBookFirst.includes(therapist.id),
  )

  return (
    <>
      <Form grow={1} shrink={1}>
        <ModalCloseButton id={modalId} />
        <OverflowAuto>
          <Text fontWeight="600" kind="h2">
            <Translation id="journaling.journalingLogShare.bookedFirst.title" />
          </Text>

          {therapistsWithBookFirst.length === 1 && (
            <TranslationMarkdown
              colorName="black"
              id="journaling.journalingLogShare.bookedFirst.text"
              kind="paragraph"
              pt={16}
              values={{ therapistFullName: String(therapistsWithBookFirst.at(0)?.firstName) }}
            />
          )}
          {therapistsWithBookFirst.length === 2 && (
            <TranslationMarkdown
              colorName="black"
              id="journaling.journalingLogShare.bookedFirst.2.text"
              kind="paragraph"
              pt={16}
              values={{
                firstTherapistFullName: String(therapistsWithBookFirst.at(0)?.firstName),
                secondTherapistFullName: String(therapistsWithBookFirst.at(1)?.firstName),
              }}
            />
          )}
          {therapistsWithBookFirst.length === 3 && (
            <TranslationMarkdown
              colorName="black"
              id="journaling.journalingLogShare.bookedFirst.3.text"
              kind="paragraph"
              pt={16}
              values={{
                firstTherapistFullName: String(therapistsWithBookFirst.at(0)?.firstName),
                secondTherapistFullName: String(therapistsWithBookFirst.at(1)?.firstName),
                thirdTherapistFullName: String(therapistsWithBookFirst.at(2)?.firstName),
              }}
            />
          )}
        </OverflowAuto>
        <Flex pt={16}>
          <Button kind="primary" onClick={onCloseClick}>
            <Translation id="journaling.journalingLogShare.bookedFirst.cta" />
          </Button>
        </Flex>
      </Form>
    </>
  )
}
