import {
  isTaxId,
  registerDecorator,
  type ValidationOptions,
  ValidatorConstraint,
  type ValidatorConstraintInterface,
} from 'class-validator'

const regexNoPersonaFisica = /^\d{11}$/

/**
 * @description A whitelist of specific tax codes for exceptional cases, such as homocody.
 * @link https://linear.app/serenis/issue/SAAS-1652/move-taxcode-whitelist-in-validation-library
 */
const WHITELISTED_TAX_CODE = ['RSTNDR74D26F20RK', 'RLLKHR96M70Z60RM', 'CRNLCA87H43B3R4Q', 'MNCNTN66H23H5L1Y']

export const isTaxCode = (value: unknown) => {
  if (typeof value !== 'string') {
    return false
  }

  return (
    isTaxId(value.trim(), 'it-IT') || regexNoPersonaFisica.test(value.trim()) || WHITELISTED_TAX_CODE.includes(value)
  )
}

@ValidatorConstraint({ name: 'taxCode', async: false })
export class IsTaxCodeConstraint implements ValidatorConstraintInterface {
  validate = isTaxCode

  defaultMessage = () => 'Input is not a valid tax code'
}

export function IsTaxCode<T extends object>(validationOptions?: ValidationOptions) {
  return function (object: T, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsTaxCodeConstraint,
    })
  }
}
