import { Redirect, Route, Switch } from 'react-router-dom'
import { getRoute } from '~/utils/getRoute'
import { AvailabilitiesCalendarPage } from './availabilitiesCalendar'
import { AvailabilitiesWeeklyPage } from './availabilitiesWeekly'
import { ChangeTherapistFormFlowPage } from './changeTherapistFormFlow'
import { ChangeTherapistPreferencesFormFlowPage } from './changeTherapistPreferencesFormFlow'
import { ChangeTherapistReservationPage } from './changeTherapistReservation'
import { ChatPage } from './chat'
import { ClinicalTestPage } from './clinicalTest'
import { CommunicationsPage } from './communications'
import { DiariesPage } from './diaries'
import { FoodJournalPage } from './foodJournal'
import { ForgotPasswordPage } from './forgotPassword'
import { HistoricalTherapySessionsPage } from './historicalTherapySessions'
import { HomePage } from './home'
import { InvoicesPage } from './invoices'
import { JournalingPage } from './journaling'
import { JournalingBenefitsPage } from './journalingBenefits'
import { JournalingLogPage } from './journalingLog'
import { JournalingLogEditPage } from './journalingLogEdit'
import { JournalingLogsPage } from './journalingLogs'
import { JournalingLogSharedPage } from './journalingLogShared'
import { JournalingSettingsPage } from './journalingSettings'
import { JournalingTipsOfLovePage } from './journalingTipsOfLove'
import { LoginPage } from './login'
import { LoginCallbackPage } from './loginCallback'
import { NotificationsPage } from './notifications'
import { NotificationsJournalingPage } from './notificationsJournaling'
import { OnboardingPage } from './onboarding'
import { OnboardingCouplesPage } from './onboardingCouples'
import { OnboardingGuardianPage } from './onboardingGuardian'
import { PageAppBarEffect } from './PageAppBarEffect'
import { PageHead } from './PageHead'
import { PageScrollEffect } from './PageScrollEffect'
import { PageTabNavigationEffect } from './PageTabNavigationEffect'
import { PageTitleEffect } from './PageTitleEffect'
import { PageTrackEffect } from './PageTrackEffect'
import { PatientsManagementPage } from './patientsManagement'
import { PaymentsConfirmPage } from './paymentsConfirm'
import { PreBookingPage } from './preBooking'
import { ProductReviewDetailPage } from './productReviewDetail'
import { ProfilePage } from './profile'
import { ProfileActivitiesPage } from './profileActivities'
import { ProfileEducationPage } from './profileEducation'
import { ProfileLanguagesPage } from './profileLanguages'
import { ProfilePathologiesPage } from './profilePathologies'
import { ProfilePatientTypesPage } from './profilePatientTypes'
import { ProfileStylesPage } from './profileStyles'
import { ProfileTechniquesPage } from './profileTechniques'
import { ProfileWorkplacesPage } from './profileWorkplaces'
import { ReservationPage } from './reservation'
import { ResetPasswordPage } from './resetPassword'
import { SaasOnboardingInternalPage } from './saasOnboardingInternal'
import { SettingsAccountPage } from './settingsAccount'
import { SettingsBillingPage } from './settingsBilling'
import { SettingsInvitePage } from './settingsInvite'
import { SettingsPaymentsPage } from './settingsPayments'
import { SettingsPaymentsAddPage } from './settingsPaymentsAdd'
import { SettingsPaymentsConfirmPage } from './settingsPaymentsConfirm'
import { ShareJournalingRewardStickerPage } from './shareJournalingRewardSticker'
import { ShareJournalingScoreStickerPage } from './shareJournalingScoreSticker'
import { ShareSentenceOfTheDayPage } from './shareSentenceOfTheDay'
import { ShareTherapySessionStickerPage } from './shareTherapySessionAchievementSticker'
import { SignupPage } from './signup'
import { StartPage } from './start'
import { StickersPage } from './stickers'
import { TherapiesPage } from './therapies'
import { TherapySessionBookingPage } from './therapySessionBooking'
import { TherapySessionDeletePage } from './therapySessionDelete'
import { TherapySessionEditPage } from './therapySessionEdit'
import { TherapySessionLivePage } from './therapySessionLive'
import { TherapySessionLiveExternalPage } from './therapySessionLiveExternal'
import { TherapySessionSchedulePage } from './therapySessionSchedule'
import { type Page } from './types'
import { VideocallTroubleshootingPage } from './videocallTroubleshooting'

const commonPages: Page[] = [
  ForgotPasswordPage,
  HomePage,
  LoginCallbackPage,
  LoginPage,
  ResetPasswordPage,
  SaasOnboardingInternalPage,
  SettingsAccountPage,
  SignupPage,
]

// NOTE: order matters here since this components are rendered as children of <Switch />
// HomePage since it renders with `/` and not exact should be the last one
// https://v5.reactrouter.com/web/example/basic
const serenisPages: Page[] = [
  AvailabilitiesCalendarPage,
  AvailabilitiesWeeklyPage,
  ChangeTherapistFormFlowPage,
  ChangeTherapistPreferencesFormFlowPage,
  ChangeTherapistReservationPage,
  ChatPage,
  ClinicalTestPage,
  CommunicationsPage,
  DiariesPage,
  FoodJournalPage,
  HistoricalTherapySessionsPage,
  InvoicesPage,
  JournalingBenefitsPage,
  JournalingLogEditPage,
  JournalingLogPage,
  JournalingLogSharedPage,
  JournalingLogsPage,
  JournalingPage,
  JournalingSettingsPage,
  JournalingTipsOfLovePage,
  NotificationsJournalingPage,
  NotificationsPage,
  OnboardingCouplesPage,
  OnboardingGuardianPage,
  OnboardingPage,
  PatientsManagementPage,
  PaymentsConfirmPage,
  PreBookingPage,
  ProductReviewDetailPage,
  ProfileActivitiesPage,
  ProfileEducationPage,
  ProfileLanguagesPage,
  ProfilePage,
  ProfilePathologiesPage,
  ProfilePatientTypesPage,
  ProfileStylesPage,
  ProfileTechniquesPage,
  ProfileWorkplacesPage,
  ReservationPage,
  SettingsBillingPage,
  SettingsInvitePage,
  SettingsPaymentsAddPage,
  SettingsPaymentsConfirmPage,
  SettingsPaymentsPage,
  ShareJournalingRewardStickerPage,
  ShareJournalingScoreStickerPage,
  ShareSentenceOfTheDayPage,
  ShareTherapySessionStickerPage,
  StartPage,
  StickersPage,
  TherapiesPage,
  TherapySessionBookingPage,
  TherapySessionDeletePage,
  TherapySessionEditPage,
  TherapySessionLiveExternalPage,
  TherapySessionLivePage,
  TherapySessionSchedulePage,
  VideocallTroubleshootingPage,
]

const pages = [...commonPages, ...serenisPages]

export const Pages = () => (
  <Switch>
    {pages.map(({ exact, id, options, PageGuard, PageLoaded, PageProvider, PageView, paths }) => (
      <Route key={id} exact={exact} path={paths}>
        <PageAppBarEffect
          isVisibleAppBar={options.isVisibleAppBar}
          isVisibleBackButton={options.isVisibleBackButton}
          isVisibleNotificationsInbox={options.isVisibleNotificationsInbox}
        />

        <PageTitleEffect title={options.title} />

        <PageGuard>
          {options.seoKey != null && <PageHead id={options.seoKey} />}

          <PageProvider>
            <PageLoaded>
              {id != null && <PageTrackEffect id={id} />}
              <PageScrollEffect />
            </PageLoaded>
            <PageTabNavigationEffect isVisibleTabNavigation={options.isVisibleTabNavigation} />
            <PageView />
          </PageProvider>
        </PageGuard>
      </Route>
    ))}

    <Route>
      <Redirect to={getRoute('/')} />
    </Route>
  </Switch>
)
