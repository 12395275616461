import { useCurrentUserNullable } from '~/hooks/useCurrentUser'

export const useCurrentUserProfessionTypeValue = () => {
  const { professionTypeValues } = useCurrentUserNullable()

  if (professionTypeValues == null) {
    return {
      isCoach: false,
      isNutritionist: false,
      isPsychiatrist: false,
      isPsychotherapist: false,
      isSexologist: false,
    }
  }

  return {
    isCoach: professionTypeValues.includes('COACH'),
    isNutritionist:
      professionTypeValues.includes('NUTRITIONIST_WEIGHT_LOSS') || professionTypeValues.includes('NUTRITIONIST_DCA'),
    isPsychiatrist: professionTypeValues.includes('PSYCHIATRIST'),
    isPsychotherapist: professionTypeValues.includes('PSYCHOTHERAPIST'),
    isSexologist: professionTypeValues.includes('SEXOLOGIST'),
  }
}
