import { type TypographyName } from '../types/typography'
import { cssvar, cssvarName, cssvarValue } from '../utils/cssvar'

export const DESIGN_TOKENS_TYPOGRAPHY_PREFIX = 'typography'

export const DESIGN_TOKENS_TYPOGRAPHY_PROPERTIES = [
  'font-size',
  'line-height',
  'font-weight',
  'font-family',
  'letter-spacing',
] as const

type TypographyProp = (typeof DESIGN_TOKENS_TYPOGRAPHY_PROPERTIES)[number]

export const cssvarNameTypographyProp = (typography: TypographyName, prop: TypographyProp) =>
  cssvarName(`${DESIGN_TOKENS_TYPOGRAPHY_PREFIX}-${typography}-${prop}`)

export const cssvarValueTypographyProp = (typography: TypographyName, prop: TypographyProp) =>
  cssvarValue(`${DESIGN_TOKENS_TYPOGRAPHY_PREFIX}-${typography}-${prop}`)

export const cssvarTypographyProp = (typography: TypographyName, prop: TypographyProp) =>
  cssvar(`${DESIGN_TOKENS_TYPOGRAPHY_PREFIX}-${typography}-${prop}`)

export const cssvarTypography = (typography: TypographyName) =>
  DESIGN_TOKENS_TYPOGRAPHY_PROPERTIES.map((prop) => `${prop}: ${cssvarTypographyProp(typography, prop)};`)
