import { Flex, Pressable } from 'cdk'
import { SPACING_0, SPACING_3XS, SPACING_MD } from 'design-tokens'
import { matchPath, useLocation } from 'react-router-dom'
import { Avatar, Text } from 'ui'
import { DateTime } from '~/components/DateTime'
import { RecurrencyTime } from '~/components/RecurrencyTime'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { TherapistOverviewCardDetail } from '~/domains/therapist/TherapistOverviewCard/Detail'
import { type TranslationId } from '~/i18n/types'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'
import { useTherapySession } from '../../../hooks/useTherapySession'
import { EditAndDeleteAppointmentButtons } from './EditAndDeleteAppointmentButtons'

type AppointmentDetailsProps = {
  canManage?: boolean
}

export const AppointmentDetails = ({ canManage = false }: AppointmentDetailsProps) => {
  const { endAt, repeatEveryWeek, startAt, therapist, therapyPathType } = useTherapySession()
  const { pathname } = useLocation()
  const { isControl } = useFeatureFlagsByUserId()

  const therapySessionTypeMap: Record<
    TherapyTherapyPathType,
    Extract<TranslationId, `therapySessions.item.therapySessionType.${string}`>
  > = {
    COUPLES_PSYCHOTHERAPY: 'therapySessions.item.therapySessionType.couples',
    MYSELF_PSYCHIATRY: 'therapySessions.item.therapySessionType.psychiatry',
    MYSELF_PSYCHOTHERAPY: 'therapySessions.item.therapySessionType.individual',
    PATH_ASSERTIVE_COMMUNICATION: 'therapySessions.item.therapySessionType.assertiveCommunication',
    PATH_COACHING: 'therapySessions.item.therapySessionType.coaching',
    PATH_SEXOLOGY: 'therapySessions.item.therapySessionType.sexology', // FIXME: https://linear.app/serenis/issue/PRD-5756/excludetherapypathtype-path-sexology-app-web
    PATH_SLEEP: 'therapySessions.item.therapySessionType.sleep',
    UNDERAGE_PSYCHOTHERAPY: 'therapySessions.item.therapySessionType.individual',
    PATH_NUTRITION_WEIGHT_LOSS: 'therapySessions.item.therapySessionType.nutritionWeightLoss',
    PATH_NUTRITION_DCA: 'therapySessions.item.therapySessionType.nutritionDca',
  }

  const therapySessionTypeTranslationId = therapySessionTypeMap[therapyPathType ?? 'MYSELF_PSYCHOTHERAPY']

  const isTherapiesPath = !!matchPath(pathname, { path: [getRoute('/therapies')] })

  return (
    <Flex $direction="row">
      {!isTherapiesPath && (
        <Flex>
          <Avatar image={therapist?.therapist?.profileImage?.s} name={therapist?.fullName} size="sm" />
        </Flex>
      )}
      <Flex $align="flex-start" $pl={isTherapiesPath ? SPACING_0 : SPACING_MD} $shrink={1}>
        <Flex $pb={SPACING_3XS}>
          <TranslationMarkdown colorName="grey-80" id={therapySessionTypeTranslationId} kind="paragraph" />
        </Flex>

        {!isTherapiesPath && (
          <TherapistOverviewCardDetail therapyPath={therapyPathType ?? 'MYSELF_PSYCHOTHERAPY'}>
            <Pressable data-test-id="therapist-profile-link">
              <Text colorName="primary" fontWeight="600" kind="paragraph" textDecoration="underline">
                {therapist?.fullName}
              </Text>
            </Pressable>
          </TherapistOverviewCardDetail>
        )}

        {!!startAt && !!endAt && (
          <Flex $pt={SPACING_3XS}>
            <DateTime endAt={endAt} startAt={startAt} />
          </Flex>
        )}
        {repeatEveryWeek && startAt && (
          <Flex $pt={SPACING_3XS}>
            <RecurrencyTime repeatEveryWeek={repeatEveryWeek} startAt={startAt} />
          </Flex>
        )}
        {isControl('ff_manage_therapy_session') && canManage && (
          <Flex $pt={SPACING_MD}>
            <EditAndDeleteAppointmentButtons />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
