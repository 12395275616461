import { Flex, PositionAbsolute, PositionRelative } from 'cdk'
import { BORDER_RADIUS_SM, SPACING_LG, SPACING_MD, SPACING_XS } from 'design-tokens'
import { Icon } from 'icons'
import { useState } from 'react'
import { Button } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useRootHistory } from '~/hooks/useRootHistory'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { SettingsInviteSvg } from '../svg'

export const SettingsInviteCard = () => {
  const [isCardShown, setIsCardShown] = useState(getLocalStorage('settings-invite-card-shown') != null)

  const trackEventClick = useTrackEventClick()
  const rootHistrory = useRootHistory()

  const handleHideCard = () => {
    setLocalStorage('settings-invite-card-shown', new Date().toISOString())
    setIsCardShown(true)
  }

  const navigate = () => {
    handleHideCard()
    trackEventClick('settings-invite.from-mgm-card')
    rootHistrory.push('/settings/invite')
  }

  if (isCardShown) {
    return null
  }

  return (
    <PositionRelative
      $align="center"
      $borderRadius={BORDER_RADIUS_SM}
      $direction="row"
      $elevationName="xs"
      $gap={SPACING_MD}
      $justify="center"
      $overflow="hidden"
      $px={SPACING_MD}
      $py={SPACING_LG}
      $wrap="wrap"
    >
      <Flex $align="center" $grow={1}>
        <SettingsInviteSvg />
      </Flex>
      <Flex $align="center" $direction="column" $gap={SPACING_MD} $grow={1}>
        <Flex $align="center" $gap={SPACING_XS}>
          <TranslationMarkdown id="settings.invite.mgm.title.mobile.1" kind="h1" />
          <TranslationMarkdown id="settings.invite.mgm.title.mobile.2" kind="h3" />
        </Flex>

        <Button kind="primary" onClick={navigate}>
          <Translation id="settings.invite.mgm.findOutMore" />
        </Button>
      </Flex>
      <PositionAbsolute $right={SPACING_MD} $top={SPACING_MD}>
        <Button isRound kind="tertiary" onClick={handleHideCard} size="sm" type="button">
          <Icon name="srns-xmark-circle" size={24} />
        </Button>
      </PositionAbsolute>
    </PositionRelative>
  )
}
