import { COLOR_ACCENT, COLOR_MINTTEA_20, COLOR_PRIMARY } from 'design-tokens'
import { Svg } from './components/Svg'

/**
 * @link https://linear.app/serenis/issue/SAAS-1090/update-gestologoicon-to-use-design-tokens
 */
export const GestoLogoIcon = () => (
  <Svg className="srns-gesto-light" fill="none" height="15" width="20">
    <path
      d="M19.614.534H11.94a.284.284 0 0 0-.171.056c-.432.308-2.524 1.906-4.192 4.947.018.009 6.464 3.28 6.486 8.76.355-7.273 4.178-11.652 5.758-13.164.217-.208.084-.6-.206-.6Z"
      fill={COLOR_PRIMARY}
    />
    <path
      d="M7.576 5.537H.312c-.32 0-.432.465-.153.64 1.844 1.15 5.251 3.852 5.253 8.142 0-3.705.975-6.61 2.166-8.782h-.002Z"
      fill={COLOR_MINTTEA_20}
    />
    <path
      d="M7.578 5.537c-1.191 2.172-2.166 5.077-2.166 8.782v.032c0 .357.265.649.593.649h7.462c.328 0 .593-.29.593-.649v-.054c-.02-5.48-6.468-8.751-6.486-8.76h.004Z"
      fill={COLOR_ACCENT}
    />
  </Svg>
)
