import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useFormFlowResponse } from '~/domains/formFlow/hooks/useFormFlowResponse'

const INTENTS = [
  'call',
  'coaching',
  'dca',
  'home',
  'journaling',
  'nutritionist',
  'psychiatry',
  'sexology',
  'therapyCouple',
  'therapyMyself',
] as const

export type Intent = (typeof INTENTS)[number]

const isIntent = (value: string): value is Intent => (INTENTS as unknown as string).includes(value)

export const signupIntentShouldRedirectToHome = (
  value: Intent,
): value is Extract<Intent, 'call' | 'journaling' | 'home'> => ['call', 'journaling', 'home'].includes(value)

export const getSignupIntent = (value: Intent): Intent => value

export const useSignupIntent = (): Intent => {
  const { search } = useLocation()

  const { intent } = queryString.parse(search)

  const response = useFormFlowResponse()

  if (!intent || Array.isArray(intent) || !isIntent(intent)) {
    const forWhom = response.formFlowResponse?.answers
      ?.find(({ step: { name } }) => name === 'FOR_WHOM')
      ?.valueChoice?.at(0)?.name

    if (forWhom === 'FOR_WHOM_PATH_NUTRITION_WEIGHT_LOSS' || forWhom === 'FOR_WHOM_PATH_NUTRITION_DCA') {
      return 'nutritionist'
    }

    return forWhom === 'GENERIC_FOR_WHOM_COUPLES' ? 'therapyCouple' : 'therapyMyself'
  }

  return intent
}
