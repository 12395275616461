import { type FetchPolicy, gql, useQuery } from '@apollo/client'
import {
  type TherapySessionFindManyFutureForTherapistByPatientIdQuery,
  type TherapySessionFindManyFutureForTherapistByPatientIdQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query therapySessionFindManyFutureForTherapistByPatientId($patientId: String!) {
    therapySessionFindManyFutureForTherapistByPatientId(patientId: $patientId) {
      churn
      endAt
      endAtSlot
      id
      notes
      parentTherapySessionId
      patient {
        fullName
        firstName
        email
        phoneNumber
        id
      }
      provider
      recurrency
      repeatEveryWeek
      startAt
      status
      therapist {
        fullName
        id
      }
      therapyPathType
      typeCost
      url
    }
  }
`

export const useTherapySessionFindManyFutureForTherapistByPatientId = (
  patientId: string,
  fetchPolicy: FetchPolicy = 'cache-first',
) => {
  const { data, loading, error, refetch, updateQuery } = useQuery<
    TherapySessionFindManyFutureForTherapistByPatientIdQuery,
    TherapySessionFindManyFutureForTherapistByPatientIdQueryVariables
  >(QUERY, {
    variables: {
      patientId,
    },
    fetchPolicy,
  })

  return {
    error: !!error,
    loading,
    therapySessions: data?.therapySessionFindManyFutureForTherapistByPatientId ?? [],
    refetch,
    updateQuery,
  }
}
