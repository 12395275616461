import { useEffect } from 'react'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { type ReservationFormValues } from '~/domains/reservation/types'

export const useRegisterSelectedTimeSlotVariant = () => {
  const form = useReactHookFormContext<ReservationFormValues>()

  useEffect(() => {
    form.register('selectedTimeSlotWithTherapist', { required: true })
    form.trigger('selectedTimeSlotWithTherapist')
  }, [form])
}
