import { Flex, Pressable } from 'cdk'
import { Icon } from 'icons'
import { useState } from 'react'
import { type TextFieldProps } from 'ui'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { SignupFormTextField } from './SignupFormTextField'

type Props = TextFieldProps & {
  name: string
}

export const SignupFormPasswordField = ({ autoComplete, name }: Props) => {
  const {
    errors: { required },
    labels: { password: label },
    helperTexts: { password: helperText },
  } = useFormTranslations()
  const { isPassword } = useFormValidation()
  const showPassword = useTranslation('actions.showPassword')
  const hidePassword = useTranslation('actions.hidePassword')

  const [type, setType] = useState<'password' | 'text'>('password')

  return (
    <SignupFormTextField
      autoCapitalize="none"
      autoComplete={autoComplete}
      hint={helperText}
      label={label}
      name={name}
      rules={{ required, validate: isPassword }}
      spellCheck="false"
      suffix={
        <Pressable
          aria-controls="password"
          aria-label={type === 'password' ? showPassword : hidePassword}
          onClick={() => setType(type === 'password' ? 'text' : 'password')}
        >
          <Flex>
            <Icon name={type === 'password' ? 'eye' : 'eye-closed'} size={20} />
          </Flex>
        </Pressable>
      }
      type={type}
    />
  )
}
