import { Flex, Form, MaxWidth840px, MountOn } from 'cdk'
import { useCallback } from 'react'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { type ReservationFormValues } from '~/domains/reservation/types'
import { ScrollRestoreProvider } from '~/hooks/useScrollRestore'
import { BookBySuggestedTherapistsView } from '../../components/BookBySuggestedTherapistsView'
import { SuggestedTherapistsSwitcher } from '../../components/BookBySuggestedTherapistsView/SuggestedTherapistsSwitcher'
import { AvailabilityDayDropdownProvider } from '../../hooks/useAvailabilityDayDropdown'
import { useRegisterSelectedTimeSlot } from '../../hooks/useRegisterSelectedTimeSlot'
import { useReservationFlow } from '../../hooks/useReservationFlow'

export const BookBySuggestedTherapists = () => {
  useRegisterSelectedTimeSlot()
  const form = useReactHookFormContext<ReservationFormValues>()

  const { onDayAndTimeSlotSelected } = useReservationFlow()

  const onSubmit = useCallback(
    ({ selectedTimeSlot }: ReservationFormValues) => {
      if (!selectedTimeSlot) {
        return
      }

      onDayAndTimeSlotSelected(selectedTimeSlot)
    },
    [onDayAndTimeSlotSelected],
  )

  return (
    <ScrollRestoreProvider pb={0}>
      <Form $backgroundColorName="lighter" $grow={1} id="reservationFlow" onSubmit={form.handleSubmit(onSubmit)}>
        <AvailabilityDayDropdownProvider>
          <MountOn mediaQuery="sm">
            <Flex $px={24} $py={16}>
              <SuggestedTherapistsSwitcher />
              <BookBySuggestedTherapistsView />
            </Flex>
          </MountOn>
          <MountOn mediaQuery="gt-sm">
            <MaxWidth840px $pb={32} $pt={32} $px={32}>
              <SuggestedTherapistsSwitcher />
              <BookBySuggestedTherapistsView />
            </MaxWidth840px>
          </MountOn>
        </AvailabilityDayDropdownProvider>
      </Form>
    </ScrollRestoreProvider>
  )
}
