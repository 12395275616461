import { Flex, type FlexProps, OverflowHidden, Pressable } from 'cdk'
import { Icon } from 'icons'
import { type ReactNode } from 'react'
import { InView } from 'react-intersection-observer'
import { useSwipeable } from 'react-swipeable'
import styled from 'styled-components'
import { shouldForwardProp } from 'ui'
import { useCarousel } from './useCarousel'

type Props = {
  children: ReactNode[]
  itemFlexProps?: FlexProps
  /** Use 'auto' to let carousel control items width */
  itemWidth: string
  showNavigation: boolean
  showPagination: boolean
  visibleItems: number
}

type Attrs = Pick<Props, 'itemWidth' | 'visibleItems'> & {
  currentSlideIndex: number
}

const Container = styled(Flex).withConfig({ shouldForwardProp })<Attrs>`
  transition: transform 500ms ease-in-out 0ms;
  transform: ${({ currentSlideIndex, itemWidth, visibleItems }) =>
    itemWidth === 'auto'
      ? `translateX(-${(currentSlideIndex * 100) / visibleItems}%)`
      : `translateX(calc(${itemWidth} * -${currentSlideIndex}))`};
  will-change: transform;
`

export const Carousel = ({
  children,
  itemFlexProps,
  itemWidth,
  showNavigation,
  showPagination,
  visibleItems,
}: Props) => {
  const { current, goNext, goPrev, prevDisabled, nextDisabled, updateRightEdgeReached } = useCarousel()

  const swipeHandlers = useSwipeable({
    onSwipedLeft: goNext,
    onSwipedRight: goPrev,
    preventScrollOnSwipe: true,
    trackMouse: true,
  })

  return (
    <>
      <OverflowHidden {...swipeHandlers} className="carousel-overflow-hidden">
        <Container $direction="row" currentSlideIndex={current} itemWidth={itemWidth} visibleItems={visibleItems}>
          {children.map((item, index) => (
            <Flex key={index} $basis={itemWidth === 'auto' ? `${100 / visibleItems}%` : itemWidth} {...itemFlexProps}>
              {item}
            </Flex>
          ))}
          {itemWidth !== 'auto' && <InView initialInView onChange={updateRightEdgeReached} />}
        </Container>
      </OverflowHidden>

      {(showPagination || showNavigation) && (
        <Flex $align="center" $direction="row" $justify="space-between" $pt={16}>
          {showPagination && (
            <Flex $direction="row">
              {children.map((_, index) => (
                <Flex key={index} $pr={4}>
                  <Flex
                    $borderColorName={current === index ? 'primary' : 'neutral-40'}
                    $borderRadius={4}
                    $borderSize={4}
                  />
                </Flex>
              ))}
            </Flex>
          )}

          {showNavigation && (
            <Flex $direction="row">
              <Pressable disabled={prevDisabled} onClick={goPrev}>
                <Icon colorName={prevDisabled ? 'grey-40' : 'primary'} name="arrow-left" size={32} />
              </Pressable>

              <Flex $pl={8}>
                <Pressable disabled={nextDisabled} onClick={goNext}>
                  <Icon colorName={nextDisabled ? 'grey-40' : 'primary'} name="arrow-right" size={32} />
                </Pressable>
              </Flex>
            </Flex>
          )}
        </Flex>
      )}
    </>
  )
}
