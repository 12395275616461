import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, CardBox, Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useReservationFlow } from '../../hooks/useReservationFlow'
import { useSuspenseTherapyPathByIdQuery } from '../../hooks/useTherapyPathByIdQuery'
import { getReservationRoutes } from '../../utils/getReservationRoutes'

export const ChangeSuggestedTherapistsCard = () => {
  const { selectedTherapist } = useReservationFlow()

  const { emailVerified } = useCurrentUser()

  const { therapyPath } = useSuspenseTherapyPathByIdQuery()

  const selectedTherapistIsPsychiatrist = selectedTherapist?.therapist.professionTypes.some(
    ({ professionType: { value } }) => value === 'PSYCHIATRIST',
  )

  const history = useHistory()

  const skipBooking = useCallback(() => {
    if (therapyPath.type === 'MYSELF_PSYCHIATRY') {
      history.push(getReservationRoutes('/skip-booking/psychiatry'))

      return
    }

    if (therapyPath.type === 'PATH_COACHING') {
      history.push(getReservationRoutes('/skip-booking/coaching'))

      return
    }

    history.push(getReservationRoutes('/skip-booking'))
  }, [therapyPath, history])

  const firstName = String(selectedTherapist?.firstName)

  if (!emailVerified) {
    return null
  }

  return (
    <CardBox background="purple02">
      <Text fontWeight="600" kind="h3">
        <Translation
          id={
            selectedTherapistIsPsychiatrist
              ? 'reservation.availabilities.changePsychiatristCard.title'
              : 'reservation.availabilities.changeTherapistCard.title'
          }
        />
      </Text>

      <TranslationMarkdown
        colorName="black"
        id={
          selectedTherapistIsPsychiatrist
            ? 'reservation.availabilities.changePsychiatristCard.body'
            : 'reservation.availabilities.changeTherapistCard.body'
        }
        kind="paragraph"
        pt={8}
        values={{ firstName }}
      />

      <Flex pt={16}>
        <Button data-test-id="proceed-without-booking" kind="secondary" onClick={skipBooking} size="small">
          <Translation id="suggestedTherapist.noReservation.continue.cta" />
        </Button>
      </Flex>
    </CardBox>
  )
}
