import { Flex, MaxWidth640px } from 'cdk'
import { SPACING_5XL, SPACING_XS } from 'design-tokens'
import { type ReactNode } from 'react'
import { Text } from 'ui'
import { ContactUsButton } from '~/components/ContactUsButton'
import { Translation } from '~/components/Translation'
import { ErrorPage } from '../ErrorPage'

// We already wrap localStorage calls in try-catch, but third-party libraries might not.
const isLocalStorageSupported = () => {
  const value = 'srns'

  try {
    localStorage.setItem(value, value)
    localStorage.removeItem(value)
    return true
  } catch (e) {
    return false
  }
}

const areRequirementsMet = () => isLocalStorageSupported()

const UnmetRequirementsError = () => (
  <ErrorPage>
    <MaxWidth640px>
      <Text kind="h2" textAlign="center">
        <Translation id="generic.unmetRequirementsError.title" />
      </Text>

      <Flex $pt={48}>
        <Text kind="paragraph" textAlign="center">
          <Translation id="generic.unmetRequirementsError.content" />
        </Text>
      </Flex>

      <Flex $justify="center" $mdDirection="row" $p={SPACING_XS} $pt={SPACING_5XL} $wrap="wrap">
        <ContactUsButton />
      </Flex>
    </MaxWidth640px>
  </ErrorPage>
)

type Props = {
  children: ReactNode
}

export const AssertMinimumRequirements = ({ children }: Props) => {
  if (!areRequirementsMet()) {
    return <UnmetRequirementsError />
  }

  return children
}
