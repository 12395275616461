import { gql, useQuery } from '@apollo/client'
import { isNeitherNullNorUndefined } from 'functions'
import { type PatientTherapiesQuery, type PatientTherapiesQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query patientTherapies {
    therapies {
      changeTherapistReason
      id
      ongoing
      patient {
        id
      }
      therapist {
        firstName
        fullName
        id
        profileImage {
          s
          m
          l
        }
      }
      therapyPath {
        cost
        costFirst
        duration
        id
        type
      }
      withTherapyBundle
    }
  }
`

export const usePatientTherapiesQuery = () => {
  const { data, error, loading, refetch, updateQuery } = useQuery<
    PatientTherapiesQuery,
    PatientTherapiesQueryVariables
  >(QUERY)

  return {
    error: !!error,
    loading,
    ongoing: data?.therapies.filter(({ ongoing }) => ongoing) ?? [],
    /**
     * @description Keep only ongoing to get the first one: only for Journaling
     */
    therapy:
      data?.therapies
        .filter(
          ({ ongoing, therapyPath }) => ongoing && !['MYSELF_PSYCHIATRY', 'PATH_COACHING'].includes(therapyPath.type),
        )
        .at(0) ?? null,
    therapies: data?.therapies ?? [],
    therapists: data?.therapies.map((therapy) => therapy.therapist).filter(isNeitherNullNorUndefined) ?? [],
    refetch,
    updateQuery,
  }
}
