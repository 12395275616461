/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { type FontSizeName } from '../types/font-size'
import { cssvar, cssvarName, cssvarValue } from '../utils/cssvar'

export const DESIGN_TOKENS_FONT_SIZE_PREFIX = 'font-size'

export const cssvarNameFontSize = (fontSize: FontSizeName) =>
  cssvarName(`${DESIGN_TOKENS_FONT_SIZE_PREFIX}-${fontSize}`)

export const cssvarValueFontSize = (fontSize: FontSizeName) =>
  cssvarValue(`${DESIGN_TOKENS_FONT_SIZE_PREFIX}-${fontSize}`)

export const cssvarFontSize = (fontSize: FontSizeName) => cssvar(`${DESIGN_TOKENS_FONT_SIZE_PREFIX}-${fontSize}`)
