import { Flex } from 'cdk'
import { Text } from 'ui'
import { useNativeAppVersion } from '~/domains/appNative/hooks/useNativeAppVersion'

export const MenuNativeAppVersion = () => {
  const appNativeVersion = useNativeAppVersion()

  if (!appNativeVersion) {
    return null
  }

  return (
    <Flex $pt={12}>
      <Text colorName="neutral-80" fontWeight="400" kind="footnote" textAlign="center">
        {appNativeVersion}
      </Text>
    </Flex>
  )
}
