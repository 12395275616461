import { getSessionStorage } from './sessionStorage/getSessionStorage'

export const getSessionStorageQueryParams = (): Record<string, unknown> | null => {
  try {
    const value = getSessionStorage('query-params')

    if (value == null) {
      return null
    }

    return JSON.parse(value)
  } catch (error) {
    return null
  }
}
