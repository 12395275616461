/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { type BorderRadiusName } from '../types/border-radius'
import { cssvar, cssvarName, cssvarValue } from '../utils/cssvar'

export const DESIGN_TOKENS_BORDER_RADIUS_PREFIX = 'border-radius'

export const cssvarNameBorderRadius = (borderRadius: BorderRadiusName) =>
  cssvarName(`${DESIGN_TOKENS_BORDER_RADIUS_PREFIX}-${borderRadius}`)

export const cssvarValueBorderRadius = (borderRadius: BorderRadiusName) =>
  cssvarValue(`${DESIGN_TOKENS_BORDER_RADIUS_PREFIX}-${borderRadius}`)

export const cssvarBorderRadius = (borderRadius: BorderRadiusName) =>
  cssvar(`${DESIGN_TOKENS_BORDER_RADIUS_PREFIX}-${borderRadius}`)
