import { Flex, MountOn, OverflowHidden } from 'cdk'
import { type PropsWithChildren } from 'react'
import { Divider } from 'ui'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'

type ContentSkeletonProps = PropsWithChildren<{
  bottomChildren?: PropsWithChildren['children']
  topEndChildren?: PropsWithChildren['children']
  topStartChildren?: PropsWithChildren['children']
  useDivider?: boolean
}>

export const ContentSkeleton = ({
  bottomChildren,
  children,
  topEndChildren,
  topStartChildren,
  useDivider = true,
}: ContentSkeletonProps) => {
  const { isControl } = useFeatureFlagsByUserId()
  const isManageTherapySessionControl = isControl('ff_manage_therapy_session')

  return (
    <OverflowHidden $mdAlign="center" $mdDirection="row" $mdJustify="space-between">
      <Flex $direction="row" $grow={1} $justify="space-between">
        <Flex $align="flex-start" $grow={1} $mdBasis={332} $shrink={1}>
          <Flex
            $align="center"
            $direction="row"
            $gap={16}
            $justify="space-between"
            $mdJustify="flex-start"
            $minWidth="100%"
          >
            {topStartChildren}
            {topEndChildren && topEndChildren}
          </Flex>
          {children && <Flex $pt={16}>{children}</Flex>}
        </Flex>
      </Flex>

      {isManageTherapySessionControl && bottomChildren && (
        <Flex $mdBasis={180} $mdPt={0} $pt={16}>
          {bottomChildren && (
            <>
              <MountOn mediaQuery="sm">
                {useDivider && (
                  <Flex $pb={16}>
                    <Divider />
                  </Flex>
                )}
              </MountOn>
              {bottomChildren}
            </>
          )}
        </Flex>
      )}

      {!isManageTherapySessionControl && bottomChildren && (
        <Flex $gap={8} $mdBasis={180} $mdPt={0} $pt={16}>
          {bottomChildren}
        </Flex>
      )}
    </OverflowHidden>
  )
}
