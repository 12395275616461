import { type FindTherapistProfileByIdQuery } from '~/types/graphql'

export type UserMaybeTherapist = NonNullable<FindTherapistProfileByIdQuery['findTherapistProfileById']>

export type UserTherapist = {
  firstName: UserMaybeTherapist['firstName']
  fullName: UserMaybeTherapist['fullName']
  id: UserMaybeTherapist['id']
  therapist: NonNullable<UserMaybeTherapist['therapist']>
}

export const isUserTherapist = (user: UserMaybeTherapist): user is UserTherapist => !!user?.therapist
