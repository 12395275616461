import { Flex } from 'cdk'
import { SPACING_XS } from 'design-tokens'
import { type PropsWithChildren } from 'react'
import { Link } from 'ui'

type Props = PropsWithChildren<{
  to: string
}>

export const MenuExternalLink = ({ children, to }: Props) => (
  <Link colorName="darker" colorNameHover="primary" href={to} rel="noreferrer" target="_blank">
    <Flex $align="center" $direction="row" $py={8}>
      <Flex $align="center" $direction="row" $grow={1} $pr={SPACING_XS} $shrink={1}>
        {children}
      </Flex>
    </Flex>
  </Link>
)
