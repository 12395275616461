import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'

type Context = {
  current: number
  goNext: () => void
  goPrev: () => void
  length: number
  nextDisabled: boolean
  prevDisabled: boolean
  updateRightEdgeReached: (value: boolean) => void
}

const CarouselContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
  length: number
}

export const CarouselProvider = ({ children, length }: Props) => {
  const [current, setCurrent] = useState(0)
  const [isRightEdgeReached, setIsRightEdgeReached] = useState(false)

  const nextDisabled = useMemo(
    () => isRightEdgeReached || current === length - 1,
    [current, isRightEdgeReached, length],
  )

  const prevDisabled = useMemo(() => current === 0, [current])

  const goNext = useCallback(() => {
    if (nextDisabled) {
      return
    }

    setCurrent(current + 1)
  }, [current, nextDisabled])

  const goPrev = useCallback(() => {
    if (prevDisabled) {
      return
    }

    setCurrent(current - 1)
  }, [current, prevDisabled])

  const updateRightEdgeReached = useCallback((value: boolean) => setIsRightEdgeReached(value), [])

  useEffect(() => {
    if (current > 0 && current >= length) {
      setCurrent(length - 1)
    }
  }, [current, length])

  return (
    <CarouselContext.Provider
      value={{ current, goNext, goPrev, length, nextDisabled, prevDisabled, updateRightEdgeReached }}
    >
      {children}
    </CarouselContext.Provider>
  )
}

export const useCarousel = () => {
  const state = useContext(CarouselContext)

  if (!state) {
    throw new Error('The `useCarousel` should be wrapped with `CarouselProvider`.')
  }

  return state
}
