import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { removeLocalStorage } from '~/utils/localStorage/removeLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'

const differenceBetweenDates = (date: string) => {
  const d1 = new Date(date)
  const d2 = new Date()

  const diffInMilliseconds = Math.abs(+d1 - +d2)

  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24)

  return Math.floor(diffInDays)
}

export const useUpdateLocalStorageSettingsInvite = () => {
  const inviteCardShownDate = getLocalStorage('settings-invite-card-shown')

  if (inviteCardShownDate == null) {
    return null
  }

  if (inviteCardShownDate === 'showed') {
    setLocalStorage('settings-invite-card-shown', new Date().toISOString())
  }

  const difference = differenceBetweenDates(inviteCardShownDate)

  if (difference >= 30) {
    removeLocalStorage('settings-invite-card-shown')
  }

  return null
}
