import { OverflowAuto, PageLayout } from 'cdk'
import { type ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const StepOverflowAuto = ({ children }: Props) => (
  <OverflowAuto $lgPt={40} $mdPt={32} $pt={24} $px={16}>
    <PageLayout $lgPb={40} $mdPb={32} $pb={24} $px={0} $py={0} maxWidth="640px">
      {children}
    </PageLayout>
  </OverflowAuto>
)
