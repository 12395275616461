import { Flex, PositionRelative, Pressable } from 'cdk'
import { SPACING_MD, SPACING_SM_VALUE, SPACING_XS } from 'design-tokens'
import { useBreakpoints } from 'hooks'
import { Icon } from 'icons'
import { useEffect } from 'react'
import styled from 'styled-components'
import { Button, Drawer, DrawerFooter, DrawerTrigger, useDrawerContext } from 'ui'
import { CarouselProvider } from '~/components/Carousel'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useCarouselSlots } from '~/domains/reservation/hooks/useCarouselSlots'
import { useReservationFlow } from '~/domains/reservation/hooks/useReservationFlow'
import { type ReservationFormValues } from '~/domains/reservation/types'
import { TherapistOverviewCard } from '~/domains/therapist/TherapistOverviewCard'
import { type UserTherapist } from '~/domains/therapist/types'
import { CompareCalendars } from '../common/CompareCalendars'
import { ReservationDurationText } from '../common/ReservationDurationText'
import { ReservationForm } from '../common/ReservationForm'
import { ReservationSubmitButton } from '../common/ReservationSubmitButton'
import { TimeSlotDaysTherapists } from '../TimeSlots/TimeSlotDaysTherapists'

type NavigationElementProps = {
  $dir: 'left' | 'right'
  $show: boolean
}

const NavigationElement = styled(Flex)
  .attrs<NavigationElementProps>(({ $dir, $show }) => ({
    $position: 'absolute',
    $top: '52px',
    $opacity: $show ? 1 : 0,
    $left: ($dir === 'left' && `calc(${SPACING_SM_VALUE} * -1)`) || 'auto',
    $right: ($dir === 'right' && `calc(${SPACING_SM_VALUE} * -1)`) || 'auto',
  }))
  .withConfig({ displayName: 'BookBySuggestedTherapistsOverviewsNavigation' })`
    transition: opacity 0.3s;
    ${({ $show }) =>
      !$show &&
      `
        cursor: default;
        pointer-events: none;
      `}
  `

const Navigation = () => {
  const { goBack, goNext, index, suggestedTherapistsProfiles } = useReservationFlow()

  return (
    <>
      <NavigationElement $dir="left" $show={index > 0}>
        <Pressable onClick={goBack}>
          <Icon colorName="lighter" fillColorName="darker" name="chevron-left-circle" size={24} />
        </Pressable>
      </NavigationElement>

      <NavigationElement $dir="right" $show={index < suggestedTherapistsProfiles.length - 1}>
        <Pressable onClick={goNext}>
          <Icon colorName="lighter" fillColorName="darker" name="chevron-right-circle" size={24} />
        </Pressable>
      </NavigationElement>
    </>
  )
}

const OpenCalendarHeader = () => (
  <Flex $align="center" $direction="row" $justify="space-between">
    <Flex $align="center" $grow={1}>
      <TranslationMarkdown id="reservation.bookFreeTherapySession" kind="h3" />
    </Flex>
  </Flex>
)

type DrawerCalendarContentProps = {
  compareTherapists: boolean
  selectedTherapist: UserTherapist | undefined
  suggestedTherapistsProfiles: UserTherapist[]
  toggleCompareTherapists?: VoidFunction | undefined
  onSubmit: ({ selectedTimeSlotWithTherapist }: ReservationFormValues) => void
}

const DrawerCalendarContent = ({
  compareTherapists,
  selectedTherapist,
  suggestedTherapistsProfiles,
  toggleCompareTherapists,
  onSubmit,
}: DrawerCalendarContentProps) => {
  const { close } = useDrawerContext()
  const { length } = useCarouselSlots({ compareTherapists, selectedTherapist, suggestedTherapistsProfiles })

  const { isLarge } = useBreakpoints()

  useEffect(() => {
    if (isLarge) {
      close()
    }
  }, [close, isLarge])

  return (
    <ReservationForm onSubmit={onSubmit}>
      <CarouselProvider length={length}>
        <Flex $gap={SPACING_XS} $grow={1} $pt={SPACING_MD} $shrink={1}>
          <OpenCalendarHeader />
          <TimeSlotDaysTherapists
            compareTherapists={compareTherapists}
            selectedTherapist={selectedTherapist}
            suggestedTherapistsProfiles={suggestedTherapistsProfiles}
            withinDrawer
          />

          <DrawerFooter>
            <Flex $gap={SPACING_MD} $grow={1}>
              <ReservationDurationText />

              <Flex $align="center" $direction="row" $gap={SPACING_MD}>
                <Flex $grow={1} $shrink={1}>
                  <Button isGhost kind="primary" onClick={close}>
                    <Translation id="actions.cancel" />
                  </Button>
                </Flex>
                <Flex $grow={1} $shrink={1}>
                  <ReservationSubmitButton />
                </Flex>
              </Flex>

              <CompareCalendars
                alignCenter
                compareTherapists={compareTherapists}
                showBorder
                suggestedTherapistsProfiles={suggestedTherapistsProfiles}
                toggleCompareTherapists={toggleCompareTherapists}
              />
            </Flex>
          </DrawerFooter>
        </Flex>
      </CarouselProvider>
    </ReservationForm>
  )
}

type OpenCalendarProps = {
  compareTherapists: boolean
  selectedTherapist: UserTherapist | undefined
  suggestedTherapistsProfiles: UserTherapist[]
  toggleCompareTherapists?: VoidFunction | undefined
  onSubmit: ({ selectedTimeSlotWithTherapist }: ReservationFormValues) => void
}

export const TherapistOverviewCardOpenCalendar = ({
  compareTherapists,
  selectedTherapist,
  suggestedTherapistsProfiles,
  toggleCompareTherapists,
  onSubmit,
}: OpenCalendarProps) => (
  <>
    <DrawerTrigger>
      <Flex $grow={1} $lgHide>
        <Button kind="primary" size="md">
          <Translation id="therapist.overviewCard.cta.showCalendar" />
        </Button>
      </Flex>
    </DrawerTrigger>

    <Drawer>
      <DrawerCalendarContent
        compareTherapists={compareTherapists}
        onSubmit={onSubmit}
        selectedTherapist={selectedTherapist}
        suggestedTherapistsProfiles={suggestedTherapistsProfiles}
        toggleCompareTherapists={toggleCompareTherapists}
      />
    </Drawer>
  </>
)

type BookBySuggestedTherapistsOverviewsProps = {
  onSubmit: ({ selectedTimeSlotWithTherapist }: ReservationFormValues) => void
  toggleCompareTherapists: VoidFunction
}

export const BookBySuggestedTherapistsOverviews = ({
  onSubmit,
  toggleCompareTherapists,
}: BookBySuggestedTherapistsOverviewsProps) => {
  const { compareTherapists, selectedTherapist, suggestedTherapistsProfiles } = useReservationFlow()

  return (
    <PositionRelative $align="center" $maxWidth="fit-content" data-test-id="therapist-overview-card">
      <TherapistOverviewCard
        calendarCTA={
          <TherapistOverviewCardOpenCalendar
            compareTherapists={compareTherapists}
            onSubmit={onSubmit}
            selectedTherapist={selectedTherapist}
            suggestedTherapistsProfiles={suggestedTherapistsProfiles}
            toggleCompareTherapists={toggleCompareTherapists}
          />
        }
      />
      <Navigation />
    </PositionRelative>
  )
}
