import { Flex } from 'cdk'
import { SPACING_XL } from 'design-tokens'
import { type PropsWithChildren } from 'react'
import { useTherapist } from '~/hooks/useTherapist'
import { useTherapistProfessionTypeValue } from '~/hooks/useTherapistProfessionTypeValue'
import { shouldShowYearsOfExperience } from '~/utils/shouldShowYearsOfExperience'
import { useTherapistYearOfExperience } from '../../hooks/useTherapistYearOfExperience'
import { TherapistOverviewCardDetailContentCertifications } from './DetailContentCertifications'
import { TherapistOverviewCardDetailContentFormations } from './DetailContentFormations'
import { TherapistOverviewCardDetailContentIndicators } from './DetailContentIndicators'
import { TherapistOverviewCardDetailContentSelfDescription } from './DetailContentSelfDescription'
import { TherapistOverviewCardDetailContentTreatments } from './DetailContentTreatments'
import { TherapistOverviewCardDetailContentWhatToKnow } from './DetailContentWhatToKnow'

export const TherapistOverviewCardDetailContent = ({ children }: PropsWithChildren) => {
  const {
    therapist: {
      about,
      aboutCoach,
      age,
      numberOfPatients,
      professionalExperiences: experiences,
      registerRegistrationYearPsychologist: registrationYear,
      sex,
      therapistCertifications: certifications,
      therapistProfessionalStyles,
      therapistWorkplaces,
      therapySchool,
      workRegion,
    },
  } = useTherapist()

  const yearsOfExperience = useTherapistYearOfExperience({ registrationYear })
  const showYearsOfExperience = shouldShowYearsOfExperience(yearsOfExperience)

  const formalityScore = therapistProfessionalStyles?.find((style) => style.type === 'FORMALITY')?.score
  const rationalityScore = therapistProfessionalStyles?.find((style) => style.type === 'RATIONALITY')?.score

  const directivityScore = therapistProfessionalStyles?.find((style) => style.type === 'DIRECTIVITY')?.score
  const structuringScore = therapistProfessionalStyles?.find((style) => style.type === 'STRUCTURING')?.score

  const { isCoach, isNutritionist } = useTherapistProfessionTypeValue()

  return (
    <Flex $gap={SPACING_XL}>
      <TherapistOverviewCardDetailContentWhatToKnow
        age={age}
        numberOfPatients={numberOfPatients}
        orientation={!isNutritionist ? therapySchool?.name : undefined}
        therapistWorkplaces={therapistWorkplaces}
        workRegion={workRegion}
        yearsOfExperience={showYearsOfExperience && yearsOfExperience}
      />

      <TherapistOverviewCardDetailContentTreatments sex={sex} />

      {!isNutritionist && (
        <TherapistOverviewCardDetailContentIndicators
          directivityScore={directivityScore}
          formalityScore={formalityScore}
          rationalityScore={rationalityScore}
          sex={sex}
          structuringScore={structuringScore}
        />
      )}

      <TherapistOverviewCardDetailContentSelfDescription about={isCoach ? (aboutCoach ?? about) : about} sex={sex} />

      <TherapistOverviewCardDetailContentFormations experiences={experiences} />

      <TherapistOverviewCardDetailContentCertifications certifications={certifications} sex={sex} />

      {children}
    </Flex>
  )
}
