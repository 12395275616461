import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useSuspenseTherapyPathByIdQuery } from '~/domains/reservation/hooks/useTherapyPathByIdQuery'
import { useTherapySessionMeetingDuration } from '~/hooks/useTherapySessionMeetingDuration'

export const ReservationDurationText = () => {
  const { therapyPath } = useSuspenseTherapyPathByIdQuery()
  const { minutes } = useTherapySessionMeetingDuration({
    firstTherapySession: true,
    therapyPathType: therapyPath.type,
  })
  const isNutrition = ['PATH_NUTRITION_WEIGHT_LOSS', 'PATH_NUTRITION_DCA'].includes(therapyPath.type)

  return (
    <TranslationMarkdown
      colorName="neutral-70"
      id="reservation.bookBySuggestedTherapist.duration"
      kind="caption"
      values={{ minutes: isNutrition ? 15 : minutes }}
    />
  )
}
