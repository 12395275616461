import loadable from '@loadable/component'
import { Flex, PositionAbsolute, PositionRelative } from 'cdk'
import { SPACING_4XL, SPACING_LG, SPACING_SM } from 'design-tokens'
import styled from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'

const LazyClinicalDirectorAvatarVariant = loadable(() => import('./ClinicalDirectorAvatarVariant.lazy'), {
  resolveComponent: (components) => components.ClinicalDirectorAvatarVariant,
})

const ToastCardWrapper = styled(Flex)`
  margin: 0 calc(${SPACING_4XL} * -1);
`

type ClinicalDirectorQuoteProps = {
  title:
    | Extract<TranslationId, `clinicalTests.clinicalDirector.${string}.title`>
    | Extract<TranslationId, `formFlow.clinicalDirector.quote${string}.title`>
}

export const ClinicalDirectorQuoteToast = ({ title }: ClinicalDirectorQuoteProps) => (
  <ToastCardWrapper $backgroundColorName="neutral-100" $px={`calc(${SPACING_4XL} + ${SPACING_LG})`} $py={SPACING_SM}>
    <Flex $direction="row" $maxWidth={464}>
      <PositionRelative $basis={96}>
        <PositionAbsolute $bottom={-12} $left={-64}>
          <LazyClinicalDirectorAvatarVariant />
        </PositionAbsolute>
      </PositionRelative>
      <Flex $direction="row" $justify="center" $shrink={1}>
        <Flex $basis={320} $gap={8} $shrink={1}>
          <Text colorName="lighter" fontWeight="400" kind="paragraph">
            <Translation id={title} />
          </Text>
          <Flex>
            <Text colorName="neutral-50" fontWeight="600" kind="caption">
              Domenico De Donatis
            </Text>
            <Text colorName="neutral-50" kind="footnote">
              <Translation id="generic.clinicalDirector" />
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </ToastCardWrapper>
)
