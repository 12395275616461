import { gql, useQuery } from '@apollo/client'
import { type ReferredUserIdsQuery, type ReferredUserIdsQueryVariables } from '~/types/graphql'
import { useCurrentUser } from './useCurrentUser'

const QUERY = gql`
  query referredUserIds($userId: String!) {
    referredUserIds(userId: $userId) {
      remainingMemberGetMemberRewards
      totalMemberGetMemberRewards
    }
  }
`

export const useReferredUserIds = () => {
  const { id: userId } = useCurrentUser()

  const { data, error, loading } = useQuery<ReferredUserIdsQuery, ReferredUserIdsQueryVariables>(QUERY, {
    variables: {
      userId,
    },
  })

  return {
    error: error != null,
    loading,
    remainingMemberGetMemberRewards: data?.referredUserIds?.remainingMemberGetMemberRewards ?? 0,
    totalMemberGetMemberRewards: data?.referredUserIds?.totalMemberGetMemberRewards ?? 0,
  }
}
