import { Flex } from 'cdk'
import { SPACING_2XL, SPACING_LG, SPACING_SM } from 'design-tokens'
import { MenuNativeAppVersion } from '~/domains/appNative/components/MenuNativeAppVersion'
import { MenuLanguageSwitcher } from '~/i18n/components/MenuLanguageSwitcher'
import { MenuItemLogout } from '../items/MenuItemLogout'

export const MenuFooter = () => (
  <Flex $gap={SPACING_SM} $pb={SPACING_LG} $pt={SPACING_2XL}>
    <MenuItemLogout />
    <MenuLanguageSwitcher />
    <MenuNativeAppVersion />
  </Flex>
)
