import { useEffect } from 'react'
import { useTrackEventImpression } from '~/domains/analytics/hooks/useTrackEventImpression'
import { type UserTherapist } from '~/domains/therapist/types'

type Params = {
  selectedTherapist: UserTherapist
}

export const PreReservationImpressionTracker = ({ selectedTherapist }: Params) => {
  const trackEvent = useTrackEventImpression('pre-reservation.therapists')

  useEffect(() => {
    if (!selectedTherapist) {
      return
    }

    trackEvent({ selectedTherapistId: selectedTherapist?.id })
  }, [selectedTherapist, trackEvent])

  return null
}
