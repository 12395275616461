import { FONT_WEIGHT_600_VALUE } from 'design-tokens'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'

type Props = {
  id: TranslationId
}

export const MenuGroupTitle = ({ id }: Props) => (
  <Text colorName="primary" fontWeight={FONT_WEIGHT_600_VALUE} kind="paragraph">
    <Translation id={id} />
  </Text>
)
