import { Flex } from 'cdk'
import { SPACING_MD } from 'design-tokens'
import { memo, useCallback } from 'react'
import { Text } from 'ui'
import { IntercomWithBadge } from '~/components/AppBar/components/IntercomWithBadge'
import { Translation } from '~/components/Translation'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useTabNavigationMenu } from '~/domains/navigation/NewNavigation'
import { MenuAction } from '../common/MenuAction'

export const MenuItemWriteUs = memo(() => {
  const { openWithMessage } = useIntercom()
  const { closeMenu } = useTabNavigationMenu()

  const handleClick = useCallback(() => {
    openWithMessage()

    closeMenu()
  }, [closeMenu, openWithMessage])

  return (
    <MenuAction onClick={handleClick}>
      <Flex $maxHeight={24} $pr={SPACING_MD}>
        <IntercomWithBadge />
      </Flex>

      <Flex $grow={1} $shrink={1}>
        <Text kind="paragraph">
          <Translation id="menu.help.writeUs" />
        </Text>
      </Flex>
    </MenuAction>
  )
})

MenuItemWriteUs.displayName = 'MenuItemWriteUs'
