import { Flex, media, Pressable } from 'cdk'
import {
  FONT_WEIGHT_500_VALUE,
  SPACING_2XS,
  SPACING_4XL,
  SPACING_5XL,
  SPACING_LG,
  SPACING_MD,
  SPACING_SM,
  TIME_150,
} from 'design-tokens'
import styled from 'styled-components'
import { Button, Text, useDrawerContext } from 'ui'
import { CarouselProvider } from '~/components/Carousel'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { GLOBAL_VALUE_SEPARATOR } from '~/constants'
import { getSignupWish } from '~/domains/auth/hooks/useSignupWish'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { useUserFormFlowResponseCompleted } from '~/domains/formFlow/hooks/useUserFormFlowResponseCompleted'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { TherapistOverviewCard } from '~/domains/therapist/TherapistOverviewCard'
import { OpenResumeCTA } from '~/domains/therapist/TherapistOverviewCard/OpenResumeCTA'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useTherapist } from '~/hooks/useTherapist'
import { getRoute } from '~/utils/getRoute'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { PreReservationImpressionTracker } from '../../components/PreReservationImpressionTracker'
import { useCarouselSlots } from '../../hooks/useCarouselSlots'
import { useSuspenseTherapyPathByIdQuery } from '../../hooks/useTherapyPathByIdQuery'
import { type ReservationFormValues } from '../../types'
import { ReservationDurationText } from '../BookBySuggestedTherapists/common/ReservationDurationText'
import { ReservationForm } from '../BookBySuggestedTherapists/common/ReservationForm'
import { ReservationSubmitButton } from '../BookBySuggestedTherapists/common/ReservationSubmitButton'
import { TherapistOverviewCardOpenCalendar } from '../BookBySuggestedTherapists/TherapistsOverviews'
import { TimeSlotDaysTherapists } from '../BookBySuggestedTherapists/TimeSlots/TimeSlotDaysTherapists'

type HaveDoubtsProps = {
  isNutrition: boolean
}

type TranslatedFlexProps = {
  $translateX: string
}

const TranslatedFlex = styled(Flex)<TranslatedFlexProps>`
  transition: transform ${TIME_150} ease-in-out;

  ${({ $translateX }) => media.md`
    transform: translateX(${$translateX});
  `}
`

const HaveDoubts = ({ isNutrition }: HaveDoubtsProps) => {
  const { userFormFlowResponseCompletedId: formFlowId } = useUserFormFlowResponseCompleted()
  const rootHistory = useRootHistory()

  const handleClick = () => {
    setLocalStorage('pre-booking-compare-new-therapist', formFlowId)

    rootHistory.push(`${getRoute('/signup')}?wish=${getSignupWish('comparing')}`)
  }

  return (
    <Flex $direction="row" $gap={SPACING_2XS} $justify="center">
      <Text colorName="neutral-70" kind="paragraph">
        <Translation id="reservation.therapist.overviewCard.haveDoubts.text" />
      </Text>
      <Pressable data-test-id="have-doubts-cta" onClick={handleClick}>
        <TranslationMarkdown
          colorName="primary-50"
          fontWeight={FONT_WEIGHT_500_VALUE}
          id={
            isNutrition
              ? 'reservation.therapist.overviewCard.haveDoubts.cta.nutritionist'
              : 'reservation.therapist.overviewCard.haveDoubts.cta'
          }
          kind="paragraph"
        />
      </Pressable>
    </Flex>
  )
}

export const PreBookingTherapist = () => {
  const form = useReactHookForm<ReservationFormValues>({})
  const therapist = useTherapist()
  const { therapyPath } = useSuspenseTherapyPathByIdQuery()
  const rootHistory = useRootHistory()
  const { isVariant1 } = useFeatureFlagsByFingerprint()
  const { isOpen } = useDrawerContext()
  const { length } = useCarouselSlots({
    compareTherapists: false,
    selectedTherapist: therapist,
    suggestedTherapistsProfiles: [therapist],
  })

  form.register('selectedTimeSlotWithTherapist', { required: true })

  const isNutrition = ['PATH_NUTRITION_WEIGHT_LOSS', 'PATH_NUTRITION_DCA'].includes(therapyPath.type)
  const isVersionB = isVariant1('ff_pre_signup_therapists')

  const onSubmit = ({
    selectedTimeSlotWithTherapist,
  }: Pick<ReservationFormValues, 'selectedTimeSlotWithTherapist'>) => {
    setLocalStorage('pre-booking-selected-time-slot', selectedTimeSlotWithTherapist)

    rootHistory.push(`${getRoute('/signup')}?wish=${getSignupWish('booking')}`)
  }

  const handleOpenCalendar = () => {
    setLocalStorage('pre-booking-selected-therapist', `${therapist.id}${GLOBAL_VALUE_SEPARATOR}${therapist.firstName}`)

    rootHistory.push(`${getRoute('/signup')}?wish=${getSignupWish('calendar')}`)
  }

  const handleOpenResume = () => {
    setLocalStorage('pre-booking-selected-therapist', `${therapist.id}${GLOBAL_VALUE_SEPARATOR}${therapist.firstName}`)

    rootHistory.push(`${getRoute('/signup')}?wish=${getSignupWish('curriculum')}`)
  }

  const openResumeCTA = isVersionB ? <OpenResumeCTA onClick={handleOpenResume} /> : null

  return (
    <>
      <PreReservationImpressionTracker selectedTherapist={therapist} />
      <ReactHookFormProvider {...form}>
        <Flex $grow={1} $maxWidth={1440}>
          <TranslatedFlex
            $gap={SPACING_LG}
            $grow={1}
            $hide={false}
            $justify="center"
            $lgHide
            $translateX={isOpen ? '-33%' : '0'}
          >
            <TherapistOverviewCard
              calendarCTA={
                isVersionB ? (
                  <Button kind="primary" onClick={handleOpenCalendar} size="md">
                    <Translation id="therapist.overviewCard.cta.showCalendar" />
                  </Button>
                ) : (
                  <TherapistOverviewCardOpenCalendar
                    compareTherapists={false}
                    onSubmit={onSubmit}
                    selectedTherapist={therapist}
                    suggestedTherapistsProfiles={[therapist]}
                  />
                )
              }
              openResumeCTA={openResumeCTA}
            />
            <HaveDoubts isNutrition={isNutrition} />
          </TranslatedFlex>
          <ReservationForm onSubmit={onSubmit}>
            <Flex
              $direction="row"
              $gap={SPACING_5XL}
              $grow={1}
              $hide
              $justify="space-between"
              $lgHide={false}
              $pb={SPACING_MD}
              $pt={SPACING_4XL}
              $px={SPACING_4XL}
            >
              <Flex $gap={SPACING_LG}>
                <TherapistOverviewCard calendarCTA={null} openResumeCTA={openResumeCTA} />
                <HaveDoubts isNutrition={isNutrition} />
              </Flex>
              <Flex $gap={SPACING_MD} $grow={1} $shrink={1}>
                <Flex $align="center" $direction="row" $justify="space-between" $shrink={1} $wrap="wrap">
                  <Flex $grow={1}>
                    <TranslationMarkdown id="reservation.bookFreeTherapySession" kind="h2" />
                  </Flex>
                </Flex>
                <Flex $gap={SPACING_SM}>
                  <CarouselProvider length={length}>
                    <TimeSlotDaysTherapists
                      compareTherapists={false}
                      selectedTherapist={therapist}
                      suggestedTherapistsProfiles={[therapist]}
                    />
                  </CarouselProvider>
                  <Flex $align="flex-end">
                    <ReservationDurationText />
                  </Flex>
                </Flex>
                <Flex $align="flex-end">
                  <ReservationSubmitButton />
                </Flex>
              </Flex>
            </Flex>
          </ReservationForm>
        </Flex>
      </ReactHookFormProvider>
    </>
  )
}
