import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { FoodJournalProvider } from '~/domains/nutrition/components/FoodJournalContext'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyFoodJournalPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.FoodJournalPageView,
})

export const FoodJournalPage: Page = {
  exact: true,
  id: 'foodJournal',
  paths: ['/food-journal'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: true,
    seoKey: 'foodJournal',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient>{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: FoodJournalProvider,
  PageView: () => <LazyFoodJournalPageView />,
}
