import { pipe } from 'fp-ts/function'
import { toCurrency } from 'numbers'
import { getEnv } from '~/utils/getEnv'
import { type Intent } from './useSignupIntent'

export const useTranslationVariablesTherapySessionCosts = (intent: Intent): Record<string, string> => {
  switch (intent) {
    case 'call':
    case 'home':
    case 'journaling':
    case 'sexology':
    case 'therapyMyself':
      return {
        therapySessionCost: toCurrency(4900),
      }
    case 'coaching':
      return {
        therapySessionCost: toCurrency(Number(getEnv('SERENIS_THERAPY_SESSION_COST_ONLINE_COACHING'))),
      }
    case 'dca':
      return {
        therapySessionCost: pipe('SERENIS_THERAPY_SESSION_COST_ONLINE_DCA', getEnv, Number, toCurrency),
        firstTherapySessionCost: pipe('SERENIS_THERAPY_SESSION_COST_ONLINE_DCA_FIRST', getEnv, Number, toCurrency),
      }
    case 'nutritionist':
      return {
        therapySessionCost: pipe('SERENIS_THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS', getEnv, Number, toCurrency),
        firstTherapySessionCost: pipe(
          'SERENIS_THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS_FIRST',
          getEnv,
          Number,
          toCurrency,
        ),
      }
    case 'psychiatry':
      return {
        therapySessionCost: toCurrency(7700),
        firstTherapySessionCost: toCurrency(8900),
      }
    case 'therapyCouple':
      return {
        therapySessionCost: toCurrency(5900),
      }
  }
}
