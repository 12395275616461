import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'
import { TextField } from './TextField'

type Props = {
  required?: boolean
}

export const SendVerificationCodePhoneField = ({ required = true }: Props) => {
  const { errors, helperTexts, labels, placeholders } = useFormTranslations()

  const { isMobilePhone } = useFormValidation()

  return (
    <TextField
      autoComplete="tel-national"
      disabled={false}
      hint={helperTexts.mobilePhone}
      label={labels.phoneNumber}
      name="phoneNumber"
      placeholder={placeholders.phoneNumberLongText}
      readOnly={false}
      rules={{
        ...(required && { required: errors.required }),
        validate: (value) => {
          if (!required && !value.length) {
            return true
          }

          return isMobilePhone(value)
        },
      }}
      type="tel"
    />
  )
}
