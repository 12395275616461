import { useCallback } from 'react'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'
import { SignupFormTextField } from './SignupFormTextField'

type FormValues = {
  email: string
  emailConfirmation: string
}

export const SignupFormEmailWithConfirmationFields = () => {
  const form = useReactHookFormContext<FormValues>()
  const { isEmail } = useFormValidation()

  const {
    errors: { required, emailConfirmation },
    helperTexts,
    labels,
    placeholders,
  } = useFormTranslations()

  const firstEmail = form.watch('email')

  const validateEmail = useCallback(
    (value: string) => {
      if (isEmail(value) && value === firstEmail) {
        return true
      }

      return emailConfirmation
    },
    [emailConfirmation, firstEmail, isEmail],
  )

  return (
    <>
      <SignupFormTextField
        autoComplete="email"
        hint={helperTexts.emailSignup}
        label={labels.email}
        name="email"
        placeholder={placeholders.email}
        rules={{
          required,
          validate: isEmail,
        }}
        type="email"
      />
      <SignupFormTextField
        autoComplete="email"
        hint={helperTexts.emailConfirmation}
        label={labels.emailConfirmation}
        name="emailConfirmation"
        placeholder={placeholders.email}
        rules={{
          required,
          validate: validateEmail,
        }}
        type="email"
      />
    </>
  )
}
