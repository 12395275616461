import { Flex, type FlexProps, PositionRelative } from 'cdk'
import { toDayOfMonth, toMonthNameShort, toWeekDayShort } from 'dates'
import {
  BORDER_RADIUS_XS,
  BORDER_WIDTH_0,
  BORDER_WIDTH_1,
  COLOR_LIGHTER,
  COLOR_PRIMARY_50,
  COLOR_TRANSPARENT,
  SPACING_0,
  SPACING_4XS,
} from 'design-tokens'
import { type PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { Text } from 'ui'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'

export const TIME_SLOT_DAY_HEADER_HEIGHT = 40

const Container = styled(PositionRelative)<{ $isMultiple: boolean }>`
  ${({ $isMultiple = false }) =>
    !$isMultiple &&
    css`
      margin: 0;
      outline: ${BORDER_WIDTH_1} solid ${COLOR_TRANSPARENT};

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 1;
        border-radius: ${BORDER_RADIUS_XS};
        outline: ${BORDER_WIDTH_1} solid ${COLOR_TRANSPARENT};
        pointer-events: none;
      }

      &:has(:checked) {
        outline-color: ${COLOR_LIGHTER};

        &::after {
          outline-color: ${COLOR_PRIMARY_50};
        }

        .time-slot-list-header-text * {
          color: ${COLOR_PRIMARY_50};
        }
      }
    `};

  &:has(:checked) {
    .time-slot-list-header-text * {
      color: ${COLOR_PRIMARY_50};
    }
  }
`

type Props = PropsWithChildren<{
  className?: string
  containerWidth: string
  day: Date
  isMultiple?: boolean
}> &
  FlexProps

export const TimeSlotDayTherapists = ({ children, containerWidth, day, isMultiple = false }: Props) => (
  <Container $grow={1} $isMultiple={isMultiple} $maxWidth={containerWidth} $minWidth={containerWidth}>
    <Flex
      $align="center"
      $backgroundColorName="lighter"
      $borderColorName="neutral-40"
      $borderSizeBottom={isMultiple ? BORDER_WIDTH_0 : BORDER_WIDTH_1}
      $direction="column"
      $gap={SPACING_4XS}
      $justify="center"
      $maxHeight={TIME_SLOT_DAY_HEADER_HEIGHT}
      $minHeight={TIME_SLOT_DAY_HEADER_HEIGHT}
    >
      <Text className="time-slot-list-header-text">
        <TranslationMarkdown
          id="timeSlot.header.date"
          kind="paragraph"
          values={{
            dayShort: toWeekDayShort(day),
            dayNumber: toDayOfMonth(day),
            monthShort: toMonthNameShort(day),
          }}
        />
      </Text>
    </Flex>
    <Flex
      $backgroundColorName="neutral-40"
      $direction="row"
      $gap={BORDER_WIDTH_1}
      $grow={1}
      $pb={SPACING_0}
      role="radiogroup"
    >
      {children}
    </Flex>
  </Container>
)
