import { Flex, PositionRelative } from 'cdk'
import { OPACITY_24, SPACING_MD } from 'design-tokens'
import { type PropsWithChildren } from 'react'
import styled from 'styled-components'

const PositionRelativeWithShadow = styled(PositionRelative)`
  box-shadow: 0 0.5px 0 0 rgb(from var(--srns-color-primary) r g b / ${OPACITY_24}) inset;
`

export const FlowNavigationBar = ({ children }: PropsWithChildren) => (
  <PositionRelativeWithShadow $backgroundColorName="white" $py={16}>
    <Flex $maxWidth={464} $px={SPACING_MD}>
      {children}
    </Flex>
  </PositionRelativeWithShadow>
)
