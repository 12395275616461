import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'

export const LoginMenuTitle = () => (
  <>
    <Text fontWeight="600" kind="h1">
      <Translation id="generic.login.title" />
    </Text>
    <Flex pt={16}>
      <Text fontWeight="400" kind="paragraph">
        <Translation id="generic.login.subtitle" />
      </Text>
    </Flex>
  </>
)
