/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { type ColorName } from '../types/color'
import { cssvar, cssvarName, cssvarValue } from '../utils/cssvar'

export const DESIGN_TOKENS_COLOR_PREFIX = 'color'

export const cssvarNameColor = (color: ColorName) => cssvarName(`${DESIGN_TOKENS_COLOR_PREFIX}-${color}`)

export const cssvarValueColor = (color: ColorName) => cssvarValue(`${DESIGN_TOKENS_COLOR_PREFIX}-${color}`)

export const cssvarColor = (color: ColorName) => cssvar(`${DESIGN_TOKENS_COLOR_PREFIX}-${color}`)
