import { Flex } from 'cdk'
import {
  BORDER_RADIUS_SM,
  BORDER_WIDTH_2,
  COLOR_PRIMARY_40,
  COLOR_PRIMARY_50,
  COLOR_PRIMARY_80,
  COLOR_TRANSPARENT,
  ELEVATION_SM,
  SPACING_MD,
  SPACING_SM,
  SPACING_XS,
  TIME_150,
} from 'design-tokens'
import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Text } from 'ui'
import { type TherapyPathKey } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathCard'
import { StartTherapyPathIcon } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathIcon'
import { StartTherapyPathName } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathName'
import { validFirstTherapySessionFree } from '~/domains/therapies/constants'
import { type TranslationId } from '~/i18n/types'
import { ChipFirstTherapySessionFree } from './ChipFirstTherapySessionFree'
import { Translation } from './Translation'

type TherapyPathProps = {
  $active?: boolean
  therapyPathType: TherapyPathKey
}

const therapyPathTranslations: Record<
  TherapyPathKey,
  Extract<TranslationId, `therapies.startTherapyPath.card.description.${string}`>
> = {
  MYSELF_PSYCHOTHERAPY: 'therapies.startTherapyPath.card.description.individual',
  COUPLES_PSYCHOTHERAPY: 'therapies.startTherapyPath.card.description.couples',
  PATH_COACHING: 'therapies.startTherapyPath.card.description.coaching',
  PATH_NUTRITION_WEIGHT_LOSS: 'therapies.startTherapyPath.card.description.nutrition.weightLoss',
  PATH_NUTRITION_DCA: 'therapies.startTherapyPath.card.description.nutrition.dca',
  PATH_SEXOLOGY: 'therapies.startTherapyPath.card.description.sexology',
  MYSELF_PSYCHIATRY: 'therapies.startTherapyPath.card.description.psychiatry',
}

const TherapyPathContainerVariant = styled(Flex)<Pick<TherapyPathProps, '$active'>>`
  outline: ${BORDER_WIDTH_2} solid ${COLOR_TRANSPARENT};
  transition:
    box-shadow ${TIME_150} ease-out,
    outline-color ${TIME_150} ease-out;

  ${({ $active }) => css`
    box-shadow: ${$active && ELEVATION_SM};
    outline-color: ${$active && COLOR_PRIMARY_50};

    &:hover {
      box-shadow: ${ELEVATION_SM};
      outline-color: ${$active ? COLOR_PRIMARY_80 : COLOR_PRIMARY_40};
    }
  `}
`

export const TherapyPathCard = ({ $active = false, therapyPathType }: TherapyPathProps) => {
  const showChip = useMemo(() => validFirstTherapySessionFree.includes(therapyPathType), [therapyPathType])

  return (
    <TherapyPathContainerVariant
      $active={$active}
      $backgroundColorName="lighter"
      $borderRadius={BORDER_RADIUS_SM}
      $elevationName="xxs"
      $p={SPACING_SM}
    >
      <Flex $direction="column" $gap={SPACING_XS}>
        <Flex $direction="row" $gap={SPACING_MD} $justify="space-between">
          <StartTherapyPathIcon size={48} therapyPathType={therapyPathType} />
          {showChip && (
            <Flex>
              <ChipFirstTherapySessionFree />
            </Flex>
          )}
        </Flex>
        <Flex>
          <StartTherapyPathName therapyPathType={therapyPathType} />
          <Text kind="paragraph">
            <Translation id={therapyPathTranslations[therapyPathType]} />
          </Text>
        </Flex>
      </Flex>
    </TherapyPathContainerVariant>
  )
}
