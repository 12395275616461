import { Flex } from 'cdk'
import { SPACING_XL, SPACING_XS } from 'design-tokens'
import { type PropsWithChildren } from 'react'
import { Drawer, DrawerHeader, DrawerProvider, DrawerTrigger } from 'ui'
import { useValidTherapistProfessionTypeValue } from '~/domains/reservation/pages/ThankYouPage/hooks/useValidTherapistProfessionTypeValue'
import { useTherapist } from '~/hooks/useTherapist'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { TherapistOverviewCardDetailContent } from './DetailContent'
import { TherapistOverviewCardDetailHeader } from './DetailHeader'

type TherapistOverviewCardDetailProps = PropsWithChildren<{
  footer?: React.ReactNode
  therapyPath: TherapyTherapyPathType
}>

export const TherapistOverviewCardDetail = ({ children, footer, therapyPath }: TherapistOverviewCardDetailProps) => {
  const { fullName, therapist } = useTherapist()

  const { profileImage, sex } = therapist

  const titleId = useValidTherapistProfessionTypeValue({ pathType: therapyPath, sex })

  return (
    <DrawerProvider>
      <DrawerTrigger>{children}</DrawerTrigger>

      <Drawer>
        <Flex $gap={SPACING_XS} $pb={SPACING_XL}>
          <DrawerHeader>
            <TherapistOverviewCardDetailHeader
              fullName={fullName}
              profileImage={profileImage?.m ?? ''}
              titleId={titleId}
            />
          </DrawerHeader>
          <TherapistOverviewCardDetailContent>{footer}</TherapistOverviewCardDetailContent>
        </Flex>
      </Drawer>
    </DrawerProvider>
  )
}
