import { type ColorName, cssvarColor, SPACING_MD, type TypographyName } from 'design-tokens'
import { memo } from 'react'
import ReactMarkdown, { type Components } from 'react-markdown'
import { Link as ReactRouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { Link, Text, type TextProps as UiTextProps } from 'ui'
import { Flex, type FlexProps as UiFlexProps, ListItem } from 'ui-deprecated'
import { OrderedList } from './OrderedList'

const allowedElements: (keyof JSX.IntrinsicElements)[] = ['h3', 'a', 'em', 'li', 'p', 'strong', 'u', 'ul', 'ol', 'del']

type FlexProps = Pick<UiFlexProps, 'lgPt' | 'mdPt' | 'pt'>
type TextProps = Pick<UiTextProps, 'ellipsis' | 'fontWeight' | 'textAlign' | 'kind'>

type Props = {
  children: string
  colorName?: ColorName
  colorNameBold?: ColorName
  kind?: TypographyName
  target?: '_blank' | '_self'
} & FlexProps &
  TextProps

type Param = Required<Pick<Props, 'colorName' | 'kind' | 'target'>> &
  FlexProps &
  TextProps &
  Pick<Props, 'colorNameBold'>

type UnorderedListAttrs = {
  colorName?: ColorName
  listStyleType?: 'disc' | 'none'
}

const UnorderedList = styled.ul<UnorderedListAttrs>`
  ${({ colorName }) => colorName && `color: ${cssvarColor(colorName)};`}
  padding-left: ${SPACING_MD};
  list-style-type: ${({ listStyleType = 'disc' }) => listStyleType};
`

const getComponents = ({
  textAlign,
  colorName,
  colorNameBold,
  ellipsis,
  fontWeight,
  kind,
  lgPt,
  mdPt,
  pt,
  target,
}: Param): Components => ({
  h3: ({ children }) => <Text kind="h3">{children}</Text>,
  a: ({ children, href }) => {
    if (href === '') {
      return (
        <Text as={Link} kind={kind} textDecoration="underline">
          {children}
        </Text>
      )
    }

    return target === '_self' && href ? (
      <Link as={ReactRouterLink} to={href}>
        <Text kind={kind}>{children}</Text>
      </Link>
    ) : (
      <Text as={Link} href={href as string} kind={kind} target={target} textDecoration="underline">
        {children}
      </Text>
    )
  },
  em: ({ children }) => (
    <Text as="em" colorName={colorName} fontStyle="italic" kind={kind}>
      {children}
    </Text>
  ),
  li: ({ children }) => (
    <ListItem>
      <Text as="p" breakWord colorName={colorName} kind={kind} newLine>
        {children}
      </Text>
    </ListItem>
  ),
  p: ({ children }) => (
    <Flex lgPt={lgPt} mdPt={mdPt} pt={pt}>
      <Text
        as="p"
        breakWord
        colorName={colorName}
        ellipsis={ellipsis}
        fontWeight={fontWeight}
        kind={kind}
        newLine
        textAlign={textAlign}
      >
        {children}
      </Text>
    </Flex>
  ),
  strong: ({ children }) => (
    <Text as="strong" colorName={colorNameBold || colorName} fontWeight="600" kind={kind}>
      {children}
    </Text>
  ),
  u: ({ children }) => (
    <Text as="u" colorName={colorName} kind={kind} textDecoration="underline">
      {children}
    </Text>
  ),
  ul: ({ children }) => (
    <Flex py={16}>
      <UnorderedList colorName={colorName}>{children}</UnorderedList>
    </Flex>
  ),
  ol: ({ children }) => (
    <Flex pt={16}>
      <OrderedList>{children}</OrderedList>
    </Flex>
  ),
})

export const Markdown = memo(
  ({
    children,
    colorName = 'black',
    colorNameBold,
    ellipsis,
    fontWeight,
    kind = 'paragraph',
    lgPt,
    mdPt,
    pt,
    target = '_blank',
    textAlign,
  }: Props) => (
    <ReactMarkdown
      allowedElements={allowedElements}
      components={getComponents({
        textAlign,
        colorName,
        colorNameBold,
        ellipsis,
        fontWeight,
        kind,
        mdPt,
        lgPt,
        pt,
        target,
      })}
      unwrapDisallowed
    >
      {children}
    </ReactMarkdown>
  ),
)

Markdown.displayName = 'Markdown'
