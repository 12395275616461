import { Chip } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { AppointmentDetails } from '../components/AppointmentDetails'
import { ConnectToLiveButton } from '../components/ConnectToLiveButton'
import { ContentSkeleton } from '../components/ContentSkeleton'
import { ManageButtonVariant } from '../components/ManageButtonVariant'
import { PayNowButton } from '../components/PayNowButton'
import { Price } from '../components/Price'

type Props = {
  isVeryNextTherapySession?: boolean
}

export const BookedWithPaymentMethodContent = ({ isVeryNextTherapySession }: Props) => {
  const { isControl } = useFeatureFlagsByUserId()

  return (
    <ContentSkeleton
      bottomChildren={
        <>
          <ConnectToLiveButton isVeryNextTherapySession={isVeryNextTherapySession} />
          {!isControl('ff_manage_therapy_session') && <ManageButtonVariant />}
          <Flex pt={8}>
            <PayNowButton />
          </Flex>
        </>
      }
      topEndChildren={<Price />}
      topStartChildren={
        <Chip kind="warning">
          <Translation id="therapySession.patient.scheduled.statusValue" />
        </Chip>
      }
    >
      <AppointmentDetails canManage />
    </ContentSkeleton>
  )
}
