import { Flex } from 'cdk'
import { SPACING_MD } from 'design-tokens'
import { Icon } from 'icons'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useIsNativeAppAndroid } from '~/domains/appNative/hooks/useIsNativeAppAndroid'
import { useIsNativeAppIOS } from '~/domains/appNative/hooks/useIsNativeAppIOS'
import { getEnv } from '~/utils/getEnv'
import { MenuAction } from '../common/MenuAction'

export const MenuItemReview = () => {
  const isNativeAppAndroid = useIsNativeAppAndroid()
  const isNativeAppIOS = useIsNativeAppIOS()

  const reviewURL = isNativeAppAndroid
    ? `${getEnv('SERENIS_PLAY_STORE')}&showAllReviews=true`
    : isNativeAppIOS
      ? `${getEnv('SERENIS_APP_STORE')}?action=write-review`
      : getEnv('SERENIS_TRUST_PILOT')

  const onClick = useCallback(() => {
    window.open(reviewURL, '_blank')
  }, [reviewURL])

  return (
    <MenuAction onClick={onClick}>
      <Flex $pr={SPACING_MD}>
        <Icon colorName="neutral-60" fillColorName="lighter" name="star" size={24} />
      </Flex>

      <Flex $align="flex-start" $grow={1} $shrink={1}>
        <Text kind="paragraph">
          <Translation id="menu.review" />
        </Text>
      </Flex>
    </MenuAction>
  )
}

MenuItemReview.displayName = 'MenuItemReview'
