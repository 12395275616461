import loadable from '@loadable/component'
import { useCallback } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex, PositionRelative, Pressable } from 'ui-deprecated'
import { TertiaryButtonIcon } from '~/components/TertiaryButton/TertiaryButtonIcon'
import { Translation } from '~/components/Translation'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useModals } from '~/domains/modals'
import { useCopyToClipboard } from '~/hooks/useCopyToClipboard'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useToasts } from '~/hooks/useToasts'
import { buildReferralSharedLink } from '../../utils/buildReferralSharedLink'
import { ShareReferralCodeModal } from '../ShareReferralCodeModal'

const Fallback = styled.div`
  width: 80px;
  height: 56px;
`

const LazyPeopleImage = loadable(() => import('./PeopleImage.lazy'), {
  resolveComponent: (components) => components.PeopleImage,
  fallback: <Fallback />,
})

const PressableWithIndex = styled(Pressable)`
  z-index: 2;
`

type Props = {
  hideImageOnSmallOnly?: boolean
}

export const ShareReferralCode = ({ hideImageOnSmallOnly = false }: Props) => {
  const { referralCode } = useCurrentUser()
  const { isVariant } = useFeatureFlagsByUserId()
  const rootHistory = useRootHistory()

  const [, copy] = useCopyToClipboard()

  const { addToast } = useToasts()

  const { open } = useModals()

  const handleOpenModal = useCallback(
    () => (isVariant('ff_new_mgm_page') ? rootHistory.push('/settings/invite') : open('referralShare')),
    [open, isVariant, rootHistory],
  )

  const handleCopyLink = useCallback(async () => {
    if (referralCode) {
      const result = await copy(buildReferralSharedLink(referralCode))

      addToast(
        result
          ? { translationId: 'referralCode.share.copied', type: 'success' }
          : { translationId: 'generic.error', type: 'alert' },
      )
    }
  }, [addToast, copy, referralCode])

  return (
    <>
      <PositionRelative
        align="center"
        background="purple08"
        borderRadius={16}
        direction="row"
        justify="space-between"
        p={16}
        zIndex={0}
      >
        <LazyPeopleImage hide={hideImageOnSmallOnly} mdHide={false} pr={16} />
        <Flex align="flex-start" grow={1} pr={8} shrink={1}>
          <Text colorName="white" fontWeight="600" kind="paragraph" textAlign="left">
            <Translation id="referralCode.share.title.suggestion" />
          </Text>
          <Flex pt={4}>
            <Text colorName="white" fontWeight="400" kind="paragraph" textAlign="left">
              <Translation id="referralCode.share.body" />
            </Text>
          </Flex>
        </Flex>
        <PressableWithIndex autoWidth onClick={handleCopyLink}>
          <TertiaryButtonIcon colorName="white" iconName="copy" />
        </PressableWithIndex>
        <Flex pl={8}>
          <PressableWithIndex autoWidth onClick={handleOpenModal}>
            <TertiaryButtonIcon colorName="white" iconName="arrow-right" />
          </PressableWithIndex>
        </Flex>
      </PositionRelative>

      <ShareReferralCodeModal />
    </>
  )
}
