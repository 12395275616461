import { createContext, type ReactNode, useCallback, useContext, useMemo, useState } from 'react'

type LoadingKey = 'apple' | 'facebook' | 'google' | 'local'

export type Context = {
  loadingKey: LoadingKey | null
  loading: boolean
  startLoading: (loadingKey: LoadingKey) => void
  stopLoading: () => void
}

type Props = {
  children: ReactNode
}

const LoadingStateContext = createContext<Context | null>(null)

export const LoadingStateProvider = ({ children }: Props) => {
  const [loadingKey, setLoadingKey] = useState<LoadingKey | null>(null)

  const startLoading = useCallback((key: LoadingKey) => {
    setLoadingKey(key)
  }, [])

  const stopLoading = useCallback(() => {
    setLoadingKey(null)
  }, [])

  const value = useMemo(
    () => ({ loadingKey, loading: loadingKey != null, startLoading, stopLoading }),
    [loadingKey, startLoading, stopLoading],
  )

  return <LoadingStateContext.Provider value={value}>{children}</LoadingStateContext.Provider>
}

export const useLoadingState = () => {
  const context = useContext(LoadingStateContext)

  if (context == null) {
    throw new Error('The `useLoadingState` should be wrapped with `LoadingStateProvider`.')
  }

  return context
}
