/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { type LineHeightName } from '../types/line-height'
import { cssvar, cssvarName, cssvarValue } from '../utils/cssvar'

export const DESIGN_TOKENS_LINE_HEIGHT_PREFIX = 'line-height'

export const cssvarNameLineHeight = (lineHeight: LineHeightName) =>
  cssvarName(`${DESIGN_TOKENS_LINE_HEIGHT_PREFIX}-${lineHeight}`)

export const cssvarValueLineHeight = (lineHeight: LineHeightName) =>
  cssvarValue(`${DESIGN_TOKENS_LINE_HEIGHT_PREFIX}-${lineHeight}`)

export const cssvarLineHeight = (lineHeight: LineHeightName) =>
  cssvar(`${DESIGN_TOKENS_LINE_HEIGHT_PREFIX}-${lineHeight}`)
