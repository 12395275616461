import { Flex } from 'cdk'
import { BORDER_RADIUS_SM, SPACING_LG } from 'design-tokens'
import styled from 'styled-components'

/** @deprecated Use this component until the new Card from `ui` is ready */
export const CardBoxNoStroke = styled(Flex).attrs((attrs) => ({
  $backgroundColorName: 'lighter',
  $borderRadius: BORDER_RADIUS_SM,
  $elevationName: 'xs',
  $overflow: 'hidden',
  $p: SPACING_LG,
  ...attrs,
}))`
  /* stylelint-disable-next-line no-empty-source */
`
