import { Flex, PositionAbsolute } from 'cdk'
import { toHoursMinutes } from 'dates'
import {
  BORDER_RADIUS_XS,
  BORDER_WIDTH_1,
  BORDER_WIDTH_2,
  COLOR_NEUTRAL_20,
  COLOR_PRIMARY_10,
  COLOR_PRIMARY_50,
  OPACITY_0,
  SPACING_4XS,
  SPACING_XS,
  TIME_150,
} from 'design-tokens'
import styled from 'styled-components'
import { Text } from 'ui'
import { GLOBAL_VALUE_SEPARATOR } from '~/constants'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { type ReservationFormValues } from '~/domains/reservation/types'

const TimeSlotContainer = styled(Flex)`
  cursor: pointer;
  transition:
    border-color ${TIME_150} ease,
    background-color ${TIME_150} ease;

  &:hover {
    background-color: ${COLOR_NEUTRAL_20};
  }

  &:has(:focus-visible) {
    outline: ${BORDER_WIDTH_2} solid ${COLOR_PRIMARY_50};
    outline-offset: ${SPACING_4XS};
  }

  &:has(:checked) {
    border-color: ${COLOR_PRIMARY_50};
    background-color: ${COLOR_PRIMARY_10};
    cursor: default;
  }
`

const TimeSlotText = styled(Text)`
  &:has(:checked) {
    color: ${COLOR_PRIMARY_50};
  }
`

type TimeSlotProps = {
  slot: Date
  therapistId: string
}

export const TimeSlot = ({ slot, therapistId }: TimeSlotProps) => {
  const form = useReactHookFormContext<ReservationFormValues>()

  return (
    <TimeSlotContainer
      $align="center"
      $backgroundColorName="lighter"
      $borderColorName="neutral-40"
      $borderRadius={BORDER_RADIUS_XS}
      $borderSize={BORDER_WIDTH_1}
      $py={SPACING_XS}
      as="label"
    >
      <TimeSlotText kind="paragraph">
        <PositionAbsolute
          $opacity={OPACITY_0}
          $zIndex={-1}
          as="input"
          data-test-id="time-slot-item-single-suggestion"
          type="radio"
          value={`${slot.toISOString()}${GLOBAL_VALUE_SEPARATOR}${therapistId}`}
          {...form.register('selectedTimeSlotWithTherapist')}
        />
        {toHoursMinutes(slot)}
      </TimeSlotText>
    </TimeSlotContainer>
  )
}
