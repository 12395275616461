import { Space } from 'cdk'
import { isNeitherNullNorUndefined } from 'functions'
import { toCurrencyIntOrDecimal } from 'numbers'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto, SuggestionBox } from 'ui-deprecated'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEventView } from '~/domains/analytics/hooks/useTrackEventView'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useAvailableChangeTherapistsFree } from '../hooks/useAvailableChangeTherapistsFree'
import { getChangeTherapistModalRoutes } from '../utils/routes'

type Props = {
  therapyId: string
}

export const ChangeTherapistModalInfo = ({ therapyId }: Props) => {
  useTrackEventView('changeTherapist.modal.info')

  const { availableChangeTherapistsFree, loading, error } = useAvailableChangeTherapistsFree()
  const { loading: patientTherapiesLoading, ongoing } = usePatientTherapies()

  const therapy = useMemo(() => ongoing.find((therapy) => therapy.id === therapyId), [ongoing, therapyId])

  const history = useHistory()

  const handleOnClick = useCallback(() => {
    history.push(getChangeTherapistModalRoutes('/changeTherapistReasonAuto'))
  }, [history])

  if (loading || patientTherapiesLoading) {
    return <CenteredLoader />
  }

  if (!isNeitherNullNorUndefined(availableChangeTherapistsFree) || error || !therapy) {
    return (
      <SuggestionBox kind="alert">
        <Translation id="generic.error" />
      </SuggestionBox>
    )
  }

  return (
    <>
      <OverflowAuto>
        <Text fontWeight="600" kind="h1">
          <Translation id="changeTherapist.modal.info.title" />
        </Text>

        <Flex pt={8}>
          <Text kind="paragraph">
            <Translation id="changeTherapist.modal.info.body.1" />
            <Space />
            😊
          </Text>
        </Flex>

        <Flex pt={8}>
          <Text kind="paragraph">
            <Translation id="changeTherapist.modal.info.body.2" />
          </Text>
        </Flex>

        <Flex pb={4} pt={32}>
          <SuggestionBox kind="info">
            {availableChangeTherapistsFree > 0 ? (
              <TranslationMarkdown
                id="changeTherapist.modal.info.toast.free"
                kind="paragraph"
                values={{ therapySessionCost: toCurrencyIntOrDecimal(therapy.therapyPath.cost) }}
              />
            ) : (
              <TranslationMarkdown
                id="changeTherapist.modal.info.toast.payed"
                kind="paragraph"
                values={{ therapySessionCost: toCurrencyIntOrDecimal(therapy.therapyPath.cost) }}
              />
            )}
          </SuggestionBox>
        </Flex>
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="primary" onClick={handleOnClick}>
          <Translation id="actions.confirm" />
        </Button>
      </Flex>
    </>
  )
}
