import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { parsePath, parseQueryParams } from 'url-parser'
import { getSessionStorageQueryParams } from '~/utils/getSessionStorageQueryParams'

export const getDefaultSessionStorage = (path: string) => {
  try {
    const queryParams = parseQueryParams(path)

    const { hash, pathname } = parsePath(path)

    const defaultLastLandingValue = {
      lastLandingHash: hash ?? null,
      lastLandingPage: pathname,
      lastLandingReferrer: document?.referrer == null || document.referrer === '' ? null : document.referrer,
    }

    return {
      ...queryParams,
      ...defaultLastLandingValue,
    }
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'utils',
      },
    })

    return {}
  }
}

export const useSessionStorageParams = () => {
  const { asPath } = useRouter()

  return useMemo(
    () => (getSessionStorageQueryParams() ?? getDefaultSessionStorage(asPath)) as Record<string, unknown>,
    [asPath],
  )
}
