import { useMergeRefs } from '@floating-ui/react'
import { Flex, type FlexProps, PositionAbsolute, PositionRelative } from 'cdk'
import {
  BORDER_RADIUS_4XS,
  COLOR_ERROR,
  COLOR_NEUTRAL_20,
  SPACING_0,
  SPACING_2XS,
  SPACING_3XS,
  SPACING_LG,
  SPACING_SM,
  SPACING_XS,
  TIME_300,
} from 'design-tokens'
import { type CSSProperties, forwardRef, type InputHTMLAttributes, type ReactNode, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Hint } from './Hint'
import { Label } from './Label'
import { defaultTextInputSize, TextInput } from './TextInput'

type SizeName = 'sm' | 'md' | 'lg'

type LabelPositionStyle = {
  top: FlexProps['$top']
}

export const labelPositionsStyles: Record<SizeName, LabelPositionStyle> = {
  sm: { top: `calc(${SPACING_2XS} * -1)` },
  md: { top: SPACING_3XS },
  lg: { top: SPACING_XS },
}

type InputSizesStyle = {
  paddingBlock: CSSProperties['paddingBlock']
}

export const inputSizesStyles: Record<SizeName, InputSizesStyle> = {
  sm: { paddingBlock: `${SPACING_SM} ${SPACING_3XS}` },
  md: { paddingBlock: `20px ${SPACING_3XS}` },
  lg: { paddingBlock: `${SPACING_LG} ${SPACING_XS}` },
}

const LabelContainer = styled(PositionRelative)`
  transition: background-color ${TIME_300} ease-out;
`

type TextFieldContainer = {
  $sizeName: SizeName
}

const TextFieldContainer = styled(PositionRelative).withConfig({
  displayName: 'TextFieldContainer',
})<TextFieldContainer>(
  ({ $sizeName }) => css`
    &:has(${Label}) input {
      padding-block: ${() => inputSizesStyles[$sizeName].paddingBlock};
    }

    &:has(:user-invalid),
    &:has(:invalid) {
      ${Label} {
        color: ${COLOR_ERROR};
      }
    }

    &:has(:autofill) {
      ${LabelContainer} {
        background-color: ${COLOR_NEUTRAL_20};
      }
    }
  `,
)

export type TextFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'children' | 'value'> & {
  error?: ReactNode
  hint?: ReactNode
  label?: ReactNode
  sizeName?: SizeName
  suffix?: ReactNode
  // Exclude "readonly string[]" since TextField doesn't handle type="file"
  value?: string | number
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, error, hint, sizeName = defaultTextInputSize, suffix, ...inputProps }, forwardedRef) => {
    const identifier = inputProps.name
    const labelId = label ? `${identifier}-label` : undefined
    const description = error ? error : hint
    const descriptionId = error ? `${identifier}-error` : hint ? `${identifier}-hint` : undefined
    const inputRef = useRef<HTMLInputElement | null>(null)
    const mergedRef = useMergeRefs([inputRef, forwardedRef])

    useEffect(() => {
      if (inputRef !== null && 'current' in inputRef) {
        inputRef.current?.setCustomValidity(typeof error === 'string' ? error : '')
      }
    }, [error, inputRef])

    return (
      <TextFieldContainer $gap={SPACING_3XS} $sizeName={sizeName}>
        <PositionRelative>
          <TextInput
            ref={mergedRef}
            $sizeName={sizeName}
            aria-describedby={descriptionId}
            aria-labelledby={labelId}
            id={identifier}
            {...inputProps}
          />
          {suffix && (
            <PositionAbsolute
              $align="center"
              $bottom={SPACING_0}
              $justify="center"
              $px={SPACING_SM}
              $py={SPACING_XS}
              $right={SPACING_0}
              $top={SPACING_0}
            >
              {suffix}
            </PositionAbsolute>
          )}
        </PositionRelative>
        {label && (
          <PositionAbsolute
            $align="flex-start"
            $left={SPACING_XS}
            $right={SPACING_XS}
            $top={labelPositionsStyles[sizeName].top}
            as={Label}
            colorName={inputProps.disabled || inputProps.readOnly ? 'neutral-80' : 'primary'}
            forwardedAs="label"
            htmlFor={identifier}
            id={labelId}
          >
            <LabelContainer
              $backgroundColorName={inputProps.disabled ? 'neutral-20' : 'lighter'}
              $borderRadius={BORDER_RADIUS_4XS}
              $px={SPACING_3XS}
            >
              {label}
            </LabelContainer>
          </PositionAbsolute>
        )}
        {typeof descriptionId === 'string' && (
          <Flex $px={SPACING_SM}>
            <Hint colorName={error ? 'error' : undefined} id={descriptionId}>
              {description}
            </Hint>
          </Flex>
        )}
      </TextFieldContainer>
    )
  },
)
