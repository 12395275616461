import { useEffect } from 'react'
import { useTrackEventImpression } from '~/domains/analytics/hooks/useTrackEventImpression'
import { useReservationFlow } from '../hooks/useReservationFlow'

export const ReservationImpressionTracker = () => {
  const { loading, selectedTherapist, suggestedTherapistsProfiles } = useReservationFlow()
  const trackEvent = useTrackEventImpression('reservation.therapists')

  useEffect(() => {
    if (loading || !selectedTherapist || !suggestedTherapistsProfiles.length) {
      return
    }

    trackEvent({
      selectedTherapistId: selectedTherapist?.id,
      suggestedTherapistsProfilesIds: suggestedTherapistsProfiles.map(({ id }) => id),
    })
  }, [loading, selectedTherapist, suggestedTherapistsProfiles, trackEvent])

  return null
}
