import { PositionAbsolute } from 'cdk'
import { BORDER_RADIUS_CIRCLE, BORDER_WIDTH_2, COLOR_LIGHTER, COLOR_RED_60 } from 'design-tokens'
import styled from 'styled-components'

export const UnreadDot = styled(PositionAbsolute)`
  top: calc(${BORDER_WIDTH_2} * -1);
  right: calc(${BORDER_WIDTH_2} * -1);
  width: 12px;
  height: 12px;
  border: ${BORDER_WIDTH_2} solid ${COLOR_LIGHTER};
  border-radius: ${BORDER_RADIUS_CIRCLE};
  background-color: ${COLOR_RED_60};
`
