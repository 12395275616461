import { useTranslation } from '~/i18n/hooks/useTranslation'

export const useFormTranslations = () => ({
  errors: {
    availability: {
      required: useTranslation('forms.errors.email'),
    },
    email: useTranslation('forms.errors.email'),
    emailConfirmation: useTranslation('forms.errors.emailConfirmation'),
    maxLength: useTranslation('forms.errors.maxLength'),
    minLength: useTranslation('forms.errors.minLength'),
    dayOfMonth: useTranslation('forms.errors.dayOfMonth'),
    month: useTranslation('forms.errors.month'),
    year: useTranslation('forms.errors.year'),
    olderThan18: useTranslation('forms.errors.olderThan18'),
    youngerThan14: useTranslation('forms.errors.youngerThan14'),
    mobilePhone: useTranslation('forms.errors.mobilePhone'),
    paidAtBeforeEmittedAt: useTranslation('forms.errors.paidAtBeforeEmittedAt'),
    password: useTranslation('forms.errors.password'),
    patientAvailability: useTranslation('forms.errors.patientIsNotAvailable'),
    patternDigits: useTranslation('forms.errors.pattern.digits'),
    postalCode: useTranslation('forms.errors.postalCode'),
    province: useTranslation('forms.errors.province'),
    required: useTranslation('forms.errors.required'),
    taxCode: useTranslation('forms.errors.taxCode'),
    timeZone: useTranslation('forms.errors.timeZone'),
  },
  helperTexts: {
    email: useTranslation('forms.helperTexts.email'),
    emailConfirmation: useTranslation('forms.helperTexts.emailConfirmation'),
    emailSignup: useTranslation('forms.helperTexts.email.signup'),
    mobilePhone: useTranslation('forms.helperTexts.mobilePhone'),
    password: useTranslation('forms.helperTexts.password'),
    professionalEmail: useTranslation('forms.helperTexts.professionalEmail'),
    surname: useTranslation('forms.helperTexts.surname.signup'),
    textareaCounter: useTranslation('forms.helperTexts.textareaCounter'),
    timeZone: useTranslation('forms.helperTexts.timeZone'),
  },
  labels: {
    availabilityAvailable: useTranslation('forms.labels.availabilityAvailable'),
    availabilityUnavailable: useTranslation('forms.labels.availabilityUnavailable'),
    availability: {
      endAtHours: useTranslation('forms.labels.availabilityEndAtHours'),
      endAtMinutes: useTranslation('forms.labels.availabilityEndAtMinutes'),
      startAtHours: useTranslation('forms.labels.availabilityStartAtHours'),
      startAtMinutes: useTranslation('forms.labels.availabilityStartAtMinutes'),
    },
    collectionDate: useTranslation('forms.labels.saasDocumentCollectionDate'),
    city: useTranslation('forms.labels.city'),
    country: useTranslation('forms.labels.country'),
    creditCardExpirationDate: useTranslation('forms.labels.creditCardExpirationDate'),
    creditCardNumber: useTranslation('forms.labels.creditCardNumber'),
    creditCardSecurityCode: useTranslation('forms.labels.creditCardSecurityCode'),
    defaultPaymentMethod: useTranslation('forms.labels.defaultPaymentMethod'),
    email: useTranslation('forms.labels.email'),
    emailConfirmation: useTranslation('forms.labels.email'),
    firstName: useTranslation('forms.labels.firstName'),
    foreignRegion: useTranslation('forms.labels.foreignRegion'),
    invoiceEnabled: useTranslation('forms.labels.invoiceEnabled'),
    lastName: useTranslation('forms.labels.lastName'),
    professionalEmail: useTranslation('forms.labels.professionalEmail'),
    marketingEmails: useTranslation('forms.labels.marketingEmails'),
    name: useTranslation('forms.labels.name'),
    newPassword: useTranslation('forms.labels.newPassword'),
    oldPassword: useTranslation('forms.labels.oldPassword'),
    password: useTranslation('forms.labels.password'),
    phoneNumber: useTranslation('forms.labels.phoneNumber'),
    pincode: useTranslation('forms.labels.pincode'),
    postalCode: useTranslation('forms.labels.postalCode'),
    profilation: useTranslation('forms.labels.profilation'),
    province: useTranslation('forms.labels.province'),
    recurringTherapyDisabled: useTranslation('forms.label.recurringTherapyDisabled'),
    recurringTherapyEnabled: useTranslation('forms.label.recurringTherapyEnabled'),
    registeredElectronicMail: useTranslation('forms.labels.registeredElectronicMail'),
    street: useTranslation('forms.labels.street'),
    stsEnabled: useTranslation('forms.labels.stsEnabled'),
    stsEnabledInfo: useTranslation('forms.labels.stsEnabledInfo'),
    taxCode: useTranslation('forms.labels.taxCode'),
    therapySessionsDuration: useTranslation('forms.labels.therapySessionsDuration'),
    timeZone: useTranslation('forms.labels.timeZone'),
  },
  placeholders: {
    askForReviewMessage: useTranslation('forms.placeholders.askForReviewMessage'),
    availability: {
      endAtHours: useTranslation('forms.placeholders.availabilityEndAtHours'),
      endAtMinutes: useTranslation('forms.placeholders.availabilityEndAtMinutes'),
      startAtHours: useTranslation('forms.placeholders.availabilityStartAtHours'),
      startAtMinutes: useTranslation('forms.placeholders.availabilityStartAtMinutes'),
    },
    cantFindSlot: useTranslation('forms.placeholders.cantFindSlot'),
    churnReason: useTranslation('forms.placeholders.churnReason'),
    city: useTranslation('forms.placeholders.city'),
    clinicalBond: useTranslation('clinicalBond.info.placeholder'),
    conventionCodeName: useTranslation('forms.placeholders.conventionCodeName'),
    country: useTranslation('forms.placeholders.country'),
    creditCardExpirationDate: useTranslation('forms.placeholders.creditCardExpirationDate'),
    creditCardNumber: useTranslation('forms.placeholders.creditCardNumber'),
    creditCardSecurityCode: useTranslation('forms.placeholders.creditCardSecurityCode'),
    deleteBatchRecurrency: useTranslation('forms.placeholders.deleteBatchRecurrency'),
    deleteForTherapistEmergencyMessage: useTranslation('forms.placeholders.deleteForTherapistEmergencyMessage'),
    deleteTherapySession: useTranslation('forms.placeholders.deleteTherapySession'),
    editTherapySession: useTranslation('forms.placeholders.editTherapySession'),
    email: useTranslation('forms.placeholders.email'),
    emailFormFlow: useTranslation('forms.placeholders.emailFormFlow'),
    firstName: useTranslation('forms.placeholders.firstName'),
    foreignRegion: useTranslation('forms.placeholders.foreignRegion'),
    lastName: useTranslation('forms.placeholders.lastName'),
    month: useTranslation('forms.placeholders.month'),
    name: useTranslation('forms.placeholders.name'),
    partnerEmail: useTranslation('forms.placeholders.partnerEmail'),
    partnerEmailConfirmation: useTranslation('forms.placeholders.partnerEmailConfirmation'),
    partnerFirstName: useTranslation('forms.placeholders.partnerFirstName'),
    partnerLastName: useTranslation('forms.placeholders.partnerLastName'),
    password: useTranslation('forms.placeholders.password'),
    patientsGroupFilter: useTranslation('forms.placeholders.patientsGroupFilter'),
    phoneNumberExample: useTranslation('forms.placeholders.phoneNumber'),
    phoneNumberLongText: useTranslation('reservation.sendVerification.placeholder'),
    phoneVerificationCode: useTranslation('forms.placeholders.phoneVerificationCode'),
    pincode: useTranslation('forms.placeholders.pincode'),
    postalCode: useTranslation('forms.placeholders.postalCode'),
    productReviewBody: useTranslation('forms.placeholders.productReviewBody'),
    province: useTranslation('forms.placeholders.province'),
    registeredElectronicMail: useTranslation('forms.placeholders.registeredElectronicMail'),
    rescheduleTherapistSessionMessage: useTranslation('forms.placeholders.rescheduleTherapistSessionMessage'),
    searchQuery: useTranslation('forms.placeholders.searchQuery'),
    street: useTranslation('forms.placeholders.street'),
    taxArrangement: useTranslation('forms.placeholders.taxArrangement'),
    taxCode: useTranslation('forms.placeholders.taxCode'),
    technicalFeedback: useTranslation('technicalFeedback.bad.placeholder'),
    therapistIds: useTranslation('forms.placeholders.therapistIds'),
    timeZone: useTranslation('forms.placeholders.timeZone'),
    writeHere: useTranslation('forms.placeholders.writeHere'),
    year: useTranslation('forms.placeholders.year'),
  },
})
