import { useCallback, useEffect, useRef, useState } from 'react'

export const useTimer = (duration: number) => {
  const [seconds, setSeconds] = useState(0)
  const timer = useRef<NodeJS.Timeout | undefined>()

  const startTimer = useCallback(() => {
    setSeconds(duration)
    if (timer.current != null) {
      clearInterval(timer.current)
    }

    timer.current = setInterval(() => {
      setSeconds((prev) => prev - 1)
    }, 1000)
  }, [duration])

  useEffect(() => {
    if (timer.current != null && seconds === 0) {
      clearInterval(timer.current)
    }
  }, [seconds])

  useEffect(() => () => clearInterval(timer.current), [])

  return { seconds, startTimer }
}
