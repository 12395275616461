import { gql, useQuery, type WatchQueryFetchPolicy } from '@apollo/client'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import {
  type TherapySessionsTherapistAgendaQuery,
  type TherapySessionsTherapistAgendaQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query therapySessionsTherapistAgenda($id: String!) {
    user(id: $id) {
      id
      therapySessionsTherapistAgenda {
        bonus
        churn
        endAt
        free
        id
        notes
        parentTherapySessionId
        patient {
          fullName
          firstName
          email
          phoneNumber
          id
        }
        provider
        recurrency
        repeatEveryWeek
        startAt
        status
        therapist {
          fullName
          id
        }
        therapyPathType
        typeCost
        url
      }
    }
  }
`

export const useTherapySessionsTherapistAgenda = (fetchPolicy: WatchQueryFetchPolicy = 'cache-first') => {
  const { id } = useCurrentUser()

  const { data, error, loading, refetch, startPolling, stopPolling, updateQuery } = useQuery<
    TherapySessionsTherapistAgendaQuery,
    TherapySessionsTherapistAgendaQueryVariables
  >(QUERY, {
    variables: {
      id,
    },
    fetchPolicy,
  })

  const items = data?.user?.therapySessionsTherapistAgenda || []

  return {
    error: !!error,
    events: items.map(({ startAt }) => [startAt]),
    items,
    loading,
    refetch,
    startPolling,
    stopPolling,
    updateQuery,
  }
}
