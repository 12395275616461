import { Flex } from 'cdk'
import { SPACING_3XS, SPACING_MD } from 'design-tokens'
import { Avatar, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'

export const TherapistOverviewCardDetailHeader = ({
  profileImage,
  fullName,
  titleId,
}: {
  profileImage: string
  fullName: string
  titleId: TranslationId
}) => (
  <Flex
    $align="center"
    $direction="column"
    $gap={SPACING_MD}
    $grow={1}
    $mdDirection="row"
    $zIndex={1}
    className="therapist-overview-card-detail-header-container"
  >
    <Avatar image={profileImage} name={fullName} size="2xl" />
    <Flex
      $align="center"
      $gap={SPACING_3XS}
      $mdAlign="flex-start"
      className="therapist-overview-card-detail-header-infos"
    >
      <Text kind="h2">{fullName}</Text>
      <Text kind="paragraph" style={{ lineHeight: '24px' }}>
        <Translation id={titleId} />
      </Text>
    </Flex>
  </Flex>
)
