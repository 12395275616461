import { uid } from 'uid'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'

export const upsertAnonymousFingerprint = () => {
  const fingerprint = getLocalStorage('anonymous-fingerprint')

  if (fingerprint != null) {
    return fingerprint
  }

  const anonynoumsFingerprint = uid()

  setLocalStorage('anonymous-fingerprint', anonynoumsFingerprint)

  return anonynoumsFingerprint
}
