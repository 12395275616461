import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { isBeforeCancellationPolicyLimit } from '~/utils/dates/isBeforeCancellationPolicyLimit'
import { isTherapySessionSuccessfullyPaid } from '~/utils/dates/isTherapySessionSuccessfullyPaid'
import { getDeleteForTherapistEmergencyRoute } from '../utils/getDeleteForTherapistEmergencyRoute'

type Props = Pick<TherapistAgendaTherapySession, 'free' | 'patient' | 'startAt' | 'status' | 'typeCost'>

export const StepWarning = ({ free, patient, startAt, status, typeCost }: Props) => {
  const history = useHistory()

  const goNext = useCallback(() => {
    if (isTherapySessionSuccessfullyPaid({ status, typeCost }) && isBeforeCancellationPolicyLimit(startAt)) {
      history.push(getDeleteForTherapistEmergencyRoute('/payment-warning'))

      return
    }

    history.push(getDeleteForTherapistEmergencyRoute('/reason'))
  }, [history, startAt, status, typeCost])

  return (
    <>
      <OverflowAuto>
        <Text fontWeight="600" kind="h3">
          <Translation id="therapySession.deleteForTherapistEmergency.step.warning.title" />
        </Text>

        <TranslationMarkdown
          colorName="black"
          id={
            (!isTherapySessionSuccessfullyPaid({ status, typeCost }) && isBeforeCancellationPolicyLimit(startAt)) ||
            free
              ? 'therapySession.deleteForTherapistEmergency.step.warning.2.body'
              : 'therapySession.deleteForTherapistEmergency.step.warning.1.body'
          }
          kind="paragraph"
          pt={16}
          values={{
            firstName: patient.firstName,
          }}
        />
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="danger" onClick={goNext} type="button">
          <Translation id="therapySession.deleteForTherapistEmergency.step.warning.confirm" />
        </Button>
      </Flex>
    </>
  )
}
