import { gql, useLazyQuery } from '@apollo/client'
import { type JournalingLogCanShareQuery, type JournalingLogCanShareQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query journalingLogCanShare {
    journalingLogCanShare {
      status
      therapistId
    }
  }
`

export const useJournalingLogCanShare = () => {
  const [journalingLogCanShare, { error, loading, refetch }] = useLazyQuery<
    JournalingLogCanShareQuery,
    JournalingLogCanShareQueryVariables
  >(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    error: error != null,
    loading,
    journalingLogCanShare,
    refetch,
  }
}
