import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'ui'
import { Translation } from '~/components/Translation'
import { useTherapySession } from '~/hooks/useTherapySession'
import { getRoute } from '~/utils/getRoute'

export const ManageButtonVariant2 = () => {
  const { id } = useTherapySession()

  const history = useHistory()

  const handleOnClick = useCallback(() => {
    history.push(getRoute(`/therapy-session/${id}/edit`))
  }, [history, id])

  return (
    <Button as="span" kind="tertiary" onClick={handleOnClick}>
      <Translation id="manageTherapySession.variant1.manage" />
    </Button>
  )
}
