import { Flex, OverflowAuto } from 'cdk'
import { memo } from 'react'
import { useReviewCanShare } from '../hooks/useReviewCanShare'
import { MenuFooter } from './common/MenuFooter'
import { MenuGroup } from './common/MenuGroup'
import { MenuGroupTitle } from './common/MenuGroupTitle'
import { MenuGroupVoices } from './common/MenuGroupVoices'
import { MenuHeader } from './items/MenuHeader'
import { MenuItemAccount } from './items/MenuItemAccount'
import { MenuItemConvention } from './items/MenuItemConvention'
import { MenuItemEmergency } from './items/MenuItemEmergency'
import { MenuItemFAQ } from './items/MenuItemFAQ'
import { MenuItemInvite } from './items/MenuItemInvite'
import { MenuItemInvoices } from './items/MenuItemInvoices'
import { MenuItemNotifications } from './items/MenuItemNotifications'
import { MenuItemPayments } from './items/MenuItemPayments'
import { MenuItemPrivacyPolicy } from './items/MenuItemPrivacyPolicy'
import { MenuItemReview } from './items/MenuItemReview'
import { MenuItemTermsAndConditions } from './items/MenuItemTermsAndConditions'
import { MenuItemWriteUs } from './items/MenuItemWriteUs'

export const PatientTabMenu = memo(({ handleAutoClose }: { handleAutoClose?: () => void }) => {
  const { data: status } = useReviewCanShare()

  return (
    <>
      <MenuHeader />

      <OverflowAuto $justify="space-between" $p={16}>
        <Flex $gap={32} $grow={1}>
          <MenuGroup>
            <MenuGroupTitle id="menu.menu" />

            <MenuGroupVoices>
              <Flex onClick={handleAutoClose}>
                <MenuItemAccount />
              </Flex>

              <Flex onClick={handleAutoClose}>
                <MenuItemPayments />
              </Flex>

              <Flex onClick={handleAutoClose}>
                <MenuItemInvoices />
              </Flex>

              <Flex onClick={handleAutoClose}>
                <MenuItemNotifications />
              </Flex>

              <Flex onClick={handleAutoClose}>
                <MenuItemInvite />
              </Flex>

              <Flex onClick={handleAutoClose}>
                <MenuItemConvention />
              </Flex>
            </MenuGroupVoices>
          </MenuGroup>

          {status === 'CAN_SHARE' && (
            <MenuGroup>
              <MenuGroupTitle id="menu.youOkay" />
              <MenuGroupVoices>
                <MenuItemReview />
              </MenuGroupVoices>
            </MenuGroup>
          )}

          <MenuGroup>
            <MenuGroupTitle id="menu.help" />

            <MenuGroupVoices>
              <Flex onClick={handleAutoClose}>
                <MenuItemFAQ />
              </Flex>

              <Flex onClick={handleAutoClose}>
                <MenuItemWriteUs />
              </Flex>

              <MenuItemEmergency />
            </MenuGroupVoices>
          </MenuGroup>

          <MenuGroup>
            <MenuGroupTitle id="menu.legal" />

            <MenuGroupVoices>
              <MenuItemTermsAndConditions />
              <MenuItemPrivacyPolicy />
            </MenuGroupVoices>
          </MenuGroup>
        </Flex>

        <MenuFooter />
      </OverflowAuto>
    </>
  )
})

PatientTabMenu.displayName = 'PatientTabMenu'
