import { useMemo } from 'react'
import { getFormFlowRoute } from '~/domains/formFlow/utils/getFormFlowRoute'
import { usePartnershipByUtmSource } from '~/hooks/usePartnership'
import { type FormFlowsQuery } from '~/types/graphql'
import { usePrefilledForWhomType } from '../hooks/usePrefilledForWhomType'

type Props = {
  formFlows: FormFlowsQuery['formFlows']
  initialEntries?: string[]
}

export const useMemoryRouterInitialOptions = ({ formFlows, initialEntries }: Props) => {
  const partnership = usePartnershipByUtmSource()
  const prefilledForWhomType = usePrefilledForWhomType()
  const introduction = formFlows.find(({ type }) => type === 'INTRODUCTION')

  return useMemo(() => {
    const prefilledWelcomeOrIntroduction =
      prefilledForWhomType === null && partnership === null
        ? [getFormFlowRoute(`/${introduction?.id}/${introduction?.steps[0].id}`)]
        : [getFormFlowRoute('/welcome')]

    return {
      initialEntries: initialEntries ?? prefilledWelcomeOrIntroduction,
      initialIndex: 0,
    }
  }, [initialEntries, introduction?.id, introduction?.steps, partnership, prefilledForWhomType])
}
