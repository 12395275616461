import { Flex, PositionRelative } from 'cdk'
import {
  BORDER_RADIUS_SM,
  BORDER_WIDTH_1,
  COLOR_NEUTRAL_110,
  SPACING_2XS,
  SPACING_3XS,
  SPACING_4XS,
  SPACING_MD,
  SPACING_SM,
  SPACING_XS,
} from 'design-tokens'
import { Icon, type IconName } from 'icons'
import styled from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { type TranslationId } from '~/i18n/types'
import { type TherapistProfessionalExperienceType } from '~/types/graphql'

type Props = {
  experiences: {
    id: string
    name: string
    type: TherapistProfessionalExperienceType | null
  }[]
}

const getFormationType = (type: string | null): TranslationId => {
  switch (type) {
    case 'DEGREE':
      return 'therapist.overviewCard.formation.value.degree'
    case 'SPECIALIZATION':
      return 'therapist.overviewCard.formation.value.specialization'
    case 'REGISTER_REGISTRATION':
      return 'therapist.overviewCard.formation.value.registerRegistration'
    default:
      return 'therapist.overviewCard.formation.value.noType'
  }
}

const getFormationTypeIcon = (type: string | null): IconName => {
  switch (type) {
    case 'DEGREE':
      return 'graduation-cap'
    case 'REGISTER_REGISTRATION':
      return 'srns-contact-phonebook'
    case 'SPECIALIZATION':
      return 'srns-shipping-box'
    default:
      return 'srns-generic'
  }
}

const Timeline = styled(Flex)`
  position: absolute;
  top: 32px;
  bottom: 0;
  left: 11px;
  width: ${SPACING_4XS};
  background-image: radial-gradient(circle, ${COLOR_NEUTRAL_110} ${BORDER_WIDTH_1}, transparent ${BORDER_WIDTH_1});
  background-position: left top ${SPACING_3XS};
  background-size: ${SPACING_4XS} ${SPACING_2XS};
  background-repeat: repeat-y;
`

export const TherapistOverviewCardDetailContentFormations = ({ experiences }: Props) => {
  if (experiences === null || experiences.length === 0) {
    return null
  }

  return (
    <Flex $backgroundColorName="neutral-20" $borderRadius={BORDER_RADIUS_SM} $gap={SPACING_SM} $p={SPACING_MD}>
      <Text kind="h3">
        <Translation id="therapist.overviewCard.formation.title" />
      </Text>
      <Flex>
        {experiences.map(({ id, name, type }, index) => {
          const formationType = getFormationType(type)
          const formationIcon = getFormationTypeIcon(type)

          return (
            <PositionRelative
              key={id}
              $align="flex-start"
              $direction="row"
              $gap={SPACING_XS}
              $grow={1}
              $pb={SPACING_SM}
              $pt={SPACING_3XS}
              $shrink={1}
            >
              {experiences.length - 1 !== index && <Timeline />}
              <Flex>
                <Icon name={formationIcon} size={24} />
              </Flex>
              <Flex $grow={1} $shrink={1}>
                <TranslationMarkdown id={formationType} values={{ name }} />
              </Flex>
            </PositionRelative>
          )
        })}
      </Flex>
    </Flex>
  )
}
