import { Flex, Pressable } from 'cdk'
import { SPACING_4XS, SPACING_SM } from 'design-tokens'
import { Icon } from 'icons'
import { matchPath, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { MenuLink } from '../common/MenuLink'

const to = '/settings/invite'

export const MenuItemInvite = () => {
  const { pathname } = useLocation()
  const active = !!matchPath(pathname, { path: to })
  const trackEventClick = useTrackEventClick()

  const handleClick = () => {
    trackEventClick('settings-invite.from-menu')
  }

  return (
    <Pressable onClick={handleClick}>
      <MenuLink to={to}>
        <Flex $pr={SPACING_SM}>
          <Icon colorName="neutral-60" name="srns-member-get-member" size={24} />
        </Flex>

        <Flex $grow={1} $pt={SPACING_4XS} $shrink={1}>
          <Text colorName={active ? 'primary' : undefined} kind="paragraph">
            <Translation id="menu.invite" />
          </Text>
        </Flex>
      </MenuLink>
    </Pressable>
  )
}
