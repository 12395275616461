import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useTherapySession } from '~/hooks/useTherapySession'
import { isBeforeCancellationPolicyLimit } from '~/utils/dates/isBeforeCancellationPolicyLimit'
import { ManageButtonVariant1 } from './ManageButtonVariant1'
import { ManageButtonVariant2 } from './ManageButtonVariant2'
import { ManageButtonVariantAfterCancellationPolicy } from './ManageButtonVariantAfterCancellationPolicy'

export const ManageButtonVariant = () => {
  const { startAt } = useTherapySession()

  const IS_BEFORE_CANCELLATION_POLICY_LIMIT = startAt && isBeforeCancellationPolicyLimit(startAt)

  const { isVariant1, isVariant2 } = useFeatureFlagsByUserId()

  if (!IS_BEFORE_CANCELLATION_POLICY_LIMIT) {
    return <ManageButtonVariantAfterCancellationPolicy />
  }

  if (isVariant1('ff_manage_therapy_session')) {
    return <ManageButtonVariant1 />
  }

  if (isVariant2('ff_manage_therapy_session')) {
    return <ManageButtonVariant2 />
  }
}
