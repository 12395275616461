import { Button } from 'ui'
import { Translation } from '~/components/Translation'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { type ReservationFormValues } from '~/domains/reservation/types'

export const ReservationSubmitButton = () => {
  const form = useReactHookFormContext<ReservationFormValues>()

  return (
    <Button data-test-id="book-slot-cta" disabled={!form.formState.isValid} kind="primary" type="submit">
      <Translation id="actions.book" />
    </Button>
  )
}
