import { Flex, OverflowAuto } from 'cdk'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, useModalContext } from 'ui'
import { Translation } from '~/components/Translation'
import { TabItemContent } from '~/domains/formFlow/components/InfoAndPrices/TabItemContent'
import { therapyPathTypeToFormFlowPrefilledConceptId } from '~/domains/therapies/constants'
import { getRoute } from '~/utils/getRoute'
import { type TherapyPathKey } from './StartTherapyPathCard'

// TODO: can we avoid this mapping and use just therapyPathType?
const therapyPathToKind = (therapyPathType: TherapyPathKey) => {
  switch (therapyPathType) {
    case 'PATH_COACHING':
      return 'coaching'
    case 'COUPLES_PSYCHOTHERAPY':
      return 'couples'
    case 'MYSELF_PSYCHOTHERAPY':
      return 'myself'
    case 'MYSELF_PSYCHIATRY':
      return 'psychiatry'
    case 'PATH_SEXOLOGY':
      return 'sexology'
    case 'PATH_NUTRITION_DCA':
      return 'dca'
    case 'PATH_NUTRITION_WEIGHT_LOSS':
      return 'nutritionist'
  }
}

type Props = {
  therapyPathType: TherapyPathKey
}

export const StartTherapyPathModalContent = ({ therapyPathType }: Props) => {
  const history = useHistory()
  const { setIsOpen } = useModalContext()

  const handleGoBack = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleGoNext = useCallback(() => {
    const prefilledConceptId = therapyPathTypeToFormFlowPrefilledConceptId[therapyPathType]
    history.push(getRoute(`/start/p/${prefilledConceptId}`))
  }, [history, therapyPathType])

  return (
    <>
      <OverflowAuto data-test-id={`start-therapy-path-${therapyPathType.toLowerCase()}-modal`}>
        <TabItemContent kind={therapyPathToKind(therapyPathType)} />
      </OverflowAuto>

      <Flex $align="center" $direction="row" $justify="flex-end" $pt={16}>
        <Button kind="tertiary" onClick={handleGoBack} type="button">
          <Translation id="actions.goBack" />
        </Button>
        <Flex $px={8} />
        <Flex data-test-id="start-therapy-path-button">
          <Button kind="primary" onClick={handleGoNext} type="button">
            <Translation id="actions.proceed" />
          </Button>
        </Flex>
      </Flex>
    </>
  )
}
