import { Flex } from 'cdk'
import { OPACITY_0, OPACITY_100, SPACING_SM } from 'design-tokens'
import { GestoLogoIcon } from 'illustrations'
import NextLink from 'next/link'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useIsSaasTherapist } from '~/domains/auth/hooks/useIsSaasTherapist'
import { getEnv } from '~/utils/getEnv'
import { getRoute } from '~/utils/getRoute'
import { type NavigationProps } from '../../types'
import { FlexWithTransition } from '../common/FlexWithTransition'
import { NavLinkContainer } from '../common/NavLinkContainer'

type Props = {
  onLinkClick?: () => void
} & NavigationProps

export const LinkGesto = ({ extended, onLinkClick, isMobile = false }: Props) => {
  const gestoAppUrl = getEnv('GESTO_APP_URL')
  const isSaasTherapist = useIsSaasTherapist()
  const history = useHistory()

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!isSaasTherapist) {
        event.preventDefault()
        onLinkClick?.()
        history.push(getRoute('/gesto/onboarding/internal'))
      } else {
        onLinkClick?.()
      }
    },
    [isSaasTherapist, onLinkClick, history],
  )

  return (
    <Flex as={NextLink} href={`${gestoAppUrl}/dashboard`} onClick={handleClick} target="_blank">
      <NavLinkContainer $direction="row" $gap={SPACING_SM} $minHeight={40}>
        <Flex>
          <GestoLogoIcon />
        </Flex>
        {!isMobile && (
          <FlexWithTransition $opacity={extended ? OPACITY_100 : OPACITY_0}>
            <Text colorName="neutral-110" kind="paragraph">
              <Translation id="menu.gesto" />
            </Text>
          </FlexWithTransition>
        )}
      </NavLinkContainer>
    </Flex>
  )
}
