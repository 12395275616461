import { noop } from 'functions'
import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { useToasts } from '~/hooks/useToasts'
import { useJournalingActivities } from '~/routes/journaling/hooks/useJournalingActivities'
import {
  type JournalingActivitiesQuery,
  type JournalingLogDeleteInput,
  type JournalingLogShareInput,
  type JournalingLogsQuery,
  type JournalingLogTodayQuery,
  type JournalingLogUpdateInput,
  type JournalingLogUpsertInput,
  type JournalingMoodsQuery,
  type JournalingRewardType,
  type JournalingSettingQuery,
  type JournalingSettingUpdateGoalInDaysInput,
  type JournalingSettingUpsertActivitiesInput,
} from '~/types/graphql'
import { useJournalingLogDelete } from './useJournalingLogDelete'
import { useJournalingLogs } from './useJournalingLogs'
import { useJournalingLogShare } from './useJournalingLogShare'
import { useJournalingLogToday } from './useJournalingLogToday'
import { useJournalingLogUpdate } from './useJournalingLogUpdate'
import { useJournalingLogUpsert } from './useJournalingLogUpsert'
import { useJournalingMoods } from './useJournalingMoods'
import { useJournalingReward } from './useJournalingReward'
import { useJournalingSetting } from './useJournalingSetting'
import { useJournalingSettingUpdateGoalInDays } from './useJournalingSettingUpdateGoalInDays'
import { useJournalingSettingUpsertActivities } from './useJournalingSettingUpsertActivities'

type Props = {
  children: ReactNode
}

type OnSuccess<R> = {
  onSuccess?: (result: R) => void
}

type Callback<T, R = void> = T & OnSuccess<R>

type ResourceState = {
  error: boolean
  loading: boolean
}

type Context = {
  journalingActivities: [JournalingActivitiesQuery['journalingActivities'], ResourceState]
  journalingLogDelete: [(param: Callback<JournalingLogDeleteInput>) => Promise<void>, ResourceState]
  journalingLogs: [JournalingLogsQuery['journalingLogs'], ResourceState]
  journalingLogToday: [JournalingLogTodayQuery['journalingLogToday'], ResourceState]
  journalingMoods: [JournalingMoodsQuery['journalingMoods'], ResourceState]
  journalingSetting: [JournalingSettingQuery['journalingSetting'], ResourceState]
  updateJournalingLog: [(param: Callback<JournalingLogUpdateInput>) => Promise<void>, ResourceState]
  updateSettingGoalInDays: [(param: Callback<JournalingSettingUpdateGoalInDaysInput>) => Promise<void>, ResourceState]
  upsertJournalingLog: [(param: Callback<JournalingLogUpsertInput, string>) => Promise<void>, ResourceState]
  upsertSettingActivities: [(param: Callback<JournalingSettingUpsertActivitiesInput>) => Promise<void>, ResourceState]
  journalingLogShare: [(param: Callback<JournalingLogShareInput>) => Promise<void>, ResourceState]
  journalingLogReward: [(param: Callback<string>) => Promise<JournalingRewardType>, ResourceState]
}

const JournalingContext = createContext<Context | null>(null)

export const JournalingProvider = ({ children }: Props) => {
  const { addToast } = useToasts()

  const {
    error: journalingActivitiesError,
    journalingActivities,
    loading: journalingActivitiesLoading,
  } = useJournalingActivities()

  const {
    error: journalingLogTodayError,
    journalingLogToday,
    loading: journalingLogTodayLoading,
    refetch: journalingLogTodayRefetch,
  } = useJournalingLogToday()

  const {
    error: journalingLogsError,
    journalingLogs,
    loading: journalingLogsLoading,
    refetch: journalingLogsRefetch,
  } = useJournalingLogs()

  const { error: journalingMoodsError, journalingMoods, loading: journalingMoodsLoading } = useJournalingMoods()

  const {
    error: journalingSettingError,
    journalingSetting,
    loading: journalingSettingLoading,
    refetch: journalingSettingRefetch,
  } = useJournalingSetting()

  const [journalingSettingUpsertActivities, journalingSettingUpsertActivitiesState] =
    useJournalingSettingUpsertActivities()

  const [journalingSettingUpdateGoalInDays, journalingSettingUpdateGoalInDaysState] =
    useJournalingSettingUpdateGoalInDays()

  const [journalingLogUpsert, journalingLogUpsertState] = useJournalingLogUpsert()

  const [journalingLogUpdate, journalingLogUpdateState] = useJournalingLogUpdate()

  const [journalingLogDelete, journalingLogDeleteState] = useJournalingLogDelete()

  const [journalingLogShare, journalingLogShareState] = useJournalingLogShare()

  useEffect(() => {
    if (
      journalingActivitiesError ||
      journalingLogsError ||
      journalingMoodsError ||
      journalingSettingError ||
      journalingLogTodayError
    ) {
      addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })

      return
    }
  }, [
    addToast,
    journalingActivitiesError,
    journalingLogsError,
    journalingMoodsError,
    journalingSettingError,
    journalingLogTodayError,
  ])

  const upsertJournalingLog = useCallback(
    async ({ date, score, onSuccess = noop }: Callback<JournalingLogUpsertInput, string>) => {
      try {
        const { data } = await journalingLogUpsert({
          variables: {
            input: {
              date,
              score,
            },
          },
        })

        if (data?.journalingLogUpsert?.id == null) {
          addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })

          return
        }

        await journalingLogTodayRefetch()

        await journalingLogsRefetch()

        await journalingSettingRefetch()

        onSuccess(data?.journalingLogUpsert?.id)
      } catch (error) {
        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      }
    },
    [addToast, journalingLogTodayRefetch, journalingLogUpsert, journalingLogsRefetch, journalingSettingRefetch],
  )

  const updateJournalingLog = useCallback(
    async ({ id, onSuccess = noop, ...param }: Callback<JournalingLogUpdateInput>) => {
      try {
        const { data } = await journalingLogUpdate({
          variables: {
            input: {
              id,
              ...param,
            },
          },
        })

        if (data == null || !data.journalingLogUpdate.ok) {
          addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })

          return
        }

        await journalingLogTodayRefetch()

        await journalingLogsRefetch()

        await journalingSettingRefetch()

        onSuccess()
      } catch (error) {
        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      }
    },
    [addToast, journalingLogTodayRefetch, journalingLogUpdate, journalingLogsRefetch, journalingSettingRefetch],
  )

  const upsertSettingActivities = useCallback(
    async ({ journalingActivityIds, onSuccess = noop }: Callback<JournalingSettingUpsertActivitiesInput>) => {
      try {
        const { data } = await journalingSettingUpsertActivities({
          variables: {
            input: {
              journalingActivityIds,
            },
          },
        })

        if (data == null || !data.journalingSettingUpsertActivities.ok) {
          addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })

          return
        }

        await journalingSettingRefetch()

        onSuccess()
      } catch (error) {
        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      }
    },
    [addToast, journalingSettingRefetch, journalingSettingUpsertActivities],
  )

  const updateSettingGoalInDays = useCallback(
    async ({ goalInDays, onSuccess = noop }: Callback<JournalingSettingUpdateGoalInDaysInput>) => {
      try {
        const { data } = await journalingSettingUpdateGoalInDays({
          variables: {
            input: {
              goalInDays,
            },
          },
        })

        if (data == null || !data.journalingSettingUpdateGoalInDays.ok) {
          addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })

          return
        }

        await journalingSettingRefetch()

        onSuccess()
      } catch (error) {
        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      }
    },
    [addToast, journalingSettingRefetch, journalingSettingUpdateGoalInDays],
  )

  const journalingLogDeleteCallback = useCallback(
    async ({ id, onSuccess = noop }: Callback<JournalingLogDeleteInput>) => {
      try {
        const { data } = await journalingLogDelete({
          variables: {
            input: {
              id,
            },
          },
        })

        if (data == null || !data.journalingLogDelete.ok) {
          addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })

          return
        }

        await journalingLogTodayRefetch()

        await journalingLogsRefetch()

        await journalingSettingRefetch()

        onSuccess()
      } catch (error) {
        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      }
    },
    [addToast, journalingLogDelete, journalingLogTodayRefetch, journalingLogsRefetch, journalingSettingRefetch],
  )

  const journalingLogShareCallback = useCallback(
    async ({ onSuccess = noop, ...input }: Callback<JournalingLogShareInput>) => {
      try {
        const { data } = await journalingLogShare({
          variables: {
            input,
          },
        })

        if (data == null || !data.journalingLogShare.ok) {
          addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })

          return
        }

        await journalingLogTodayRefetch()

        await journalingLogsRefetch()

        onSuccess()
      } catch (error) {
        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      }
    },
    [addToast, journalingLogShare, journalingLogTodayRefetch, journalingLogsRefetch],
  )

  const [getJournalingReward, getJournalingRewardState] = useJournalingReward()
  const journalingRewardCallback = useCallback(
    async (logId: string) => {
      try {
        const { data } = await getJournalingReward({
          variables: {
            input: {
              id: logId,
            },
          },
        })

        if (data?.journalingReward?.rewardType == null) {
          addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })

          return 'NONE'
        }

        return data.journalingReward.rewardType
      } catch (error) {
        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })

        return 'NONE'
      }
    },
    [addToast, getJournalingReward],
  )

  const value = useMemo(
    (): Context => ({
      journalingActivities: [
        journalingActivities,
        { error: journalingActivitiesError, loading: journalingActivitiesLoading },
      ],
      journalingLogDelete: [
        journalingLogDeleteCallback,
        { error: journalingLogDeleteState.error != null, loading: journalingLogDeleteState.loading },
      ],
      journalingLogToday: [journalingLogToday, { error: journalingLogTodayError, loading: journalingLogTodayLoading }],
      journalingLogs: [journalingLogs, { error: journalingLogsError, loading: journalingLogsLoading }],
      journalingMoods: [journalingMoods, { error: journalingMoodsError, loading: journalingMoodsLoading }],
      journalingSetting: [journalingSetting, { error: journalingSettingError, loading: journalingSettingLoading }],
      upsertSettingActivities: [
        upsertSettingActivities,
        {
          loading: journalingSettingUpsertActivitiesState.loading,
          error: journalingSettingUpsertActivitiesState.error != null,
        },
      ],
      updateSettingGoalInDays: [
        updateSettingGoalInDays,
        {
          loading: journalingSettingUpdateGoalInDaysState.loading,
          error: journalingSettingUpdateGoalInDaysState.error != null,
        },
      ],
      updateJournalingLog: [
        updateJournalingLog,
        {
          loading: journalingLogUpdateState.loading,
          error: journalingLogUpdateState.error != null,
        },
      ],
      upsertJournalingLog: [
        upsertJournalingLog,
        {
          loading: journalingLogUpsertState.loading,
          error: journalingLogUpsertState.error != null,
        },
      ],
      journalingLogShare: [
        journalingLogShareCallback,
        { error: journalingLogShareState.error != null, loading: journalingLogShareState.loading },
      ],
      journalingLogReward: [
        journalingRewardCallback,
        {
          error: getJournalingRewardState.error != null,
          loading: getJournalingRewardState.loading,
        },
      ],
    }),
    [
      journalingActivities,
      journalingActivitiesError,
      journalingActivitiesLoading,
      journalingLogDeleteCallback,
      journalingLogDeleteState.error,
      journalingLogDeleteState.loading,
      journalingLogToday,
      journalingLogTodayError,
      journalingLogTodayLoading,
      journalingLogs,
      journalingLogsError,
      journalingLogsLoading,
      journalingMoods,
      journalingMoodsError,
      journalingMoodsLoading,
      journalingSetting,
      journalingSettingError,
      journalingSettingLoading,
      upsertSettingActivities,
      journalingSettingUpsertActivitiesState.loading,
      journalingSettingUpsertActivitiesState.error,
      updateSettingGoalInDays,
      journalingSettingUpdateGoalInDaysState.loading,
      journalingSettingUpdateGoalInDaysState.error,
      updateJournalingLog,
      journalingLogUpdateState.loading,
      journalingLogUpdateState.error,
      upsertJournalingLog,
      journalingLogUpsertState.loading,
      journalingLogUpsertState.error,
      journalingLogShareCallback,
      journalingLogShareState.error,
      journalingLogShareState.loading,
      journalingRewardCallback,
      getJournalingRewardState.error,
      getJournalingRewardState.loading,
    ],
  )

  return (
    <JournalingContext.Provider value={value}>
      <PatientTherapiesProvider>{children}</PatientTherapiesProvider>
    </JournalingContext.Provider>
  )
}

export const useJournalingContext = () => {
  const context = useContext(JournalingContext)

  if (context == null) {
    throw new Error('The `useJournalingContext` should be wrapped with `JournalingProvider`.')
  }

  return context
}
