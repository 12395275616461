import { type ColorName, cssvarColor, FONT_SIZE_14, LETTER_SPACING_036, LINE_HEIGHT_16, TIME_300 } from 'design-tokens'
import { type PropsWithChildren } from 'react'
import styled from 'styled-components'

export type LabelProps = PropsWithChildren<{
  colorName?: ColorName
  /** Use `id` for `aria-labelledby` together with Form Fields @see https://www.w3.org/WAI/tutorials/forms/instructions/ */
  id?: string
}>

/**
 * Should be used with Form Fields with `id` for `aria-labelledby`
 */
export const Label = styled.label.withConfig({ displayName: 'Label' })<LabelProps>`
  color: ${({ colorName = 'primary' }) => cssvarColor(colorName)};
  font-size: ${FONT_SIZE_14};
  line-height: ${LINE_HEIGHT_16};
  letter-spacing: calc(${LETTER_SPACING_036} * -1);
  transition: color ${TIME_300} ease-out;
`
