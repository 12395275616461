import { type ComponentProps, createContext, type ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { type Toasts as UiToasts } from 'ui-deprecated'
import { uid } from 'uid'
import { type TranslationId, type TranslationValues } from '~/i18n/types'
import { useTranslate } from '../i18n/hooks/useTranslation'

type Toast = ComponentProps<typeof UiToasts>['items'][number]

type Payload = Omit<Toast, 'id' | 'label' | 'timeout' | 'removeToast'> & {
  translationId: TranslationId
  translationValues?: TranslationValues
  timeout?: Toast['timeout'] | null
} & Partial<Pick<Toast, 'id'>>

type Context = {
  addToast: (payload: Payload) => void
  items: Toast[]
}

type Props = {
  children: ReactNode
}

const defaultTimeout = 5000

const ToastsContext = createContext<Context | null>(null)

export const ToastsProvider = ({ children }: Props) => {
  const [items, setItems] = useState<Toast[]>([])
  const translate = useTranslate()

  const removeToast = useCallback((id: string) => {
    setItems((toasts) => toasts.filter((toast) => toast.id !== id))
  }, [])

  const addToast = useCallback(
    ({ id = uid(), timeout, translationId, translationValues, ...toast }: Payload) => {
      setItems((toasts) => [
        ...toasts.filter((toast) => toast.id !== id),
        {
          ...toast,
          id,
          removeToast,
          label: translate(translationId, translationValues),
          timeout: timeout === null ? undefined : (timeout ?? defaultTimeout),
        },
      ])
    },
    [removeToast, translate],
  )

  const value = useMemo(() => ({ addToast, items }), [addToast, items])

  return <ToastsContext.Provider value={value}>{children}</ToastsContext.Provider>
}

export const useToasts = () => {
  const context = useContext(ToastsContext)

  if (context == null) {
    throw new Error('The `useToasts` should be wrapped with `ToastsProvider`.')
  }

  return context
}
