import { type ColorName, cssvarColor } from 'design-tokens'
import { Svg } from './components/Svg'

type LogoColorName = Extract<ColorName, 'black' | 'darker' | 'lighter' | 'primary' | 'white'>

type DoubleColorProps = {
  primaryColorName: Extract<LogoColorName, 'primary'>
  secondaryColorName: Extract<LogoColorName, 'darker'>
}

type SingleColorProps = {
  primaryColorName: LogoColorName
  secondaryColorName?: never
}

type LogoProps = DoubleColorProps | SingleColorProps

type Props = {
  height: number
} & LogoProps

export const SerenisLogoHorizontal = ({ height, primaryColorName, secondaryColorName }: Props) => {
  const primaryFill = cssvarColor(primaryColorName)
  const secondaryFill = cssvarColor(secondaryColorName ?? primaryColorName)

  return (
    <Svg className="srns-serenis-light" height={height} viewBox="0 0 160 32">
      <path
        d="M16.0972 18.5018L11.5706 17.3306C10.8905 17.1359 7.4983 16.3852 6.49082 14.5762C5.24627 12.1154 8.17278 9.60649 10.1962 8.45224C13.5348 6.55014 17.3361 5.10239 20.7452 4.06103C22.1732 3.6236 23.8044 3.18335 25.4863 2.78543L25.5428 2.77132C26.316 2.5907 26.3894 1.5183 25.6472 1.23327C25.0348 0.99903 24.366 0.804304 23.6407 0.643443C21.5862 0.18626 19.0661 0 15.9928 0C3.20025 0 0 3.20028 0 15.9986C0 20.7849 0.330185 24.29 1.67632 26.6634C2.25203 27.6793 3.25387 27.5495 3.54737 27.4677C9.2988 25.8534 16.2101 23.7792 18.4875 21.671C18.7077 21.465 20.3247 19.5629 16.0972 18.5018Z"
        fill={primaryFill}
      />
      <path
        d="M30.2131 5.13072C30.0494 4.82593 29.7051 4.66225 29.3636 4.72716C25.8162 5.40164 12.3887 8.51726 12.558 12.0675C12.6201 13.3516 14.5984 13.9668 16.2268 14.4042C18.3377 15.0194 20.1184 15.5302 22.1955 16.2245C23.7533 16.7466 25.7203 17.7315 25.7852 19.8876C25.8953 23.4406 19.3 26.8074 17.6463 27.6879C16.5767 28.258 14.1553 29.4461 12.2194 30.3802C11.48 30.7358 11.6916 31.8477 12.5101 31.8985C13.5853 31.9662 14.7452 31.9973 16.001 31.9973C28.7992 31.9973 31.9995 28.797 31.9995 15.9987C31.9995 11.0769 31.5253 7.57468 30.2131 5.1279V5.13072Z"
        fill={primaryFill}
      />
      <path
        d="M40 19.4812L44.1432 18.6769C44.7585 21.0531 45.9891 22.6617 49.0655 22.6617C51.4031 22.6617 52.5532 21.7755 52.5934 20.2855C52.5934 18.8772 51.6503 18.1915 47.7516 17.5057C42.5417 16.5405 40.6958 14.7287 40.6958 11.7062C40.6958 8.28299 43.2404 6.06763 48.2864 6.06763C53.3323 6.06763 55.713 8.3225 56.5324 11.9066L52.3059 12.8323C51.6906 10.377 50.5836 9.28767 48.2864 9.28767C46.1932 9.28767 45.0862 10.092 45.0862 11.503C45.0862 12.7927 45.8252 13.7184 49.7642 14.3618C55.138 15.327 56.9436 17.2602 56.9436 20.1613C56.9436 23.7059 54.4422 26.0003 48.985 26.0003C42.8321 26.0003 40.6555 23.1415 40 19.4783V19.4812Z"
        fill={secondaryFill}
      />
      <path
        d="M58.8297 16.2582C58.8297 9.85477 62.6451 6.07031 68.6773 6.07031C74.7095 6.07031 77.4984 9.69391 77.4984 14.8894V14.9289C77.4984 15.6937 77.5387 16.419 77.415 17.4265H63.2604C63.6715 20.607 65.4772 22.179 68.7175 22.179C71.9579 22.179 72.9843 20.6889 73.5162 18.7557L77.3719 19.8422C76.3886 23.4263 73.7606 25.9634 68.6744 25.9634C62.7658 25.9634 58.8268 22.5402 58.8268 16.2582H58.8297ZM73.3955 14.2037C73.1482 11.2235 71.5496 9.73342 68.5536 9.73342C65.5577 9.73342 63.7952 11.1417 63.3035 14.2037H73.3955Z"
        fill={secondaryFill}
      />
      <path
        d="M84.8819 12.7558H84.9653C85.8681 7.84244 87.5099 6.55556 90.4627 6.55556H91.5697V11.4265H89.6835C86.2361 11.4265 84.8848 12.5554 84.8848 15.8968V25.4807H80.4541V6.55273H84.8848V12.7529L84.8819 12.7558Z"
        fill={secondaryFill}
      />
      <path
        d="M92.6366 16.2582C92.6366 9.85477 96.452 6.07031 102.484 6.07031C108.516 6.07031 111.305 9.69391 111.305 14.8894V14.9289C111.305 15.6937 111.346 16.419 111.222 17.4265H97.0673C97.4784 20.607 99.2841 22.179 102.524 22.179C105.765 22.179 106.791 20.6889 107.323 18.7557L111.179 19.8422C110.195 23.4263 107.568 25.9634 102.481 25.9634C96.5727 25.9634 92.6337 22.5402 92.6337 16.2582H92.6366ZM107.2 14.2037C106.952 11.2235 105.354 9.73342 102.358 9.73342C99.3617 9.73342 97.5992 11.1417 97.1075 14.2037H107.2Z"
        fill={secondaryFill}
      />
      <path
        d="M118.688 11.7484H118.729C119.591 7.72125 122.012 6.07031 125.293 6.07031C129.928 6.07031 131.693 9.05047 131.693 13.1567V25.478H127.262V14.2827C127.262 12.0278 126.236 9.9733 123.243 9.9733C120.411 9.9733 118.688 11.5847 118.688 14.2827V25.478H114.258V6.55289H118.688V11.7484Z"
        fill={secondaryFill}
      />
      <path
        d="M140.267 4.01594H135.469V0.795898H140.267V4.01876V4.01594ZM140.104 25.481H135.673V6.55302H140.104V25.4781V25.481Z"
        fill={secondaryFill}
      />
      <path
        d="M143.056 19.4809L147.2 18.6766C147.815 21.0528 149.045 22.6615 152.122 22.6615C154.459 22.6615 155.61 21.7753 155.65 20.2852C155.65 18.877 154.707 18.1912 150.808 17.5054C145.598 16.5403 143.752 14.7285 143.752 11.706C143.752 8.28275 146.297 6.06738 151.343 6.06738C156.389 6.06738 158.769 8.32226 159.589 11.9063L155.362 12.832C154.747 10.3768 153.64 9.28742 151.343 9.28742C149.25 9.28742 148.143 10.0917 148.143 11.5028C148.143 12.7925 148.882 13.7182 152.821 14.3616C158.194 15.3268 160 17.2599 160 20.1611C160 23.7056 157.499 26 152.041 26C145.888 26 143.712 23.1412 143.056 19.4781V19.4809Z"
        fill={secondaryFill}
      />
    </Svg>
  )
}
