import { Controller, type RegisterOptions, useFormContext } from 'react-hook-form'
import { TextField as TextFieldUI, type TextFieldProps } from 'ui'

type Props = TextFieldProps & {
  name: string
  rules?: RegisterOptions
}

export const TextField = ({ name, rules, ...textFieldProps }: Props) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <TextFieldUI error={fieldState.error?.message} {...field} {...textFieldProps} />
      )}
      rules={rules}
    />
  )
}
