import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { media } from 'cdk'
import 'design-tokens/css'
import { type AppProps } from 'next/app'
import NextHead from 'next/head'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import 'react-medium-image-zoom/dist/styles.css'
import 'stream-chat-react/dist/scss/v2/index.scss'
import styled, { createGlobalStyle, StyleSheetManager } from 'styled-components'
import { GlobalStyle as UiGlobalStyle, shouldForwardProp } from 'ui'
import { Flex } from 'ui-deprecated'
import { Apollo } from '~/clients/apollo'
import { AssertMinimumRequirements } from '~/domains/error/AssertMinimumRequirements'
import { GenericError } from '~/domains/error/GenericError'
import { getEnv } from '~/utils/getEnv'

Sentry.init({
  allowUrls: ['app.development.serenis.it', 'app.serenis.it'],
  dsn: getEnv('SENTRY_DSN'),
  environment: getEnv('SENTRY_ENVIRONMENT'),
  ignoreErrors: ['ApolloError', '<unlabeled event>', 'AxiosError', 'Failed to load Stripe.js'],
  maxBreadcrumbs: 50,
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  integrations: [Sentry.rewriteFramesIntegration()],
})

const Wrapper = styled(Flex).attrs({
  grow: 1,
})`
  height: 100%;
  max-height: fill-available;
`

const AppGlobalStyle = createGlobalStyle`
  /* stylelint-disable selector-id-pattern */
  html, body, #__next {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: fill-available;
    height: 100%;
    max-height: fill-available;
  }

  body, #__next {
    flex-grow: 1;
  }

  ${media.sm`
    .intercom-namespace.intercom-namespace {
      .intercom-app > [class^="intercom-with-namespace"] {
        bottom: 98px;
      }
    }
  `}
`

const App = ({ Component, pageProps }: AppProps) => {
  const [domLoaded, setDomLoaded] = useState(false)

  useEffect(() => {
    setDomLoaded(true)
  }, [])

  if (!domLoaded) {
    return null
  }

  return (
    <>
      <NextHead>
        <meta key="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" name="viewport" />
      </NextHead>

      <Script id="gtm" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://gtm.serenis.it/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${getEnv('GTM_ID')}');`}
      </Script>

      <StyleSheetManager enableVendorPrefixes shouldForwardProp={shouldForwardProp}>
        <>
          <UiGlobalStyle />

          <AppGlobalStyle />

          <Sentry.ErrorBoundary fallback={<GenericError />}>
            <AssertMinimumRequirements>
              <ApolloProvider client={Apollo}>
                <Wrapper>
                  <Component {...pageProps} />
                </Wrapper>
              </ApolloProvider>
            </AssertMinimumRequirements>
          </Sentry.ErrorBoundary>
        </>
      </StyleSheetManager>
    </>
  )
}

export default App
