import { Flex, OverflowAuto, Pressable } from 'cdk'
import { COLOR_PRIMARY } from 'design-tokens'
import { isNeitherNullNorUndefined } from 'functions'
import { Icon } from 'icons'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { Modal, type ModalId, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { JournalingLogShareModalBookedFirst } from '~/routes/journalingLog/components/JournalingLogShareModalBookedFirst'
import { JournalingLogShareModalBookedSecond } from '~/routes/journalingLog/components/JournalingLogShareModalBookedSecond'
import { JournalingLogShareModalNotBooked } from '~/routes/journalingLog/components/JournalingLogShareModalNotBooked'
import { JournalingLogShareModalNoTherapist } from '~/routes/journalingLog/components/JournalingLogShareModalNoTherapist'
import { JournalingLogShareWithMultipleTherapistsModal } from '~/routes/journalingLog/components/JournalingLogShareWithMultipleTherapistsModal'
import { useJournalingLogCanShare } from '~/routes/journalingLog/hooks/useJournalingLogCanShare'
import { type JournalingLogScore } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'
import { useJournalingLogById } from '../hooks/useJournalingLogById'

const ShareItem = styled(Flex)<{ disabled: boolean }>`
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: ${COLOR_PRIMARY};
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`

type Props = {
  journalingLogId: string
  score: JournalingLogScore
  sharedWithTherapistUserIds?: string[] | null
}

export const ShareJournalingLogModal = ({ journalingLogId, score, sharedWithTherapistUserIds }: Props) => {
  const { ongoing } = usePatientTherapies()

  const history = useHistory()

  const { close, open } = useModals()

  const { journalingLog } = useJournalingLogById(journalingLogId)

  const { journalingLogCanShare } = useJournalingLogCanShare()

  const modalId: ModalId = `shareJournalingLog:${journalingLogId}`

  const sharableTherapists = ongoing
    .filter((therapy) => !['MYSELF_PSYCHIATRY', 'PATH_COACHING'].includes(therapy.therapyPath.type))
    .map((therapy) => therapy.therapist)
    .filter(isNeitherNullNorUndefined)

  const onShareWithTherapistClick = useCallback(async () => {
    close(modalId)

    if (
      sharableTherapists.length === 1 &&
      journalingLog?.sharedWithTherapistUserIds != null &&
      sharableTherapists.length - journalingLog.sharedWithTherapistUserIds.length === 1
    ) {
      const unsharedTherapist = sharableTherapists.find(
        (therapist) =>
          therapist != null &&
          journalingLog.sharedWithTherapistUserIds != null &&
          !journalingLog.sharedWithTherapistUserIds.includes(therapist.id),
      )

      if (unsharedTherapist == null) {
        return
      }

      const { data } = await journalingLogCanShare()

      const status = data?.journalingLogCanShare.find((item) => item.therapistId === unsharedTherapist.id)?.status

      if (status == null) {
        return
      }

      if (status === 'CANNOT_SHARE') {
        return
      }

      if (status === 'NO_THERAPIST') {
        open('journalingLogShareNoTherapist')

        return
      }

      if (status === 'NOT_BOOKED') {
        open('journalingLogShareNotBooked')

        return
      }

      if (status === 'BOOKED_FIRST') {
        open('journalingLogShareBookedFirst')

        return
      }
    }

    if (sharableTherapists.length > 1) {
      open(`journalingLogShareWithMultipleTherapists:${journalingLogId}`)

      return
    }

    open(`journalingLogShareBookedSecond:${journalingLogId}`)
  }, [
    close,
    journalingLogId,
    modalId,
    open,
    journalingLog?.sharedWithTherapistUserIds,
    journalingLogCanShare,
    sharableTherapists,
  ])

  const onFindTherapistClick = useCallback(() => {
    close(modalId)

    history.push(getRoute('/booking'))
  }, [close, modalId, history])

  const onShareStickerClick = useCallback(() => {
    close(modalId)

    history.push(getRoute(`/share/score/${score.toLowerCase()}`), {
      previousRoute: history.location.pathname,
    })
  }, [close, modalId, history, score])

  const therapist = ongoing.at(0)?.therapist

  const isSharingDisabled =
    sharedWithTherapistUserIds != null && sharedWithTherapistUserIds.length >= sharableTherapists.length

  return (
    <>
      <Modal id={modalId}>
        <ModalCloseButton id={modalId} />

        <OverflowAuto $grow={1} $shrink={1}>
          <Text fontWeight="600" kind="h2">
            <Translation id="share.journaling.modal.title" />
          </Text>
          <Flex $pt={8}>
            <Text kind="paragraph">
              <Translation
                id="share.journaling.modal.body"
                values={{ therapistFullName: String(ongoing.at(0)?.therapist?.fullName) }}
              />
            </Text>
          </Flex>

          <Flex $pt={16}>
            {therapist != null ? (
              <Pressable disabled={isSharingDisabled} onClick={onShareWithTherapistClick}>
                <ShareItem
                  $borderColorName="primary-40"
                  $borderRadius={8}
                  $borderSize={1}
                  $direction="row"
                  $p={16}
                  disabled={isSharingDisabled}
                >
                  <Flex>
                    <Icon colorName="grey-80" name="user" size={24} />
                  </Flex>
                  <Flex $pl={8} $shrink={1}>
                    <Flex $direction="row" $justify="space-between">
                      <Text fontWeight="600" kind="paragraph" textAlign="left">
                        <Translation id="share.page" />
                      </Text>
                      {isSharingDisabled && (
                        <Text colorName="green-60" fontWeight="600" kind="paragraph" textAlign="right">
                          <Translation id="journaling.shared" />
                        </Text>
                      )}
                    </Flex>
                    <Flex $pt={8}>
                      <Text kind="paragraph" textAlign="left">
                        <Translation
                          id="share.page.description"
                          values={{ therapistFullName: String(therapist.fullName) }}
                        />
                      </Text>
                    </Flex>
                  </Flex>
                </ShareItem>
              </Pressable>
            ) : (
              <Pressable onClick={onFindTherapistClick}>
                <Flex $borderColorName="primary-40" $borderRadius={8} $borderSize={1} $direction="row" $p={16}>
                  <Flex>
                    <Icon colorName="grey-80" name="user" size={24} />
                  </Flex>
                  <Flex $pl={8} $shrink={1}>
                    <Text fontWeight="600" kind="paragraph" textAlign="left">
                      <Translation id="share.findTherapist" />
                    </Text>
                    <Flex $pt={8}>
                      <Text kind="paragraph" textAlign="left">
                        <Translation id="share.findTherapist.description" />
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Pressable>
            )}
          </Flex>

          <Flex $pt={16}>
            <Pressable onClick={onShareStickerClick}>
              <Flex $borderColorName="primary-40" $borderRadius={8} $borderSize={1} $direction="row" $p={16}>
                <Flex>
                  <Icon colorName="grey-80" name="star" size={24} />
                </Flex>
                <Flex $pl={8} $shrink={1}>
                  <Text fontWeight="600" kind="paragraph" textAlign="left">
                    <Translation id="share.sticker" />
                  </Text>
                  <Flex $pt={8}>
                    <Text kind="paragraph" textAlign="left">
                      <Translation id="share.sticker.description" />
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Pressable>
          </Flex>
        </OverflowAuto>
      </Modal>

      <JournalingLogShareModalBookedSecond journalingLogId={journalingLogId} />
      <JournalingLogShareWithMultipleTherapistsModal
        journalingLogId={journalingLogId}
        sharedWithTherapistUserIds={sharedWithTherapistUserIds}
      />
      <JournalingLogShareModalBookedFirst />
      <JournalingLogShareModalNotBooked />
      <JournalingLogShareModalNoTherapist />
    </>
  )
}
