import { Flex, PositionRelative, Pressable } from 'cdk'
import { BORDER_RADIUS_CIRCLE, COLOR_ERROR, SPACING_SM } from 'design-tokens'
import { Icon } from 'icons'
import styled from 'styled-components'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useModals } from '~/domains/modals'
import { useRootHistory } from '~/hooks/useRootHistory'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { NavLinkContainer } from '../common/NavLinkContainer'

const MgmBadge = styled.div`
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 12px;
  height: 12px;
  border-radius: ${BORDER_RADIUS_CIRCLE};
  background-color: ${COLOR_ERROR};
`

export const LinkMemberGetMember = () => {
  const { isVariant } = useFeatureFlagsByUserId()
  const { open } = useModals()
  const isMgmPageShown = getLocalStorage('settings-invite-card-shown')
  const rootHistory = useRootHistory()
  const trackEventClick = useTrackEventClick()

  const handleClick = () => {
    if (isMgmPageShown == null) {
      setLocalStorage('settings-invite-card-shown', new Date().toISOString())
    }

    trackEventClick('settings-invite.from-mgm-icon')
    rootHistory.push('/settings/invite')
  }

  if (isVariant('ff_new_mgm_page')) {
    return (
      <Pressable onClick={handleClick}>
        <PositionRelative>
          <Icon colorName="neutral-110" fillColorName="lighter" name="srns-member-get-member" size={24} />
          {!isMgmPageShown && <MgmBadge />}
        </PositionRelative>
      </Pressable>
    )
  }

  return (
    <Pressable onClick={() => open('referralShare')}>
      <NavLinkContainer $direction="row" $gap={SPACING_SM}>
        <Flex>
          <Icon colorName="neutral-110" fillColorName="lighter" name="srns-member-get-member" size={24} />
        </Flex>
      </NavLinkContainer>
    </Pressable>
  )
}
