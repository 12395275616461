import mixpanelBrowser from 'mixpanel-browser'
import { useEffect, useMemo } from 'react'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { getEnv } from '~/utils/getEnv'

export const useMixPanelIdentifyEffect = () => {
  const { email, firstName, fullName, id, lastName, phoneNumber } = useCurrentUserNullable()
  const group = useUserActingAsGroup()
  const { getAllFlags } = useFeatureFlagsByUserId()
  const mixpanel = useMemo(
    () =>
      mixpanelBrowser.init(
        getEnv('MIXPANEL_TOKEN'),
        {
          debug: process.env.NODE_ENV === 'development',
        },
        'Serenis',
      ),
    [],
  )

  useEffect(() => {
    if (!id) {
      return
    }

    try {
      const flags = getAllFlags()

      mixpanel.identify(id)

      mixpanel.people.set({
        ...(flags || {}),
        $email: email,
        $firstName: firstName,
        $lastName: lastName,
        $name: fullName,
        $phone: phoneNumber,
        group,
        userId: id,
      })
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [email, firstName, fullName, getAllFlags, id, group, lastName, phoneNumber, mixpanel])

  return null
}
