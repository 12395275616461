/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `0` */
export const LETTER_SPACING_0 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-0)` as const
export const LETTER_SPACING_0_VALUE = '0' as const

/** `0.20px` */
export const LETTER_SPACING_020 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-020)` as const
export const LETTER_SPACING_020_VALUE = '0.20px' as const

/** `0.24px` */
export const LETTER_SPACING_024 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-024)` as const
export const LETTER_SPACING_024_VALUE = '0.24px' as const

/** `0.36px` */
export const LETTER_SPACING_036 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-036)` as const
export const LETTER_SPACING_036_VALUE = '0.36px' as const

/** `0.40px` */
export const LETTER_SPACING_040 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-040)` as const
export const LETTER_SPACING_040_VALUE = '0.40px' as const

/** `0.48px` */
export const LETTER_SPACING_048 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-048)` as const
export const LETTER_SPACING_048_VALUE = '0.48px' as const

/** `0.56px` */
export const LETTER_SPACING_056 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-056)` as const
export const LETTER_SPACING_056_VALUE = '0.56px' as const

/** `0.64px` */
export const LETTER_SPACING_064 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-064)` as const
export const LETTER_SPACING_064_VALUE = '0.64px' as const

/** `0.72px` */
export const LETTER_SPACING_072 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-072)` as const
export const LETTER_SPACING_072_VALUE = '0.72px' as const

/** `0.80px` */
export const LETTER_SPACING_080 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-080)` as const
export const LETTER_SPACING_080_VALUE = '0.80px' as const

/** `0.96px` */
export const LETTER_SPACING_096 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-096)` as const
export const LETTER_SPACING_096_VALUE = '0.96px' as const

/** `calc(112px / 100)` */
export const LETTER_SPACING_112 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-112)` as const
export const LETTER_SPACING_112_VALUE = 'calc(112px / 100)' as const

/** `calc(128px / 100)` */
export const LETTER_SPACING_128 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-128)` as const
export const LETTER_SPACING_128_VALUE = 'calc(128px / 100)' as const

/** `calc(144px / 100)` */
export const LETTER_SPACING_144 = `var(--${DESIGN_TOKENS_PREFIX}-letter-spacing-144)` as const
export const LETTER_SPACING_144_VALUE = 'calc(144px / 100)' as const
