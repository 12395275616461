import { getLevenshteinDistance } from './getLevenshteinDistance'

const domains = [
  'gmail.com',
  'libero.it',
  'hotmail.it',
  'yahoo.it',
  'alice.it',
  'hotmail.com',
  'virgilio.it',
  'live.it',
  'tiscali.it',
  'icloud.com',
  'yahoo.com',
  'outlook.it',
  'tin.it',
  'email.it',
  'fastwebnet.it',
  'inwind.it',
  'me.com',
  'live.com',
  'outlook.com',
  'msn.com',
  'ymail.com',
  'teletu.it',
  'vodafone.it',
  'katamail.com',
  'iol.it',
  'outlook.de',
]

export const isEmailTypo = (value: unknown): boolean => {
  if (typeof value !== 'string') {
    return false
  }

  const lowerCaseValue = value.toLowerCase()

  if (!lowerCaseValue.includes('@')) {
    return false
  }

  const [, enteredDomain] = lowerCaseValue.split('@')

  if (enteredDomain === '') {
    return false
  }

  if (domains.includes(enteredDomain)) {
    return false
  }

  return (
    domains
      .map((domain) => domain.length - getLevenshteinDistance(domain, enteredDomain) - enteredDomain.length < -2)
      .filter((tooFar) => !tooFar).length > 0
  )
}
