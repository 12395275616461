import { Flex, Pressable } from 'cdk'
import { BORDER_WIDTH_0, BORDER_WIDTH_1, SPACING_0, SPACING_2XS, SPACING_MD } from 'design-tokens'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type UserTherapist } from '~/domains/therapist/types'

type Props = {
  alignCenter?: boolean
  compareTherapists: boolean
  toggleCompareTherapists?: VoidFunction | undefined
  showBorder?: boolean
  suggestedTherapistsProfiles: UserTherapist[]
}

export const CompareCalendars = ({
  alignCenter = false,
  compareTherapists,
  toggleCompareTherapists,
  showBorder = false,
  suggestedTherapistsProfiles,
}: Props) => {
  if (!suggestedTherapistsProfiles || suggestedTherapistsProfiles.length === 1) {
    return null
  }

  return (
    <Flex
      $align="center"
      $borderColorName="neutral-40"
      $borderSizeTop={showBorder ? BORDER_WIDTH_1 : BORDER_WIDTH_0}
      $direction="row"
      $gap={SPACING_2XS}
      $grow={1}
      $justify={alignCenter ? 'center' : 'flex-end'}
      $pt={showBorder ? SPACING_MD : SPACING_0}
    >
      {!compareTherapists && (
        <Text colorName="neutral-70" kind="paragraph">
          <Translation id="reservation.compare.question" />
        </Text>
      )}

      <Pressable onClick={toggleCompareTherapists}>
        <Text colorName="primary-50" kind="paragraph-strong">
          <Translation id={compareTherapists ? 'reservation.compare.stop' : 'reservation.compare.cta'} />
        </Text>
      </Pressable>
    </Flex>
  )
}
