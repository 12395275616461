import { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { DrawerProvider } from 'ui'
import { CenteredLoader } from '~/components/CenteredLoader'
import { FlowWrapper } from '~/components/Flow/FlowWrapper'
import { ReservationFlowProgressBar } from '~/domains/reservation/components/ReservationFlowProgressBar'
import { NoMatchedTherapists } from '~/domains/reservation/pages/NoMatchedTherapists'
import { getReservationRoutes } from '~/domains/reservation/utils/getReservationRoutes'
import { useRootHistory } from '~/hooks/useRootHistory'
import { ScrollRestoreProvider } from '~/hooks/useScrollRestore'
import { useSuggestedTherapists } from '~/hooks/useSuggestedTherapists'
import { TherapistFetchProvider } from '~/hooks/useTherapistFetch'
import { PreBookingTherapist } from './PreBookingTherapist'

/**
 * TODO: (amin-khayam)
 * Add ReservationImpressionTracker
 * https://linear.app/serenis/issue/ENG-805/add-reservationimpressiontracker-to-pre-booking-page
 */

export const PreBooking = () => {
  const { data, error, loading } = useSuggestedTherapists()
  const history = useHistory()
  const rootHistory = useRootHistory()

  useEffect(() => {
    if (error) {
      rootHistory.push('/start')
    }
  }, [error, rootHistory])

  useEffect(() => {
    if (data?.suggestedTherapists.therapistsIds.length === 0) {
      history.push('/no-matched-therapists')
    }
  }, [history, data])

  if (!data || loading) {
    return <CenteredLoader />
  }

  return (
    <FlowWrapper>
      <ReservationFlowProgressBar />
      <ScrollRestoreProvider background="white" pb={0}>
        <TherapistFetchProvider id={data?.suggestedTherapists.therapistsIds[0]}>
          <Route exact path={getReservationRoutes('/book-by-suggested-therapist')}>
            <DrawerProvider>
              <PreBookingTherapist />
            </DrawerProvider>
          </Route>
        </TherapistFetchProvider>

        <Route exact path={getReservationRoutes('/no-matched-therapists')}>
          <NoMatchedTherapists />
        </Route>
      </ScrollRestoreProvider>
    </FlowWrapper>
  )
}
