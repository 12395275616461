import { createContext, type ReactNode, useContext, useMemo } from 'react'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { type TherapySessionLiveExternalQuery, type TherapyTherapyPathType } from '~/types/graphql'

type SimpleUser = {
  id: string
  firstName: string
  fullName: string
}

type Context = {
  currentUser: SimpleUser
  endAt: Date
  id: string
  partner: SimpleUser | null
  patient: SimpleUser
  startAt: Date
  streamToken: string
  therapyPathType: TherapyTherapyPathType | null
  therapist: SimpleUser & {
    profileImage: {
      s: string
      m: string
      l: string
    } | null
  }
}

const TherapySessionLiveContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
  therapySessionLive: NonNullable<TherapySessionLiveExternalQuery['therapySessionLiveExternal']>
}

export const TherapySessionLiveProvider = ({ children, therapySessionLive }: Props) => {
  const { id: currentUserId } = useCurrentUserNullable()
  const currentUser = useMemo(
    () =>
      [therapySessionLive.patient, therapySessionLive.therapist].find((user) => user?.id === currentUserId) ??
      therapySessionLive.partner,
    [currentUserId, therapySessionLive],
  )

  if (!currentUser || !therapySessionLive || !therapySessionLive.patient || !therapySessionLive.therapist) {
    return <CenteredLoader />
  }

  return (
    <TherapySessionLiveContext.Provider
      value={{
        currentUser,
        endAt: therapySessionLive.endAt,
        id: therapySessionLive.id,
        partner: therapySessionLive.partner,
        patient: therapySessionLive.patient,
        startAt: therapySessionLive.startAt,
        streamToken: therapySessionLive.streamToken,
        therapist: therapySessionLive.therapist,
        therapyPathType: therapySessionLive.therapyPathType,
      }}
    >
      {children}
    </TherapySessionLiveContext.Provider>
  )
}

export const useTherapySessionLive = () => {
  const context = useContext(TherapySessionLiveContext)

  if (!context) {
    throw new Error('useTherapySessionLive must be used within a TherapySessionLiveProvider')
  }

  return context
}
