import { useEffect } from 'react'
import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { Modal, ModalContent } from 'ui'
import { TrackModalView } from '~/domains/analytics/components/TrackModalView'
import { Modal as OldModal, useModals } from '~/domains/modals'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useEmailVerification } from '../hooks/useEmailVerification'
import { getEmailVerificationRoute } from '../utils/getEmailVerificationRoute'
import {
  EmailVerificationModalDefaultRoute,
  EmailVerificationModalDefaultRouteVariant,
} from './EmailVerificationModalDefaultRoute'
import {
  EmailVerificationModalEditRoute,
  EmailVerificationModalEditRouteVariant,
} from './EmailVerificationModalEditRoute'

const initialEntries = [getEmailVerificationRoute('/')]

export const EmailVerificationModal = () => {
  const { open } = useModals()
  const { email, emailVerified } = useCurrentUser()
  const { requestVerification } = useEmailVerification()

  useEffect(() => {
    open('emailVerification')
    requestVerification(email)
  }, [email, open, requestVerification])

  if (emailVerified) {
    return null
  }

  return (
    <OldModal closeOnClickOutside={false} id="emailVerification">
      <MemoryRouter initialEntries={initialEntries}>
        <Switch>
          <Route path={getEmailVerificationRoute('/edit')}>
            <EmailVerificationModalEditRoute />
          </Route>

          <Route path={getEmailVerificationRoute('/')}>
            <EmailVerificationModalDefaultRoute />
          </Route>
        </Switch>
      </MemoryRouter>
    </OldModal>
  )
}

export const EmailVerificationModalVariant = () => {
  const { email, emailVerified } = useCurrentUser()
  const { requestVerification } = useEmailVerification()

  useEffect(() => {
    requestVerification(email)
  }, [email, requestVerification])

  if (emailVerified) {
    return null
  }

  return (
    <Modal isOpen>
      <ModalContent>
        <TrackModalView name="emailVerification" />

        <MemoryRouter initialEntries={initialEntries}>
          <Switch>
            <Route path={getEmailVerificationRoute('/edit')}>
              <EmailVerificationModalEditRouteVariant />
            </Route>

            <Route path={getEmailVerificationRoute('/')}>
              <EmailVerificationModalDefaultRouteVariant />
            </Route>
          </Switch>
        </MemoryRouter>
      </ModalContent>
    </Modal>
  )
}
