import { useCallback, useMemo } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { useIsSaasTherapist } from '~/domains/auth/hooks/useIsSaasTherapist'
import { useModals } from '~/domains/modals'
import { useNavigation } from '~/domains/navigation/hooks/useNavigation'
import { getRoute, type Route } from '~/utils/getRoute'

type LocationState = {
  previousRoute: Route
}

const cancelButtonPaths = [
  getRoute('/chat/:patientId/diagnosis/edit'),
  getRoute('/patients/:patientId/diagnosis/edit'),
  getRoute('/patients/:patientId/therapy-session/:therapyId/schedule'),
  getRoute('/share/score/:score'),
]

const leaveChangeTherapistButtonPaths = [
  getRoute('/change-therapist/:therapyId'),
  getRoute('/change-therapist/:therapyId/booking'),
  getRoute('/change-therapist/:therapyId/preferences'),
]

const showReferralShareButtonPaths = [getRoute('/')]

export const useAppBarContent = () => {
  const location = useLocation<LocationState | undefined>()
  const history = useHistory()
  const { isVisibleBackButton, title } = useNavigation()
  const { open } = useModals()
  const isSaasTherapist = useIsSaasTherapist()

  const goBackOrToFallbackPreviousRoute = useCallback(() => {
    if (history.length > 2) {
      history.goBack()

      return
    }

    history.replace(getRoute(location.state?.previousRoute ?? '/'))
  }, [history, location.state])

  const leaveChangeTherapistFlow = useCallback(() => {
    open('changeTherapistLeave')
  }, [open])

  return useMemo(
    () => ({
      goBackOrToFallbackPreviousRoute,
      isVisibleBackButton,
      leaveChangeTherapistFlow,
      shouldShowGestoButton: isSaasTherapist && !!matchPath(location.pathname, { exact: true, path: getRoute('/') }),
      shouldShowCancelButton: !!matchPath(location.pathname, { path: cancelButtonPaths }),
      shouldShowShareReferralButton: !!matchPath(location.pathname, {
        path: showReferralShareButtonPaths,
        exact: true,
      }),
      shouldShowLeaveChangeTherapistButton: !!matchPath(location.pathname, {
        path: leaveChangeTherapistButtonPaths,
        exact: true,
      }),
      title,
    }),
    [
      goBackOrToFallbackPreviousRoute,
      isSaasTherapist,
      isVisibleBackButton,
      leaveChangeTherapistFlow,
      location.pathname,
      title,
    ],
  )
}
