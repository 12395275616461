import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

const WISHES = ['booking', 'calendar', 'curriculum', 'comparing', 'default'] as const

export type Wish = (typeof WISHES)[number]

const isWish = (value: string): value is Wish => (WISHES as unknown as string).includes(value)

export const getSignupWish = (value: Wish): Wish => value

export const useSignupWish = (): Wish => {
  const { search } = useLocation()
  const { wish } = queryString.parse(search)

  if (!wish || Array.isArray(wish) || !isWish(wish)) {
    return 'default'
  }

  return wish
}
