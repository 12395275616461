import { Chip } from 'ui'
import { Translation } from '~/components/Translation'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { AppointmentDetails } from '../components/AppointmentDetails'
import { ConnectToLiveButton } from '../components/ConnectToLiveButton'
import { ContentSkeleton } from '../components/ContentSkeleton'
import { ManageButtonVariant } from '../components/ManageButtonVariant'
import { Price } from '../components/Price'

type Props = {
  isVeryNextTherapySession?: boolean
}

export const BookedWithoutPaymentMethodContent = ({ isVeryNextTherapySession }: Props) => {
  const { isControl } = useFeatureFlagsByUserId()

  return (
    <ContentSkeleton
      bottomChildren={
        <>
          <ConnectToLiveButton isVeryNextTherapySession={isVeryNextTherapySession} />
          {!isControl('ff_manage_therapy_session') && <ManageButtonVariant />}
        </>
      }
      topEndChildren={<Price />}
      topStartChildren={
        <Chip kind="warning">
          <Translation id="therapySession.patient.noPaymentMethod.statusValue" />
        </Chip>
      }
    >
      <AppointmentDetails canManage />
    </ContentSkeleton>
  )
}
