import { gql, useQuery } from '@apollo/client'
import { isUserTherapist } from '~/domains/therapist/types'
import { type FindTherapistsProfilesByIdsQuery, type QueryFindTherapistsProfilesByIdsArgs } from '~/types/graphql'

const QUERY = gql`
  query findTherapistsProfilesByIds($therapistsUserIds: [String!]!) {
    findTherapistsProfilesByIds(therapistsUserIds: $therapistsUserIds) {
      id
      firstName
      fullName
      therapist {
        about
        aboutCoach
        age
        availableHoursPerWeek
        id
        numberOfPatients
        registerRegistrationRegion
        registerRegistrationYearPsychologist
        sex
        userId
        professionTypes {
          professionType {
            id
            value
          }
        }
        profileImage {
          s
          m
          l
        }
        professionalExperiences {
          id
          name
          type
        }
        therapistCertifications {
          certification {
            description
            icon
            id
            name
            slug
          }
        }
        therapistProfessionalStyles {
          score
          type
        }
        therapistWorkplaces {
          workplace {
            id
            name
          }
        }
        therapySchool {
          name
          orientation
        }
        workRegion
      }
    }
  }
`

export const useSuggestedTherapistsProfiles = (ids: string[]) => {
  const { data, error, loading } = useQuery<FindTherapistsProfilesByIdsQuery, QueryFindTherapistsProfilesByIdsArgs>(
    QUERY,
    {
      variables: {
        therapistsUserIds: ids,
      },
      fetchPolicy: 'cache-first',
    },
  )

  const suggestedTherapistsProfiles = (data?.findTherapistsProfilesByIds ?? []).filter(isUserTherapist)

  return {
    suggestedTherapistsProfiles,
    error,
    loading,
  }
}
