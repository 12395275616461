import { Icon } from 'icons'
import { useCallback, useState } from 'react'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { Modal, type ModalId, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useJournalingContext } from '~/routes/journaling/hooks/useJournalingContext'

type Props = {
  journalingLogId: string
}
export const JournalingLogShareModalBookedSecond = ({ journalingLogId }: Props) => {
  const { therapy } = usePatientTherapies()

  const { close } = useModals()

  const modalId: ModalId = `journalingLogShareBookedSecond:${journalingLogId}`

  const {
    journalingLogShare: [journalingLogShare, { loading }],
  } = useJournalingContext()

  const [sharedSuccess, setSharedSuccess] = useState(false)
  const therapist = therapy?.therapist

  const onShareConfirmClick = useCallback(() => {
    if (therapist == null) {
      return
    }

    journalingLogShare({
      journalingLogId,
      therapistId: therapist.id,
      therapistIds: [therapist.id],
      onSuccess: () => {
        setSharedSuccess(true)
      },
    })
  }, [journalingLogId, journalingLogShare, therapist])

  const onCloseClick = useCallback(() => {
    close(modalId)
  }, [close, modalId])

  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  const toggleIsAccordionOpen = useCallback(() => {
    setIsAccordionOpen((isOpen) => !isOpen)
  }, [setIsAccordionOpen])

  const therapistFullName = String(therapist?.fullName)

  return (
    <Modal id={modalId}>
      <ModalCloseButton id={modalId} />

      {!sharedSuccess && (
        <>
          <OverflowAuto>
            <Text fontWeight="600" kind="h2">
              <Translation id="journaling.tipsOfLove.share.confirm.title" />
            </Text>
            <Flex pt={16}>
              <TranslationMarkdown
                id="journaling.tipsOfLove.share.confirm.body"
                kind="paragraph"
                values={{
                  therapistFullName,
                }}
              />
            </Flex>
            <Flex grow={1} mdPb={48} pt={24}>
              <Flex align="center" direction="row">
                <TranslationMarkdown
                  colorName="black"
                  id="journaling.tipsOfLove.success.share.howItWorks"
                  kind="paragraph"
                  textAlign="center"
                />
                <Flex px={8}>
                  <Pressable align="center" autoWidth direction="row" onClick={toggleIsAccordionOpen}>
                    {!isAccordionOpen && <Icon colorName="primary" name="nav-arrow-down" size={24} />}
                  </Pressable>
                  <Pressable align="center" autoWidth direction="row" onClick={toggleIsAccordionOpen}>
                    {isAccordionOpen && <Icon colorName="primary" name="nav-arrow-up" size={24} />}
                  </Pressable>
                </Flex>
              </Flex>
              {isAccordionOpen && (
                <TranslationMarkdown
                  id="journaling.tipsOfLove.success.share.what.body"
                  kind="paragraph"
                  pt={16}
                  values={{
                    therapistFullName,
                  }}
                />
              )}
            </Flex>
          </OverflowAuto>
          <Button kind="primary" loading={loading} onClick={onShareConfirmClick}>
            <Translation id="journaling.tipsOfLove.share.confirm.confirm" />
          </Button>
          <Flex pt={8}>
            <Button disabled={loading} kind="secondary" onClick={onCloseClick}>
              <Translation id="journaling.tipsOfLove.share.confirm.dismiss" />
            </Button>
          </Flex>
        </>
      )}

      {sharedSuccess && (
        <>
          <Flex grow={1} mdPb={48}>
            <Text fontWeight="600" kind="h2">
              <Translation id="journaling.tipsOfLove.share.success.title" />
            </Text>
            <TranslationMarkdown
              id="journaling.tipsOfLove.share.success.body"
              kind="paragraph"
              pt={16}
              values={{
                therapistFullName,
              }}
            />
          </Flex>
          <Button kind="primary" loading={loading} onClick={onCloseClick}>
            <Translation id="journaling.tipsOfLove.share.success.close" />
          </Button>
        </>
      )}
    </Modal>
  )
}
