import { useMergeRefs } from '@floating-ui/react'
import { Flex, type PropsWithAs, type StyledProps } from 'cdk'
import {
  BORDER_RADIUS_CIRCLE,
  type BorderRadiusName,
  COLOR_DARKER,
  COLOR_LIGHTER,
  COLOR_PRIMARY,
  COLOR_RED_60,
  type ColorName,
  cssvarBorderRadius,
  cssvarColor,
  cssvarFontSize,
  cssvarLineHeight,
  cssvarSpacing,
  FONT_FAMILY_DEGULAR_TEXT,
  FONT_WEIGHT_500,
  type FontSizeName,
  type LineHeightName,
  OPACITY_16,
  OPACITY_32,
  SPACING_3XS,
  type SpacingName,
  TIME_150,
  TIME_700_VALUE,
} from 'design-tokens'
import { Icon } from 'icons'
import {
  type ButtonHTMLAttributes,
  Children,
  forwardRef,
  isValidElement,
  type ReactNode,
  useCallback,
  useMemo,
} from 'react'
import styled, { css } from 'styled-components'
import useRipple from 'use-ripple-hook'
import { Loader } from './Loader'

type Kind = 'accent' | 'primary' | 'secondary' | 'tertiary' | 'error'

type KindState = 'standard' | 'hover' | 'active' | 'disabled' | 'loading'

type ButtonKindColors = {
  backgroundColor: ColorName
  boxShadowColor?: string
  color: ColorName
}

export const buttonKindsColors: Record<Kind, Record<KindState, ButtonKindColors>> = {
  accent: {
    standard: { backgroundColor: 'accent', color: 'black' },
    hover: { backgroundColor: 'primary-70', color: 'white' },
    active: {
      backgroundColor: 'primary-40',
      color: 'white',
      boxShadowColor: `rgb(from ${COLOR_PRIMARY} r g b / ${OPACITY_16})`,
    },
    disabled: { backgroundColor: 'primary-10', color: 'primary-20' },
    loading: { backgroundColor: 'primary-70', color: 'white' },
  },
  primary: {
    standard: { backgroundColor: 'primary', color: 'white' },
    hover: { backgroundColor: 'primary-70', color: 'white' },
    active: {
      backgroundColor: 'primary-40',
      color: 'white',
      boxShadowColor: `rgb(from ${COLOR_PRIMARY} r g b / ${OPACITY_16})`,
    },
    disabled: { backgroundColor: 'primary-10', color: 'primary-20' },
    loading: { backgroundColor: 'primary-70', color: 'white' },
  },
  secondary: {
    standard: { backgroundColor: 'neutral-80', color: 'lighter' },
    hover: { backgroundColor: 'neutral-100', color: 'lighter' },
    active: {
      backgroundColor: 'neutral-60',
      color: 'lighter',
      boxShadowColor: `rgb(from ${COLOR_DARKER} r g b / ${OPACITY_16})`,
    },
    disabled: { backgroundColor: 'neutral-20', color: 'neutral-30' },
    loading: { backgroundColor: 'neutral-100', color: 'lighter' },
  },
  tertiary: {
    standard: { backgroundColor: 'lighter', color: 'primary-50' },
    hover: { backgroundColor: 'neutral-40', color: 'primary-50' },
    active: { backgroundColor: 'neutral-20', color: 'primary-50' },
    disabled: { backgroundColor: 'primary-40', color: 'primary-30' },
    loading: { backgroundColor: 'neutral-40', color: 'primary-50' },
  },
  error: {
    standard: { backgroundColor: 'red-60', color: 'white' },
    hover: { backgroundColor: 'red-80', color: 'white' },
    active: {
      backgroundColor: 'red-40',
      color: 'white',
      boxShadowColor: `rgb(from ${COLOR_RED_60} r g b / ${OPACITY_16})`,
    },
    disabled: { backgroundColor: 'red-20', color: 'red-40' },
    loading: { backgroundColor: 'red-80', color: 'white' },
  },
}

export const buttonKindsGhostColors: typeof buttonKindsColors = {
  accent: {
    standard: { backgroundColor: 'transparent', color: 'primary-50' },
    hover: { backgroundColor: 'transparent', color: 'primary-70' },
    active: { backgroundColor: 'primary-10', color: 'primary-70' },
    disabled: { backgroundColor: 'transparent', color: 'primary-20' },
    loading: { backgroundColor: 'transparent', color: 'primary-50' },
  },
  primary: {
    standard: { backgroundColor: 'transparent', color: 'primary-50' },
    hover: { backgroundColor: 'transparent', color: 'primary-70' },
    active: { backgroundColor: 'primary-10', color: 'primary-70' },
    disabled: { backgroundColor: 'transparent', color: 'primary-20' },
    loading: { backgroundColor: 'transparent', color: 'primary-50' },
  },
  secondary: {
    standard: { backgroundColor: 'transparent', color: 'neutral-80' },
    hover: { backgroundColor: 'transparent', color: 'neutral-100' },
    active: { backgroundColor: 'neutral-20', color: 'neutral-100' },
    disabled: { backgroundColor: 'transparent', color: 'neutral-30' },
    loading: { backgroundColor: 'transparent', color: 'neutral-80' },
  },
  tertiary: {
    standard: { backgroundColor: 'transparent', color: 'lighter' },
    hover: { backgroundColor: 'transparent', color: 'neutral-40' },
    active: { backgroundColor: 'transparent', color: 'lighter' },
    disabled: { backgroundColor: 'transparent', color: 'primary-30' },
    loading: { backgroundColor: 'transparent', color: 'lighter' },
  },
  error: {
    standard: { backgroundColor: 'transparent', color: 'red-60' },
    hover: { backgroundColor: 'transparent', color: 'red-80' },
    active: { backgroundColor: 'red-20', color: 'red-80' },
    disabled: { backgroundColor: 'transparent', color: 'red-40' },
    loading: { backgroundColor: 'transparent', color: 'red-60' },
  },
}

type Size = 'xs' | 'sm' | 'md' | 'lg'

const DEFAULT_SIZE: Size = 'md'

type ButtonSizeStyles = {
  borderRadius: BorderRadiusName
  fontSize: FontSizeName
  gap: SpacingName
  lineHeight: LineHeightName
  px: SpacingName
  pxForIcon: SpacingName
  py: SpacingName
}

export const buttonSizesStyles: Record<Size, ButtonSizeStyles> = {
  xs: { borderRadius: '2xs', fontSize: '14', gap: '3xs', lineHeight: '16', px: 'xs', pxForIcon: '2xs', py: '3xs' },
  sm: { borderRadius: 'xs', fontSize: '16', gap: '2xs', lineHeight: '24', px: 'xs', pxForIcon: '2xs', py: '3xs' },
  md: { borderRadius: 'xs', fontSize: '18', gap: '2xs', lineHeight: '24', px: 'sm', pxForIcon: 'xs', py: 'xs' },
  lg: { borderRadius: 'sm', fontSize: '20', gap: 'xs', lineHeight: '32', px: 'md', pxForIcon: 'sm', py: 'xs' },
}

type BaseButtonProps = {
  isGhost?: boolean
  isLoading?: boolean
  isRound?: boolean
  kind: Kind
  size?: Size
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & PropsWithAs<BaseButtonProps>

const ButtonContainer = styled(Flex).withConfig({ displayName: 'Button' })<StyledProps<ButtonProps>>`
  cursor: pointer;
  ${({ $kind, $isGhost, $size = DEFAULT_SIZE }) => {
    const { active, standard, disabled, hover, loading } = ($isGhost ? buttonKindsGhostColors : buttonKindsColors)[
      $kind
    ]
    const { fontSize, lineHeight } = buttonSizesStyles[$size]

    return css`
      box-sizing: border-box;
      background-color: ${cssvarColor(standard.backgroundColor)};
      color: ${cssvarColor(standard.color)};
      font-weight: ${FONT_WEIGHT_500};
      font-size: ${cssvarFontSize(fontSize)};
      font-family: ${FONT_FAMILY_DEGULAR_TEXT};
      line-height: ${cssvarLineHeight(lineHeight)};
      text-align: center;
      transition:
        color ${TIME_150} ease,
        background-color ${TIME_150} ease,
        box-shadow ${TIME_150} ease,
        border-color ${TIME_150} ease;

      &:hover {
        background-color: ${cssvarColor(hover.backgroundColor)};
        color: ${cssvarColor(hover.color)};
      }

      &:disabled,
      &[disabled] {
        background-color: ${cssvarColor(disabled.backgroundColor)};
        color: ${cssvarColor(disabled.color)};
        cursor: not-allowed;
      }

      &:not(:disabled):active {
        background-color: ${cssvarColor(active.backgroundColor)};
        color: ${cssvarColor(active.color)};
        ${active.boxShadowColor &&
        css`
          box-shadow: 0 0 0 ${SPACING_3XS} ${active.boxShadowColor};
        `}
      }

      &[aria-busy='true'] {
        background-color: ${cssvarColor(loading.backgroundColor)};
        color: ${cssvarColor(loading.color)};
        cursor: wait;
      }
    `
  }}
`

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, disabled, isGhost, isLoading, isRound, kind, size = DEFAULT_SIZE, onMouseDown, ...props }: ButtonProps,
    ref,
  ) => {
    const [ripple, rippleEvent] = useRipple({
      duration: parseInt(TIME_700_VALUE),
      color: `rgb(from ${COLOR_LIGHTER} r g b / ${OPACITY_32})`,
    })
    const buttonRef = useMergeRefs([ref, ripple])

    const { childrenToShow, iconPosition } = useMemo<{
      childrenToShow: ReactNode
      iconPosition?: 'start' | 'end'
    }>(() => {
      const iconIndex = Children.toArray(children).findIndex((child) => isValidElement(child) && child.type === Icon)

      const childrenToShow =
        isLoading && iconIndex > -1 ? (
          <>
            {Children.map(children, (child) =>
              isValidElement(child) && child.type === Icon ? <Loader key={child.key} {...child.props} /> : child,
            )}
          </>
        ) : isLoading ? (
          <>
            <Loader /> {children}
          </>
        ) : (
          children
        )

      const childrenLength = Children.count(childrenToShow)

      return {
        childrenToShow,
        iconPosition:
          childrenLength === 1 || iconIndex === -1 ? undefined : iconIndex === childrenLength - 1 ? 'end' : 'start',
      }
    }, [children, isLoading])

    const handleMouseDown = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        rippleEvent(event)

        if (onMouseDown) {
          onMouseDown(event)
        }
      },
      [onMouseDown, rippleEvent],
    )

    const { borderRadius, gap, minHeight, minWidth, pl, pr, px, py } = useMemo(() => {
      const { borderRadius, gap, lineHeight, px, pxForIcon, py } = buttonSizesStyles[size]

      const minSize = `calc(${cssvarLineHeight(lineHeight)} + (${cssvarSpacing(py)} * 2))`

      return {
        ...(iconPosition === 'start' && { pl: cssvarSpacing(pxForIcon) }),
        ...(iconPosition === 'end' && { pr: cssvarSpacing(pxForIcon) }),
        borderRadius: isRound ? BORDER_RADIUS_CIRCLE : cssvarBorderRadius(borderRadius),
        gap: cssvarSpacing(gap),
        minHeight: minSize,
        minWidth: minSize,
        px: isRound ? cssvarSpacing(py) : cssvarSpacing(px),
        py: cssvarSpacing(py),
      }
    }, [iconPosition, isRound, size])

    const isDisabled = useMemo(() => disabled || isLoading, [disabled, isLoading])

    return (
      <ButtonContainer
        ref={buttonRef}
        $align="center"
        $borderRadius={borderRadius}
        $borderSize={0}
        $direction="row"
        $gap={gap}
        $isGhost={isGhost}
        $justify="center"
        $kind={kind}
        $minHeight={minHeight}
        $minWidth={minWidth}
        $pl={pl}
        $pr={pr}
        $px={px}
        $py={py}
        $size={size}
        aria-busy={isLoading}
        aria-disabled={isDisabled}
        aria-live="polite"
        as="button"
        disabled={isDisabled}
        type="button"
        {...props}
        onMouseDown={handleMouseDown}
      >
        {childrenToShow}
      </ButtonContainer>
    )
  },
)
