import { createContext, type ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { useTrackEventModal } from '~/domains/analytics/hooks/useTrackEventModal'

export type ModalId =
  | 'activateConvention'
  | 'askForReview'
  | 'availabilitiesInsertionReminder'
  | 'bookByAvailabilities'
  | `changeTherapist:${string}`
  | 'changeTherapistInfo'
  | 'changeTherapistLeave'
  | 'clinicalBond'
  | 'clinicalBondView'
  | 'clinicalTestExitConfirmation'
  | 'clinicalTestAttachClinicalTestToChat'
  | 'confirmDeleteUser'
  | 'createAvailability'
  | 'emailVerification'
  | 'findTherapist'
  | 'leaveVideocall'
  | 'formFlowLeave'
  | 'howToAddDiagnosis'
  | 'journalingLogConfirmDeletion'
  | 'journalingLogShare'
  | 'journalingLogShareBookedFirst'
  | `journalingLogShareBookedSecond:${string}`
  | 'journalingLogShareNoTherapist'
  | 'journalingLogShareNotBooked'
  | `journalingLogShareWithMultipleTherapists:${string}`
  | 'journalingTutorial'
  | 'phoneVerification'
  | 'recalculateAvailableTherapists'
  | 'technicalFeedback'
  | 'updateAvailability'
  | `bookTherapySessionByParent:${string}`
  | `bookTherapySessionByParentUnder24:${string}`
  | `deleteBatchRecurrency:${string}`
  | `deleteRecurrency:${string}`
  | `deleteTherapySession:${string}`
  | `editTherapySession:${string}`
  | `formOptionDetail:${string}`
  | `markAsNoShowTherapySession${string}`
  | 'noMoreTherapists'
  | 'referralActivation'
  | 'referralShare'
  | `rescheduleTherapySession:${string}`
  | `rescheduleTherapySessionConfirm:${string}`
  | `rescheduleTherapySessionUnder24:${string}`
  | `rescheduleTherapySessionWarning:${string}`
  | 'sentencesTutorialIntroduction'
  | `setChurnReason:${string}`
  | `shareJournalingLog:${string}`
  | 'shareSticker'
  | 'shareSentenceOfTheDay'
  | 'stickerModule'
  | `stopTherapySession:${string}`
  | `therapistProfile:${string}`
  | `triggerPayment:${string}`
  | 'videocallBackgroundEffectManagement'
  | 'videocallContentShareManagement'

type Context = {
  close: (id: ModalId) => void
  dismiss: (id: ModalId) => void
  isOpen: (id: ModalId) => boolean
  open: (id: ModalId) => void
}

type Props = {
  children: ReactNode
}

const ModalsContext = createContext<Context | null>(null)

export const ModalsProvider = ({ children }: Props) => {
  const trackEventModal = useTrackEventModal('dismiss')

  const [modals, setModals] = useState<Partial<Record<ModalId, boolean>>>({})

  const close = useCallback((id: ModalId) => {
    setModals((items) => ({ ...items, [id]: false }))
  }, [])

  const dismiss = useCallback(
    (id: ModalId) => {
      trackEventModal(id)

      close(id)
    },
    [close, trackEventModal],
  )

  const isOpen = useCallback((id: ModalId) => Boolean(modals[id]), [modals])

  const open = useCallback((id: ModalId) => {
    setModals((items) => ({ ...items, [id]: true }))
  }, [])

  const value = useMemo(() => ({ close, dismiss, isOpen, open }), [close, dismiss, isOpen, open])

  return <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>
}

export const useModals = () => {
  const context = useContext(ModalsContext)

  if (!context) {
    throw new Error('The `useModals` should be wrapped with `ModalsProvider`.')
  }

  return context
}
