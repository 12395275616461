/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** Degular Display, serif */
export const TYPOGRAPHY_PUBLIC_H0_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h0-font-family)` as const
export const TYPOGRAPHY_PUBLIC_H0_FONT_FAMILY_VALUE = 'Degular Display, serif' as const

/** 600 */
export const TYPOGRAPHY_PUBLIC_H0_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h0-font-weight)` as const
export const TYPOGRAPHY_PUBLIC_H0_FONT_WEIGHT_VALUE = '600' as const

/** 56px */
export const TYPOGRAPHY_PUBLIC_H0_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h0-line-height)` as const
export const TYPOGRAPHY_PUBLIC_H0_LINE_HEIGHT_VALUE = '56px' as const

/** 56px */
export const TYPOGRAPHY_PUBLIC_H0_FONT_SIZE = `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h0-font-size)` as const
export const TYPOGRAPHY_PUBLIC_H0_FONT_SIZE_VALUE = '56px' as const

/** calc(112px / 100) */
export const TYPOGRAPHY_PUBLIC_H0_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h0-letter-spacing)` as const
export const TYPOGRAPHY_PUBLIC_H0_LETTER_SPACING_VALUE = 'calc(112px / 100)' as const

/** Degular Display, serif */
export const TYPOGRAPHY_PUBLIC_H1_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h1-font-family)` as const
export const TYPOGRAPHY_PUBLIC_H1_FONT_FAMILY_VALUE = 'Degular Display, serif' as const

/** 600 */
export const TYPOGRAPHY_PUBLIC_H1_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h1-font-weight)` as const
export const TYPOGRAPHY_PUBLIC_H1_FONT_WEIGHT_VALUE = '600' as const

/** 32px */
export const TYPOGRAPHY_PUBLIC_H1_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h1-line-height)` as const
export const TYPOGRAPHY_PUBLIC_H1_LINE_HEIGHT_VALUE = '32px' as const

/** 32px */
export const TYPOGRAPHY_PUBLIC_H1_FONT_SIZE = `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h1-font-size)` as const
export const TYPOGRAPHY_PUBLIC_H1_FONT_SIZE_VALUE = '32px' as const

/** 0.64px */
export const TYPOGRAPHY_PUBLIC_H1_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h1-letter-spacing)` as const
export const TYPOGRAPHY_PUBLIC_H1_LETTER_SPACING_VALUE = '0.64px' as const

/** Degular Display, serif */
export const TYPOGRAPHY_PUBLIC_H2_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h2-font-family)` as const
export const TYPOGRAPHY_PUBLIC_H2_FONT_FAMILY_VALUE = 'Degular Display, serif' as const

/** 600 */
export const TYPOGRAPHY_PUBLIC_H2_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h2-font-weight)` as const
export const TYPOGRAPHY_PUBLIC_H2_FONT_WEIGHT_VALUE = '600' as const

/** 24px */
export const TYPOGRAPHY_PUBLIC_H2_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h2-line-height)` as const
export const TYPOGRAPHY_PUBLIC_H2_LINE_HEIGHT_VALUE = '24px' as const

/** 24px */
export const TYPOGRAPHY_PUBLIC_H2_FONT_SIZE = `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h2-font-size)` as const
export const TYPOGRAPHY_PUBLIC_H2_FONT_SIZE_VALUE = '24px' as const

/** 0.48px */
export const TYPOGRAPHY_PUBLIC_H2_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h2-letter-spacing)` as const
export const TYPOGRAPHY_PUBLIC_H2_LETTER_SPACING_VALUE = '0.48px' as const

/** Degular Display, serif */
export const TYPOGRAPHY_PUBLIC_H3_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h3-font-family)` as const
export const TYPOGRAPHY_PUBLIC_H3_FONT_FAMILY_VALUE = 'Degular Display, serif' as const

/** 400 */
export const TYPOGRAPHY_PUBLIC_H3_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h3-font-weight)` as const
export const TYPOGRAPHY_PUBLIC_H3_FONT_WEIGHT_VALUE = '400' as const

/** 24px */
export const TYPOGRAPHY_PUBLIC_H3_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h3-line-height)` as const
export const TYPOGRAPHY_PUBLIC_H3_LINE_HEIGHT_VALUE = '24px' as const

/** 20px */
export const TYPOGRAPHY_PUBLIC_H3_FONT_SIZE = `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h3-font-size)` as const
export const TYPOGRAPHY_PUBLIC_H3_FONT_SIZE_VALUE = '20px' as const

/** 0.40px */
export const TYPOGRAPHY_PUBLIC_H3_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h3-letter-spacing)` as const
export const TYPOGRAPHY_PUBLIC_H3_LETTER_SPACING_VALUE = '0.40px' as const

/** Degular Display, serif */
export const TYPOGRAPHY_PUBLIC_H3_STRONG_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h3-strong-font-family)` as const
export const TYPOGRAPHY_PUBLIC_H3_STRONG_FONT_FAMILY_VALUE = 'Degular Display, serif' as const

/** 600 */
export const TYPOGRAPHY_PUBLIC_H3_STRONG_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h3-strong-font-weight)` as const
export const TYPOGRAPHY_PUBLIC_H3_STRONG_FONT_WEIGHT_VALUE = '600' as const

/** 24px */
export const TYPOGRAPHY_PUBLIC_H3_STRONG_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h3-strong-line-height)` as const
export const TYPOGRAPHY_PUBLIC_H3_STRONG_LINE_HEIGHT_VALUE = '24px' as const

/** 20px */
export const TYPOGRAPHY_PUBLIC_H3_STRONG_FONT_SIZE =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h3-strong-font-size)` as const
export const TYPOGRAPHY_PUBLIC_H3_STRONG_FONT_SIZE_VALUE = '20px' as const

/** 0.40px */
export const TYPOGRAPHY_PUBLIC_H3_STRONG_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-h3-strong-letter-spacing)` as const
export const TYPOGRAPHY_PUBLIC_H3_STRONG_LETTER_SPACING_VALUE = '0.40px' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-paragraph-font-family)` as const
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 400 */
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-paragraph-font-weight)` as const
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_FONT_WEIGHT_VALUE = '400' as const

/** 24px */
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-paragraph-line-height)` as const
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_LINE_HEIGHT_VALUE = '24px' as const

/** 18px */
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_FONT_SIZE =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-paragraph-font-size)` as const
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_FONT_SIZE_VALUE = '18px' as const

/** calc(0.36px * -1) */
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-paragraph-letter-spacing)` as const
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_LETTER_SPACING_VALUE = 'calc(0.36px * -1)' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_STRONG_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-paragraph-strong-font-family)` as const
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_STRONG_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 600 */
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_STRONG_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-paragraph-strong-font-weight)` as const
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_STRONG_FONT_WEIGHT_VALUE = '600' as const

/** 24px */
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_STRONG_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-paragraph-strong-line-height)` as const
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_STRONG_LINE_HEIGHT_VALUE = '24px' as const

/** 18px */
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_STRONG_FONT_SIZE =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-paragraph-strong-font-size)` as const
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_STRONG_FONT_SIZE_VALUE = '18px' as const

/** calc(0.36px * -1) */
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_STRONG_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-paragraph-strong-letter-spacing)` as const
export const TYPOGRAPHY_PUBLIC_PARAGRAPH_STRONG_LETTER_SPACING_VALUE = 'calc(0.36px * -1)' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_PUBLIC_CAPTION_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-caption-font-family)` as const
export const TYPOGRAPHY_PUBLIC_CAPTION_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 400 */
export const TYPOGRAPHY_PUBLIC_CAPTION_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-caption-font-weight)` as const
export const TYPOGRAPHY_PUBLIC_CAPTION_FONT_WEIGHT_VALUE = '400' as const

/** 24px */
export const TYPOGRAPHY_PUBLIC_CAPTION_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-caption-line-height)` as const
export const TYPOGRAPHY_PUBLIC_CAPTION_LINE_HEIGHT_VALUE = '24px' as const

/** 16px */
export const TYPOGRAPHY_PUBLIC_CAPTION_FONT_SIZE =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-caption-font-size)` as const
export const TYPOGRAPHY_PUBLIC_CAPTION_FONT_SIZE_VALUE = '16px' as const

/** calc(0.64px * -1) */
export const TYPOGRAPHY_PUBLIC_CAPTION_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-caption-letter-spacing)` as const
export const TYPOGRAPHY_PUBLIC_CAPTION_LETTER_SPACING_VALUE = 'calc(0.64px * -1)' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_PUBLIC_CAPTION_STRONG_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-caption-strong-font-family)` as const
export const TYPOGRAPHY_PUBLIC_CAPTION_STRONG_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 600 */
export const TYPOGRAPHY_PUBLIC_CAPTION_STRONG_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-caption-strong-font-weight)` as const
export const TYPOGRAPHY_PUBLIC_CAPTION_STRONG_FONT_WEIGHT_VALUE = '600' as const

/** 24px */
export const TYPOGRAPHY_PUBLIC_CAPTION_STRONG_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-caption-strong-line-height)` as const
export const TYPOGRAPHY_PUBLIC_CAPTION_STRONG_LINE_HEIGHT_VALUE = '24px' as const

/** 16px */
export const TYPOGRAPHY_PUBLIC_CAPTION_STRONG_FONT_SIZE =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-caption-strong-font-size)` as const
export const TYPOGRAPHY_PUBLIC_CAPTION_STRONG_FONT_SIZE_VALUE = '16px' as const

/** calc(0.64px * -1) */
export const TYPOGRAPHY_PUBLIC_CAPTION_STRONG_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-caption-strong-letter-spacing)` as const
export const TYPOGRAPHY_PUBLIC_CAPTION_STRONG_LETTER_SPACING_VALUE = 'calc(0.64px * -1)' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-footnote-font-family)` as const
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 500 */
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-footnote-font-weight)` as const
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_FONT_WEIGHT_VALUE = '500' as const

/** 24px */
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-footnote-line-height)` as const
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_LINE_HEIGHT_VALUE = '24px' as const

/** 14px */
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_FONT_SIZE =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-footnote-font-size)` as const
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_FONT_SIZE_VALUE = '14px' as const

/** 0 */
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-footnote-letter-spacing)` as const
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_LETTER_SPACING_VALUE = '0' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_STRONG_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-footnote-strong-font-family)` as const
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_STRONG_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 600 */
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_STRONG_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-footnote-strong-font-weight)` as const
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_STRONG_FONT_WEIGHT_VALUE = '600' as const

/** 24px */
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_STRONG_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-footnote-strong-line-height)` as const
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_STRONG_LINE_HEIGHT_VALUE = '24px' as const

/** 14px */
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_STRONG_FONT_SIZE =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-footnote-strong-font-size)` as const
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_STRONG_FONT_SIZE_VALUE = '14px' as const

/** 0 */
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_STRONG_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-footnote-strong-letter-spacing)` as const
export const TYPOGRAPHY_PUBLIC_FOOTNOTE_STRONG_LETTER_SPACING_VALUE = '0' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_PUBLIC_MICRO_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-micro-font-family)` as const
export const TYPOGRAPHY_PUBLIC_MICRO_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 400 */
export const TYPOGRAPHY_PUBLIC_MICRO_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-micro-font-weight)` as const
export const TYPOGRAPHY_PUBLIC_MICRO_FONT_WEIGHT_VALUE = '400' as const

/** 16px */
export const TYPOGRAPHY_PUBLIC_MICRO_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-micro-line-height)` as const
export const TYPOGRAPHY_PUBLIC_MICRO_LINE_HEIGHT_VALUE = '16px' as const

/** 12px */
export const TYPOGRAPHY_PUBLIC_MICRO_FONT_SIZE =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-micro-font-size)` as const
export const TYPOGRAPHY_PUBLIC_MICRO_FONT_SIZE_VALUE = '12px' as const

/** 0 */
export const TYPOGRAPHY_PUBLIC_MICRO_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-public-micro-letter-spacing)` as const
export const TYPOGRAPHY_PUBLIC_MICRO_LETTER_SPACING_VALUE = '0' as const

/** Degular Display, serif */
export const TYPOGRAPHY_H0_FONT_FAMILY = `var(--${DESIGN_TOKENS_PREFIX}-typography-h0-font-family)` as const
export const TYPOGRAPHY_H0_FONT_FAMILY_VALUE = 'Degular Display, serif' as const

/** 600 */
export const TYPOGRAPHY_H0_FONT_WEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-h0-font-weight)` as const
export const TYPOGRAPHY_H0_FONT_WEIGHT_VALUE = '600' as const

/** 56px */
export const TYPOGRAPHY_H0_LINE_HEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-h0-line-height)` as const
export const TYPOGRAPHY_H0_LINE_HEIGHT_VALUE = '56px' as const

/** 56px */
export const TYPOGRAPHY_H0_FONT_SIZE = `var(--${DESIGN_TOKENS_PREFIX}-typography-h0-font-size)` as const
export const TYPOGRAPHY_H0_FONT_SIZE_VALUE = '56px' as const

/** calc(112px / 100) */
export const TYPOGRAPHY_H0_LETTER_SPACING = `var(--${DESIGN_TOKENS_PREFIX}-typography-h0-letter-spacing)` as const
export const TYPOGRAPHY_H0_LETTER_SPACING_VALUE = 'calc(112px / 100)' as const

/** Degular Display, serif */
export const TYPOGRAPHY_H1_FONT_FAMILY = `var(--${DESIGN_TOKENS_PREFIX}-typography-h1-font-family)` as const
export const TYPOGRAPHY_H1_FONT_FAMILY_VALUE = 'Degular Display, serif' as const

/** 600 */
export const TYPOGRAPHY_H1_FONT_WEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-h1-font-weight)` as const
export const TYPOGRAPHY_H1_FONT_WEIGHT_VALUE = '600' as const

/** 32px */
export const TYPOGRAPHY_H1_LINE_HEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-h1-line-height)` as const
export const TYPOGRAPHY_H1_LINE_HEIGHT_VALUE = '32px' as const

/** 32px */
export const TYPOGRAPHY_H1_FONT_SIZE = `var(--${DESIGN_TOKENS_PREFIX}-typography-h1-font-size)` as const
export const TYPOGRAPHY_H1_FONT_SIZE_VALUE = '32px' as const

/** 0.64px */
export const TYPOGRAPHY_H1_LETTER_SPACING = `var(--${DESIGN_TOKENS_PREFIX}-typography-h1-letter-spacing)` as const
export const TYPOGRAPHY_H1_LETTER_SPACING_VALUE = '0.64px' as const

/** Degular Display, serif */
export const TYPOGRAPHY_H2_FONT_FAMILY = `var(--${DESIGN_TOKENS_PREFIX}-typography-h2-font-family)` as const
export const TYPOGRAPHY_H2_FONT_FAMILY_VALUE = 'Degular Display, serif' as const

/** 600 */
export const TYPOGRAPHY_H2_FONT_WEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-h2-font-weight)` as const
export const TYPOGRAPHY_H2_FONT_WEIGHT_VALUE = '600' as const

/** 24px */
export const TYPOGRAPHY_H2_LINE_HEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-h2-line-height)` as const
export const TYPOGRAPHY_H2_LINE_HEIGHT_VALUE = '24px' as const

/** 24px */
export const TYPOGRAPHY_H2_FONT_SIZE = `var(--${DESIGN_TOKENS_PREFIX}-typography-h2-font-size)` as const
export const TYPOGRAPHY_H2_FONT_SIZE_VALUE = '24px' as const

/** 0.48px */
export const TYPOGRAPHY_H2_LETTER_SPACING = `var(--${DESIGN_TOKENS_PREFIX}-typography-h2-letter-spacing)` as const
export const TYPOGRAPHY_H2_LETTER_SPACING_VALUE = '0.48px' as const

/** Degular Display, serif */
export const TYPOGRAPHY_H3_FONT_FAMILY = `var(--${DESIGN_TOKENS_PREFIX}-typography-h3-font-family)` as const
export const TYPOGRAPHY_H3_FONT_FAMILY_VALUE = 'Degular Display, serif' as const

/** 600 */
export const TYPOGRAPHY_H3_FONT_WEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-h3-font-weight)` as const
export const TYPOGRAPHY_H3_FONT_WEIGHT_VALUE = '600' as const

/** 24px */
export const TYPOGRAPHY_H3_LINE_HEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-h3-line-height)` as const
export const TYPOGRAPHY_H3_LINE_HEIGHT_VALUE = '24px' as const

/** 20px */
export const TYPOGRAPHY_H3_FONT_SIZE = `var(--${DESIGN_TOKENS_PREFIX}-typography-h3-font-size)` as const
export const TYPOGRAPHY_H3_FONT_SIZE_VALUE = '20px' as const

/** 0.40px */
export const TYPOGRAPHY_H3_LETTER_SPACING = `var(--${DESIGN_TOKENS_PREFIX}-typography-h3-letter-spacing)` as const
export const TYPOGRAPHY_H3_LETTER_SPACING_VALUE = '0.40px' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_PARAGRAPH_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-paragraph-font-family)` as const
export const TYPOGRAPHY_PARAGRAPH_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 400 */
export const TYPOGRAPHY_PARAGRAPH_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-paragraph-font-weight)` as const
export const TYPOGRAPHY_PARAGRAPH_FONT_WEIGHT_VALUE = '400' as const

/** 24px */
export const TYPOGRAPHY_PARAGRAPH_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-paragraph-line-height)` as const
export const TYPOGRAPHY_PARAGRAPH_LINE_HEIGHT_VALUE = '24px' as const

/** 18px */
export const TYPOGRAPHY_PARAGRAPH_FONT_SIZE = `var(--${DESIGN_TOKENS_PREFIX}-typography-paragraph-font-size)` as const
export const TYPOGRAPHY_PARAGRAPH_FONT_SIZE_VALUE = '18px' as const

/** calc(0.36px * -1) */
export const TYPOGRAPHY_PARAGRAPH_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-paragraph-letter-spacing)` as const
export const TYPOGRAPHY_PARAGRAPH_LETTER_SPACING_VALUE = 'calc(0.36px * -1)' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_PARAGRAPH_STRONG_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-paragraph-strong-font-family)` as const
export const TYPOGRAPHY_PARAGRAPH_STRONG_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 600 */
export const TYPOGRAPHY_PARAGRAPH_STRONG_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-paragraph-strong-font-weight)` as const
export const TYPOGRAPHY_PARAGRAPH_STRONG_FONT_WEIGHT_VALUE = '600' as const

/** 24px */
export const TYPOGRAPHY_PARAGRAPH_STRONG_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-paragraph-strong-line-height)` as const
export const TYPOGRAPHY_PARAGRAPH_STRONG_LINE_HEIGHT_VALUE = '24px' as const

/** 18px */
export const TYPOGRAPHY_PARAGRAPH_STRONG_FONT_SIZE =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-paragraph-strong-font-size)` as const
export const TYPOGRAPHY_PARAGRAPH_STRONG_FONT_SIZE_VALUE = '18px' as const

/** calc(0.36px * -1) */
export const TYPOGRAPHY_PARAGRAPH_STRONG_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-paragraph-strong-letter-spacing)` as const
export const TYPOGRAPHY_PARAGRAPH_STRONG_LETTER_SPACING_VALUE = 'calc(0.36px * -1)' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_CAPTION_FONT_FAMILY = `var(--${DESIGN_TOKENS_PREFIX}-typography-caption-font-family)` as const
export const TYPOGRAPHY_CAPTION_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 400 */
export const TYPOGRAPHY_CAPTION_FONT_WEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-caption-font-weight)` as const
export const TYPOGRAPHY_CAPTION_FONT_WEIGHT_VALUE = '400' as const

/** 24px */
export const TYPOGRAPHY_CAPTION_LINE_HEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-caption-line-height)` as const
export const TYPOGRAPHY_CAPTION_LINE_HEIGHT_VALUE = '24px' as const

/** 16px */
export const TYPOGRAPHY_CAPTION_FONT_SIZE = `var(--${DESIGN_TOKENS_PREFIX}-typography-caption-font-size)` as const
export const TYPOGRAPHY_CAPTION_FONT_SIZE_VALUE = '16px' as const

/** calc(0.64px * -1) */
export const TYPOGRAPHY_CAPTION_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-caption-letter-spacing)` as const
export const TYPOGRAPHY_CAPTION_LETTER_SPACING_VALUE = 'calc(0.64px * -1)' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_CAPTION_STRONG_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-caption-strong-font-family)` as const
export const TYPOGRAPHY_CAPTION_STRONG_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 600 */
export const TYPOGRAPHY_CAPTION_STRONG_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-caption-strong-font-weight)` as const
export const TYPOGRAPHY_CAPTION_STRONG_FONT_WEIGHT_VALUE = '600' as const

/** 24px */
export const TYPOGRAPHY_CAPTION_STRONG_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-caption-strong-line-height)` as const
export const TYPOGRAPHY_CAPTION_STRONG_LINE_HEIGHT_VALUE = '24px' as const

/** 16px */
export const TYPOGRAPHY_CAPTION_STRONG_FONT_SIZE =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-caption-strong-font-size)` as const
export const TYPOGRAPHY_CAPTION_STRONG_FONT_SIZE_VALUE = '16px' as const

/** calc(0.64px * -1) */
export const TYPOGRAPHY_CAPTION_STRONG_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-caption-strong-letter-spacing)` as const
export const TYPOGRAPHY_CAPTION_STRONG_LETTER_SPACING_VALUE = 'calc(0.64px * -1)' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_FOOTNOTE_FONT_FAMILY = `var(--${DESIGN_TOKENS_PREFIX}-typography-footnote-font-family)` as const
export const TYPOGRAPHY_FOOTNOTE_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 500 */
export const TYPOGRAPHY_FOOTNOTE_FONT_WEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-footnote-font-weight)` as const
export const TYPOGRAPHY_FOOTNOTE_FONT_WEIGHT_VALUE = '500' as const

/** 24px */
export const TYPOGRAPHY_FOOTNOTE_LINE_HEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-footnote-line-height)` as const
export const TYPOGRAPHY_FOOTNOTE_LINE_HEIGHT_VALUE = '24px' as const

/** 14px */
export const TYPOGRAPHY_FOOTNOTE_FONT_SIZE = `var(--${DESIGN_TOKENS_PREFIX}-typography-footnote-font-size)` as const
export const TYPOGRAPHY_FOOTNOTE_FONT_SIZE_VALUE = '14px' as const

/** 0 */
export const TYPOGRAPHY_FOOTNOTE_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-footnote-letter-spacing)` as const
export const TYPOGRAPHY_FOOTNOTE_LETTER_SPACING_VALUE = '0' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_FOOTNOTE_STRONG_FONT_FAMILY =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-footnote-strong-font-family)` as const
export const TYPOGRAPHY_FOOTNOTE_STRONG_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 600 */
export const TYPOGRAPHY_FOOTNOTE_STRONG_FONT_WEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-footnote-strong-font-weight)` as const
export const TYPOGRAPHY_FOOTNOTE_STRONG_FONT_WEIGHT_VALUE = '600' as const

/** 24px */
export const TYPOGRAPHY_FOOTNOTE_STRONG_LINE_HEIGHT =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-footnote-strong-line-height)` as const
export const TYPOGRAPHY_FOOTNOTE_STRONG_LINE_HEIGHT_VALUE = '24px' as const

/** 14px */
export const TYPOGRAPHY_FOOTNOTE_STRONG_FONT_SIZE =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-footnote-strong-font-size)` as const
export const TYPOGRAPHY_FOOTNOTE_STRONG_FONT_SIZE_VALUE = '14px' as const

/** 0 */
export const TYPOGRAPHY_FOOTNOTE_STRONG_LETTER_SPACING =
  `var(--${DESIGN_TOKENS_PREFIX}-typography-footnote-strong-letter-spacing)` as const
export const TYPOGRAPHY_FOOTNOTE_STRONG_LETTER_SPACING_VALUE = '0' as const

/** Degular Text, sans-serif */
export const TYPOGRAPHY_MICRO_FONT_FAMILY = `var(--${DESIGN_TOKENS_PREFIX}-typography-micro-font-family)` as const
export const TYPOGRAPHY_MICRO_FONT_FAMILY_VALUE = 'Degular Text, sans-serif' as const

/** 500 */
export const TYPOGRAPHY_MICRO_FONT_WEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-micro-font-weight)` as const
export const TYPOGRAPHY_MICRO_FONT_WEIGHT_VALUE = '500' as const

/** 16px */
export const TYPOGRAPHY_MICRO_LINE_HEIGHT = `var(--${DESIGN_TOKENS_PREFIX}-typography-micro-line-height)` as const
export const TYPOGRAPHY_MICRO_LINE_HEIGHT_VALUE = '16px' as const

/** 12px */
export const TYPOGRAPHY_MICRO_FONT_SIZE = `var(--${DESIGN_TOKENS_PREFIX}-typography-micro-font-size)` as const
export const TYPOGRAPHY_MICRO_FONT_SIZE_VALUE = '12px' as const

/** 0 */
export const TYPOGRAPHY_MICRO_LETTER_SPACING = `var(--${DESIGN_TOKENS_PREFIX}-typography-micro-letter-spacing)` as const
export const TYPOGRAPHY_MICRO_LETTER_SPACING_VALUE = '0' as const
