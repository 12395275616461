import { noop } from 'functions'
import { BrowserRouter } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { Toasts } from '~/components/Toasts'
import { useClarityIdentifyEffect } from '~/domains/analytics/hooks/useClarityIdentifyEffect'
import { useMixPanelIdentifyEffect } from '~/domains/analytics/hooks/useMixPanelIdentifyEffect'
import { ThirdPartyAppProvider } from '~/domains/appNative/hooks/useThirdPartyApp'
import { AuthStateProvider } from '~/domains/auth/components/AuthStateProvider'
import { TherapistAvailabilitiesGivenCountProvider } from '~/domains/availabilities/reminders/hooks/useTherapistAvailabilitiesGivenCount'
import { ChatProvider } from '~/domains/chat/hooks/useChat'
import { ChatUnreadCountProvider } from '~/domains/chat/hooks/useChatUnreadCount'
import { useChatUnreadCountListenerEffect } from '~/domains/chat/hooks/useChatUnreadCountListenerEffect'
import { ActivateConventionModal } from '~/domains/convention/components/ActivateConventionModal'
import { useConventionCodeName } from '~/domains/convention/hooks/useConventionCodeName'
import { FeatureFlagsByFingerprint, FeatureFlagsByUserId } from '~/domains/featureFlags'
import { FormFlowsTextValuesProvider } from '~/domains/formFlow/hooks/useFormFlowTextValues'
import { IntercomProvider } from '~/domains/intercom/components/IntercomProvider'
import { useUpdateIntercom } from '~/domains/intercom/hooks/useUpdateIntercom'
import { useUpdateIntercomFeatureFlags } from '~/domains/intercom/hooks/useUpdateIntercomFeatureFlags'
import { useUpdateIntercomFingerprint } from '~/domains/intercom/hooks/useUpdateIntercomFingerprint'
import { Modals } from '~/domains/modals'
import { ModalsProvider } from '~/domains/modals/hooks/useModals'
import { NavigationProvider } from '~/domains/navigation/hooks/useNavigation'
import { ReferralActivationModal } from '~/domains/referral/components/ReferralActivationModal'
import { useReferralCodeName } from '~/domains/referral/hooks/useReferralCodeName'
import { useAddUserInformationToDataLayer } from '~/hooks/useAddUserInformationToDataLayer'
import { useAddUserToSentryEffect } from '~/hooks/useAddUserToSentryEffect'
import { CurrentUserProvider } from '~/hooks/useCurrentUser'
import { useInitializeDataLayer } from '~/hooks/useInitializeDataLayer'
import { useInitializeInHouseVideocall } from '~/hooks/useInitializeInHouseVideocall'
import { LoadingStateProvider } from '~/hooks/useLoadingState'
import { useQueryParamInSessionStorageEffect } from '~/hooks/useQueryParamInSessionStorageEffect'
import { RootHistoryProvider } from '~/hooks/useRootHistory'
import { useSetupAnonymousFingerprintEffect } from '~/hooks/useSetupAnonymousFingerprintEffect'
import { ToastsProvider } from '~/hooks/useToasts'
import { useUpdateLocalStorageSettingsInvite } from '~/hooks/useUpdateLocalStorageSettingsInvite'
import { LanguageProvider } from '~/i18n/hooks/useLanguage'
import { MainPageLayout } from '~/Layout/MainPageLayout'

const SetupEffect = () => {
  useAddUserInformationToDataLayer()
  useAddUserToSentryEffect()
  useChatUnreadCountListenerEffect()
  useClarityIdentifyEffect()
  useMixPanelIdentifyEffect()
  useUpdateLocalStorageSettingsInvite()
  useUpdateIntercom()
  useUpdateIntercomFingerprint()
  useUpdateIntercomFeatureFlags()
  useReferralCodeName()
  useConventionCodeName()

  return null
}

const App = () => {
  useInitializeInHouseVideocall()
  useInitializeDataLayer()
  useQueryParamInSessionStorageEffect()
  useSetupAnonymousFingerprintEffect()

  return (
    <BrowserRouter getUserConfirmation={noop}>
      <CompatRouter>
        <RootHistoryProvider>
          <LanguageProvider>
            <IntercomProvider>
              <LoadingStateProvider>
                <FormFlowsTextValuesProvider>
                  <ToastsProvider>
                    <ThirdPartyAppProvider>
                      <CurrentUserProvider>
                        <AuthStateProvider>
                          <ModalsProvider>
                            <Modals />
                            <FeatureFlagsByFingerprint>
                              <FeatureFlagsByUserId>
                                <ChatUnreadCountProvider>
                                  <ChatProvider>
                                    <TherapistAvailabilitiesGivenCountProvider>
                                      <NavigationProvider>
                                        <SetupEffect />
                                        <ActivateConventionModal />
                                        <MainPageLayout />
                                        <ReferralActivationModal />
                                      </NavigationProvider>
                                    </TherapistAvailabilitiesGivenCountProvider>
                                  </ChatProvider>
                                </ChatUnreadCountProvider>
                              </FeatureFlagsByUserId>
                            </FeatureFlagsByFingerprint>
                          </ModalsProvider>
                        </AuthStateProvider>
                      </CurrentUserProvider>
                    </ThirdPartyAppProvider>
                    <Toasts />
                  </ToastsProvider>
                </FormFlowsTextValuesProvider>
              </LoadingStateProvider>
            </IntercomProvider>
          </LanguageProvider>
        </RootHistoryProvider>
      </CompatRouter>
    </BrowserRouter>
  )
}

export default App
