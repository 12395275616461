import { gql, useMutation } from '@apollo/client'
import { useCallback, useState } from 'react'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { useToasts } from '~/hooks/useToasts'
import { type TranslationId, type TranslationValues } from '~/i18n/types'
import { type EmailVerificationRequestMutation, type EmailVerificationVerifyMutation } from '~/types/graphql'
import { isGraphQLResponse } from '~/utils/graphql'

const EMAIL_VERIFICATION_REQUEST = gql`
  mutation emailVerificationRequest($input: EmailVerificationRequestInput!) {
    emailVerificationRequest(input: $input) {
      ... on ActionResponse {
        __typename
        ok
      }
      ... on TooManyAttemptsErrorResponse {
        __typename
        waitingTime
      }
    }
  }
`

const EMAIL_VERIFICATION_VERIFY = gql`
  mutation emailVerificationVerify($input: EmailVerificationVerifyInput!) {
    emailVerificationVerify(input: $input) {
      ... on ActionResponse {
        __typename
        ok
      }
    }
  }
`

export const useEmailVerification = () => {
  const { addToast } = useToasts()
  const { updateQuery } = useCurrentUserNullable()
  const { isAuthenticated } = useAuthState()
  const [error, setError] = useState<boolean>(false)

  const [emailVerificationRequest, { loading: sendingEmail }] =
    useMutation<EmailVerificationRequestMutation>(EMAIL_VERIFICATION_REQUEST)
  const [emailVerificationVerify, { loading: verifyingCode }] =
    useMutation<EmailVerificationVerifyMutation>(EMAIL_VERIFICATION_VERIFY)

  const loading = sendingEmail || verifyingCode

  const requestVerification = useCallback(
    async (email: string): Promise<boolean> => {
      setError(false)
      try {
        const { data } = await emailVerificationRequest({ variables: { input: { emailToVerify: email } } })

        let errorTranslationId: TranslationId | undefined = undefined
        let errorTranslationValues: TranslationValues | undefined = undefined
        if (isGraphQLResponse(data?.emailVerificationRequest, 'TooManyAttemptsErrorResponse')) {
          errorTranslationId = 'emailVerification.request.error.tooManyAttempts'
          errorTranslationValues = { waitingTime: data.emailVerificationRequest.waitingTime / 60 }
        } else if (
          !isGraphQLResponse(data?.emailVerificationRequest, 'ActionResponse') ||
          !data.emailVerificationRequest.ok
        ) {
          errorTranslationId = 'generic.errorRetryLater'
        }

        if (errorTranslationId) {
          setError(true)
          addToast({ type: 'alert', translationId: errorTranslationId, translationValues: errorTranslationValues })
          return false
        }

        updateQuery((state) => {
          if (!state.me) {
            return state
          }

          return {
            ...state,
            me: { ...state.me, email, emailVerifiedAt: null },
          }
        })

        addToast({
          type: 'success',
          translationId: 'emailVerification.request.success',
          translationValues: { email },
        })

        return true
      } catch (err) {
        setError(true)
        addToast({ type: 'alert', translationId: 'generic.errorRetryLater' })
        return false
      }
    },
    [addToast, emailVerificationRequest, updateQuery],
  )

  const verifyCode = useCallback(
    async (userId: string, code: string): Promise<boolean> => {
      setError(false)
      try {
        const { data } = await emailVerificationVerify({ variables: { input: { codeToVerify: code, userId } } })
        if (!isGraphQLResponse(data?.emailVerificationVerify, 'ActionResponse') || !data.emailVerificationVerify.ok) {
          setError(true)
          return false
        }

        if (isAuthenticated) {
          updateQuery((state) => {
            if (!state.me) {
              return state
            }

            return {
              ...state,
              me: { ...state.me, emailVerifiedAt: new Date() },
            }
          })
        }
        addToast({ type: 'success', translationId: 'emailVerification.verify.success' })
        return true
      } catch (err) {
        setError(true)
        return false
      }
    },
    [addToast, emailVerificationVerify, isAuthenticated, updateQuery],
  )

  return {
    requestVerification,
    verifyCode,
    error,
    loading,
  }
}
