import { Flex } from 'cdk'
import { SPACING_XS } from 'design-tokens'
import { FieldMarketingEmails } from '~/domains/react-hook-form/fields/FieldMarketingEmails'
import { FieldProfilation } from '~/domains/react-hook-form/fields/FieldProfilation'
import { FieldTermsAndConditions } from '~/domains/react-hook-form/fields/FieldTermsAndConditions'

export const SignupTermsAndConditions = () => (
  <Flex $gap={SPACING_XS}>
    <FieldMarketingEmails />
    <FieldTermsAndConditions />
    <FieldProfilation />
  </Flex>
)
