import { Flex, type FlexProps, MaxWidth1260px } from 'cdk'
import { COLOR_PRIMARY_80, OPACITY_32, SPACING_MD, SPACING_XS } from 'design-tokens'
import { useBreakpoints } from 'hooks'
import { Icon } from 'icons'
import { useMemo } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Text } from 'ui'
import { useAppBarContent } from '~/components/AppBar/hooks/useAppBarContent'
import { TertiaryButtonIconLeft } from '~/components/TertiaryButton/TertiaryButtonIconLeft'
import { TertiaryButtonIconOnly } from '~/components/TertiaryButton/TertiaryButtonIconOnly'
import { Translation } from '~/components/Translation'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import {
  LinkGesto,
  LinkMemberGetMember,
  LinkNotification,
  LinkSupport,
  TabNavigationMenuProvider,
} from '~/domains/navigation/NewNavigation'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { useCurrentUserProfessionTypeValue } from '~/hooks/useCurrentUserProfessionTypeValue'
import { getRoute } from '~/utils/getRoute'
import { APP_BAR_HEIGHT } from '../constants'
import { AppBarLogo } from './AppBarLogo'
import { AppBarTitle } from './AppBarTitle'

type AppBarContainer = FlexProps & { $isSmallOnly: boolean }

const AppBarContainer = styled(Flex).attrs({
  $backgroundColorName: 'lighter',
  $basis: APP_BAR_HEIGHT,
  $justify: 'center',
  $px: SPACING_MD,
  as: 'header',
})<AppBarContainer>`
  ${({ $isSmallOnly }) => css`
    box-shadow: ${$isSmallOnly ? `0 -0.5px 0.5px 0 rgb(from ${COLOR_PRIMARY_80} r g b / ${OPACITY_32}) inset` : ''};
  `};
`

export const AppBarContent = () => {
  const isTherapist = useUserActingAsGroup() === 'therapist'
  const { isNutritionist } = useCurrentUserProfessionTypeValue()
  const { pathname } = useLocation()
  const { referralCode } = useCurrentUserNullable()

  const {
    goBackOrToFallbackPreviousRoute,
    isVisibleBackButton,
    leaveChangeTherapistFlow,
    shouldShowCancelButton,
    shouldShowLeaveChangeTherapistButton,
    shouldShowShareReferralButton,
    title,
  } = useAppBarContent()

  const { isSmallOnly } = useBreakpoints()

  const shouldShowAppbar = useMemo(
    () => !isVisibleBackButton && !shouldShowCancelButton && !shouldShowLeaveChangeTherapistButton && isSmallOnly,
    [isVisibleBackButton, shouldShowCancelButton, shouldShowLeaveChangeTherapistButton, isSmallOnly],
  )

  const isHomePage = !!matchPath(pathname, { path: [getRoute('/')], exact: true })
  const isChatPage = !!matchPath(pathname, { path: [getRoute('/chat')], exact: false })
  const isJournalingPage = !!matchPath(pathname, { path: [getRoute('/journaling')], exact: false })
  const isTherapiesPage = !!matchPath(pathname, { path: [getRoute('/therapies')], exact: false })
  const isPatientsPage = !!matchPath(pathname, { path: [getRoute('/patients')], exact: false })
  const isSettingsInvitePage = !!matchPath(pathname, { path: [getRoute('/settings/invite')], exact: true })

  const isMainPage =
    isHomePage || isChatPage || isJournalingPage || isTherapiesPage || isPatientsPage || isSettingsInvitePage

  const showSuffixActions = shouldShowCancelButton || shouldShowLeaveChangeTherapistButton || !isSmallOnly

  if (isMainPage && !isSmallOnly) {
    return null
  }

  return (
    <AppBarContainer $isSmallOnly={isSmallOnly}>
      <MaxWidth1260px $align="center" $direction="row" $justify="space-between">
        {shouldShowAppbar && (
          <Link aria-label="Serenis Health" to={getRoute('/')}>
            <AppBarLogo />
          </Link>
        )}

        {isVisibleBackButton && (
          <Flex $basis="52px">
            <TertiaryButtonIconLeft iconName="arrow-left" onClick={goBackOrToFallbackPreviousRoute}>
              <Flex $hide $mdHide={false}>
                <Text fontWeight="600" kind="paragraph">
                  <Translation id="actions.back" />
                </Text>
              </Flex>
            </TertiaryButtonIconLeft>
          </Flex>
        )}

        {title && <AppBarTitle>{title}</AppBarTitle>}

        {!showSuffixActions && isSmallOnly && (
          <Flex $direction="row" $gap={SPACING_XS} $justify="flex-end" $minWidth={52}>
            <TabNavigationMenuProvider>
              {isTherapist && (
                <>
                  <LinkNotification extended={false} isMobile={isSmallOnly} />
                  <LinkSupport extended={false} isMobile={isSmallOnly} />
                  {!isNutritionist && <LinkGesto extended={false} isMobile={isSmallOnly} />}
                </>
              )}
              {!isTherapist && referralCode && shouldShowShareReferralButton && <LinkMemberGetMember />}
            </TabNavigationMenuProvider>
          </Flex>
        )}

        {showSuffixActions && (
          <Flex $align="center" $direction="row">
            <Flex $pl={12} data-test-id="appbar-close-button">
              {shouldShowCancelButton && (
                <TertiaryButtonIconOnly onClick={goBackOrToFallbackPreviousRoute}>
                  <Icon name="xmark" size={24} />
                </TertiaryButtonIconOnly>
              )}

              {shouldShowLeaveChangeTherapistButton && (
                <TertiaryButtonIconOnly onClick={leaveChangeTherapistFlow}>
                  <Icon name="xmark" size={24} />
                </TertiaryButtonIconOnly>
              )}
            </Flex>
          </Flex>
        )}
      </MaxWidth1260px>
    </AppBarContainer>
  )
}
