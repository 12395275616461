import { Flex } from 'cdk'
import { BORDER_RADIUS_XS, COLOR_NEUTRAL_20, SPACING_XS } from 'design-tokens'
import styled, { css } from 'styled-components'
import { UnreadDot } from './UnreadDot'

type NavLinkContainerProps = {
  $isMobile?: boolean
}

export const NavLinkContainer = styled(Flex).attrs({
  $align: 'center',
  $p: SPACING_XS,
})<NavLinkContainerProps>`
  ${({ $isMobile = false }) => css`
    &:hover {
      border-radius: ${BORDER_RADIUS_XS};
      background-color: ${!$isMobile ? COLOR_NEUTRAL_20 : 'inherit'};

      ${UnreadDot} {
        border-color: ${!$isMobile ? COLOR_NEUTRAL_20 : 'inherit'};
      }
    }
  `};
`
