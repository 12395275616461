import { fold, tryCatch } from 'fp-ts/Either'
import { identity, pipe } from 'fp-ts/function'
import { fromCents } from './fromCents'
import { isInteger } from './isInteger'

const options: Intl.NumberFormatOptions = {
  currency: 'EUR',
  style: 'currency',
}

const optionsWithNoDigits: Intl.NumberFormatOptions = {
  ...options,
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}

const optionsWithDigits: Intl.NumberFormatOptions = {
  ...options,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}

const locale: Intl.LocalesArgument = 'it-IT'

export const toCurrency = (value: number) =>
  pipe(
    value,
    fromCents,
    (v) =>
      tryCatch(
        () => v.toLocaleString(locale, optionsWithNoDigits),
        () => v.toLocaleString(locale, options),
      ),
    fold(identity, identity),
  )

export const toCurrencyWithFractionDigits = (value: number) =>
  pipe(
    value,
    fromCents,
    (v) =>
      tryCatch(
        () => v.toLocaleString(locale, optionsWithDigits),
        () => v.toLocaleString(locale, options),
      ),
    fold(identity, identity),
  )

export const toCurrencyIntOrDecimal = (value: number) =>
  pipe(
    value,
    fromCents,
    (v) =>
      tryCatch(
        () => v.toLocaleString(locale, isInteger(v) ? optionsWithNoDigits : optionsWithDigits),
        () => v.toLocaleString(locale, options),
      ),
    fold(identity, identity),
  )
