import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisXMarkCircle = ({ color, fill, height, width }: Props) => (
  <svg fill="none" height={height} width={width} xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" fill={fill} fillOpacity=".72" r="12" />
    <path d="M16 8.00001 12 12m0 0-4 4m4-4 4 4m-4-4L8 8" stroke={color} strokeLinecap="round" strokeWidth="2" />
  </svg>
)
