import { useState } from 'react'
import { useIsNativeAppAndroid } from '~/domains/appNative/hooks/useIsNativeAppAndroid'
import { useReactNativePostMessage } from '~/domains/appNative/hooks/useReactNativePostMessage'

type CopiedValue = string | null
type CopyFn = (text: string) => Promise<boolean>

export const useCopyToClipboard = (): [CopiedValue, CopyFn] => {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null)

  const isNativeAppAndroid = useIsNativeAppAndroid()

  const { copyToClipboardAndroid } = useReactNativePostMessage()

  const copy: CopyFn = async (text) => {
    if (navigator?.clipboard == null) {
      return false
    }

    try {
      if (isNativeAppAndroid) {
        copyToClipboardAndroid(text)
      } else {
        await navigator.clipboard.writeText(text)
      }
      setCopiedText(text)

      return true
    } catch (error) {
      setCopiedText(null)
      return false
    }
  }

  return [copiedText, copy]
}
