import { Flex, Pressable } from 'cdk'
import { subHours } from 'date-fns/fp'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { Icon } from 'icons'
import { toCurrencyIntOrDecimal } from 'numbers'
import { useMemo } from 'react'
import { matchPath } from 'react-router-dom'
import { Avatar, Text } from 'ui'
import { CardBoxNoStroke } from '~/components/CardBoxNoStroke'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useAvailableChangeTherapistsFree } from '~/domains/changeTherapist/hooks/useAvailableChangeTherapistsFree'
import { usePaymentMethods } from '~/domains/payments/hooks/usePaymentMethods'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { type ReservationFormValues } from '~/domains/reservation/types'
import { TherapistOverviewCardDetail } from '~/domains/therapist/TherapistOverviewCard/Detail'
import { useRootLocation } from '~/hooks/useRootHistory'
import { TherapistProvider, useTherapist } from '~/hooks/useTherapist'
import { type TranslationId } from '~/i18n/types'
import { getTherapySessionDayAndMonthShort } from '~/utils/dates/getTherapySessionDayAndMonth'
import { getTherapySessionStartAt } from '~/utils/dates/getTherapySessionStartAt'
import { getRoute } from '~/utils/getRoute'
import { getTherapySessionCancellationPolicyInHours } from '~/utils/getTherapySessionCancellationPolicyInHours'
import { useReservationFlow } from '../hooks/useReservationFlow'
import { useSuspenseTherapyPathByIdQuery } from '../hooks/useTherapyPathByIdQuery'

type Props = {
  timeslot: Date
}

const SummaryWithTherapist = ({ timeslot }: Props) => {
  const {
    fullName,
    therapist: { profileImage },
  } = useTherapist()

  const { therapyPath } = useSuspenseTherapyPathByIdQuery()

  const titleTranslationId = useMemo((): TranslationId => {
    if (therapyPath.type === 'MYSELF_PSYCHIATRY') {
      return 'reservation.submit.psychiatrist.title'
    }

    if (therapyPath.type === 'PATH_COACHING') {
      return 'reservation.submit.coach.title'
    }

    return 'reservation.submit.therapist.title'
  }, [therapyPath.type])

  return (
    <>
      <Flex $pb={24}>
        <Text kind="h1">
          <Translation id="reservation.submit.title.main" />
        </Text>
      </Flex>
      <CardBoxNoStroke>
        <Flex $align="center" $direction="row">
          <Flex $pr={16}>
            <TherapistOverviewCardDetail therapyPath={therapyPath.type ?? 'MYSELF_PSYCHOTHERAPY'}>
              <Pressable>
                <Avatar image={profileImage?.s} name={fullName} size="lg" />
              </Pressable>
            </TherapistOverviewCardDetail>
          </Flex>
          <Flex $grow={1} $pl={8}>
            <Flex $pb={8}>
              <TranslationMarkdown
                fontWeight="400"
                id={titleTranslationId}
                kind="h3"
                values={{
                  therapistFullName: fullName,
                }}
              />
            </Flex>
            <TherapistOverviewCardDetail therapyPath={therapyPath.type ?? 'MYSELF_PSYCHOTHERAPY'}>
              <Pressable>
                <Text colorName="primary" kind="paragraph-strong" textDecoration="underline">
                  <Translation id="suggestedTherapist.moreInfo" />
                </Text>
              </Pressable>
            </TherapistOverviewCardDetail>
          </Flex>
        </Flex>
        <Flex $direction="row" $pl={8} $pt={16}>
          <Flex $pr={60}>
            <Text colorName="neutral-80" kind="paragraph">
              <Translation id="generic.date" />
            </Text>

            <Flex $pt={4}>
              <Text colorName="darker" fontWeight="600" kind="paragraph">
                {pipe(timeslot, getTherapySessionDayAndMonthShort)}
              </Text>
            </Flex>
          </Flex>

          <Flex>
            <Text colorName="neutral-80" kind="paragraph">
              <Translation id="generic.hour" />
            </Text>

            <Flex $pt={4}>
              <Text colorName="darker" fontWeight="600" kind="paragraph">
                {pipe(timeslot, getTherapySessionStartAt)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </CardBoxNoStroke>
    </>
  )
}

export const SubmitBookingContent = () => {
  const form = useReactHookFormContext<ReservationFormValues>()

  const { selectedTherapist: selectedTherapistControl, suggestedTherapistsProfiles } = useReservationFlow()

  const { therapyPath } = useSuspenseTherapyPathByIdQuery()

  const { selectedTimeSlot, therapistId } = form.getValues()

  const selectedTherapist = therapistId
    ? suggestedTherapistsProfiles.find(({ id }) => id === therapistId)
    : selectedTherapistControl

  const { pathname } = useRootLocation()

  const { availableChangeTherapistsFree, loading } = useAvailableChangeTherapistsFree()

  const isChangeTherapist = !!matchPath(pathname, { path: [getRoute('/change-therapist/:therapyId/booking')] })

  const costAsCurrency = toCurrencyIntOrDecimal(therapyPath.cost)

  const isChangeTherapistPaid = isChangeTherapist && availableChangeTherapistsFree === 0

  const { items, loading: itemsLoading } = usePaymentMethods()

  const firstExpectationTranslationId = useMemo((): TranslationId => {
    if (therapyPath.type === 'PATH_COACHING') {
      return 'reservation.submit.coaching.expectation1'
    }

    return 'reservation.submit.expectation1'
  }, [therapyPath])

  const secondExpectationTranslationId = useMemo((): TranslationId => {
    if (therapyPath.type === 'MYSELF_PSYCHIATRY') {
      return 'reservation.submit.psychiatrist.expectation2'
    }

    if (therapyPath.type === 'PATH_COACHING') {
      return 'reservation.submit.coaching.expectation2'
    }

    return 'reservation.submit.expectation2'
  }, [therapyPath])

  const thirdExpectationTranslationId = useMemo((): TranslationId => {
    if (therapyPath.type === 'MYSELF_PSYCHIATRY') {
      return items.length
        ? 'reservation.submit.psychiatrist.alreadyWithPaymentMethod.expectation3'
        : 'reservation.submit.psychiatrist.expectation3'
    }

    if (therapyPath.type === 'PATH_COACHING') {
      return 'reservation.submit.coaching.expectation3'
    }

    if (['PATH_NUTRITION_WEIGHT_LOSS', 'PATH_NUTRITION_DCA'].includes(therapyPath.type)) {
      return 'reservation.submit.nutritionist.expectation3'
    }

    return 'reservation.submit.expectation3'
  }, [items, therapyPath])

  if (!selectedTimeSlot || loading || itemsLoading) {
    return null
  }

  return (
    <>
      <Flex $pt={32}>
        {!!selectedTherapist && (
          <TherapistProvider therapist={selectedTherapist}>
            <SummaryWithTherapist timeslot={selectedTimeSlot} />
          </TherapistProvider>
        )}
      </Flex>

      <Flex $gap={16} $pt={32}>
        <Flex $direction="row">
          <Flex $pr={16} $pt={2}>
            <Icon colorName="neutral-60" name="calendar" size={24} />
          </Flex>

          {therapyPath.type === 'MYSELF_PSYCHIATRY' && (
            <Flex $pt={2} $shrink={1}>
              <TranslationMarkdown
                colorName="darker"
                fontWeight="400"
                id="reservation.submit.psychiatrist.expectation1"
                kind="h3"
              />
            </Flex>
          )}

          {!(therapyPath.type === 'MYSELF_PSYCHIATRY') && (
            <Flex $pt={2} $shrink={1}>
              {!isChangeTherapistPaid && (
                <TranslationMarkdown colorName="darker" fontWeight="400" id={firstExpectationTranslationId} kind="h3" />
              )}

              {isChangeTherapistPaid && (
                <TranslationMarkdown
                  colorName="darker"
                  fontWeight="400"
                  id="reservation.submit.expectation1.payed"
                  kind="h3"
                  values={{ cost: costAsCurrency }}
                />
              )}
            </Flex>
          )}
        </Flex>

        <Flex $direction="row">
          <Flex $pr={16} $pt={2}>
            <Icon colorName="neutral-60" name="edit-pencil" size={24} />
          </Flex>

          <Flex $pt={2} $shrink={1}>
            <TranslationMarkdown
              colorName="darker"
              fontWeight="400"
              id={secondExpectationTranslationId}
              kind="h3"
              values={{
                hour: pipe(
                  selectedTimeSlot,
                  subHours(getTherapySessionCancellationPolicyInHours(null)),
                  format(FormatDateEnum.HOURS_MINUTES),
                ),
                date: pipe(
                  selectedTimeSlot,
                  subHours(getTherapySessionCancellationPolicyInHours(null)),
                  format(FormatDateEnum.LONG_DAY_DATE_MONTH_NAME),
                ),
              }}
            />
          </Flex>
        </Flex>

        <Flex $direction="row">
          <Flex $pr={16} $pt={2}>
            <Icon colorName="neutral-60" name="finder" size={24} />
          </Flex>

          <Flex $pt={2} $shrink={1}>
            <TranslationMarkdown colorName="darker" fontWeight="400" id={thirdExpectationTranslationId} kind="h3" />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
