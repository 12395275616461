import { gql, useQuery } from '@apollo/client'
import { isNeitherNullNorUndefined, noop } from 'functions'
import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useTherapyIdLocationParam } from '~/domains/changeTherapist/hooks/useTherapyIdLocationParam'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { type FormFlow } from '~/domains/formFlow/types'
import { useMemoryRouterInitialOptions } from '~/domains/formFlow/utils/useMemoryRouterInitialOptions'
import { PatientTherapiesProvider, usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useReferralCodeName } from '~/domains/referral/hooks/useReferralCodeName'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { type FormFlowsQuery, type FormFlowsQueryVariables } from '~/types/graphql'
import { getFormFlowRoute } from '../utils/getFormFlowRoute'
import { getFormWhomTypeFromTherapy } from '../utils/getFormWhomTypeFromTherapy'
import { usePrefilledConceptIdOrName } from '../utils/usePrefilledConceptIdOrName'
import { usePrefilledForWhomType } from './usePrefilledForWhomType'

type Props = {
  type: 'COMPLETE' | 'COMPLETE_CHANGE_THERAPIST' | 'PREFERENCES_CHANGE_THERAPIST'
  children: ReactNode
  onError: () => void
}

type ProvidersProps = Omit<Props, 'type'> & {
  formFlows: FormFlowsQuery['formFlows']
}

const QUERY = gql`
  query formFlows($prefilledConceptIdOrName: String) {
    formFlows(prefilledConceptIdOrName: $prefilledConceptIdOrName) {
      id
      forWhomType
      type
      steps {
        __typename
        ... on FormFlowStepChoice {
          description
          end
          id
          label
          maxLength
          multiple
          name
          options {
            defaultValue
            description
            details {
              description
              title
            }
            fillable
            name
            other
            placeholder
            required
            target
            title
            references {
              value
              type
            }
          }
          parentOption
          parentStep
          placeholder
          required
          skip
          skipsByForWhomType {
            forWhomType
            name
          }
          suggestion
          target
          title
          titleOther
          titlePlural
          variant
        }
        ... on FormFlowStepStatement {
          autoSubmit
          description
          end
          id
          image {
            height
            url
            width
          }
          name
          parentOption
          parentStep
          skip
          suggestion
          target
          title
          titleOther
          titlePlural
          variant
        }
        ... on FormFlowStepText {
          description
          end
          fieldSuggestion
          id
          label
          maxLength
          minLength
          name
          parentOption
          parentStep
          placeholder
          required
          skip
          suggestion
          target
          title
          titleOther
          titlePlural
          type
          variant
        }
      }
    }
  }
`

type Context = {
  forWhomType: FormFlow['forWhomType'] | null
  flows: FormFlow[]
  initialEntries: string[]
  initialIndex: number
  isEnd: boolean
  loading: boolean
  prefilledConceptIdOrName: string | null
  setForWhomType: Dispatch<SetStateAction<FormFlow['forWhomType']>>
  setIsEnd: Dispatch<SetStateAction<boolean>>
  type: Props['type']
}

const defaultContext: Context = {
  forWhomType: null,
  flows: [],
  initialEntries: [],
  initialIndex: 0,
  isEnd: false,
  loading: true,
  prefilledConceptIdOrName: null,
  setForWhomType: noop,
  setIsEnd: noop,
  type: 'COMPLETE',
}

const FormFlowsContext = createContext<Context | null>(null)

const FormFlowsProviderAnonymous = ({ children, formFlows, onError }: ProvidersProps) => {
  const [forWhomType, setForWhomType] = useState<FormFlow['forWhomType'] | null>(null)
  const [isEnd, setIsEnd] = useState<boolean>(false)

  const { isAuthenticated } = useAuthState()
  const { isVariant } = useFeatureFlagsByFingerprint()
  const hasActiveReferral = useReferralCodeName()
  const prefilledForWhomType = usePrefilledForWhomType()

  const introduction = formFlows.find(({ type }) => type === 'INTRODUCTION')
  const pathologies = formFlows.filter(({ type }) => type === 'PATHOLOGY').filter(isNeitherNullNorUndefined)
  const preferences = formFlows.find(({ type }) => type === 'PREFERENCES')

  const showReferralPage = !isAuthenticated && isVariant('ff_new_mgm_page') && hasActiveReferral

  const memoryRouterInitialOptions = useMemoryRouterInitialOptions({
    formFlows,
    initialEntries: showReferralPage ? [getFormFlowRoute('/referral')] : undefined,
  })

  const prefilledConceptIdOrName = usePrefilledConceptIdOrName()

  useEffect(() => {
    if (!introduction || !preferences || !pathologies.length) {
      onError()

      return
    }
  }, [introduction, onError, pathologies, preferences])

  const value = useMemo(() => {
    if (!introduction || !preferences || !pathologies.length) {
      return defaultContext
    }

    return {
      ...defaultContext,
      ...memoryRouterInitialOptions,
      forWhomType: forWhomType ?? prefilledForWhomType,
      flows: [introduction, ...pathologies, preferences],
      isEnd,
      loading: false,
      prefilledConceptIdOrName,
      setForWhomType,
      setIsEnd,
      type: 'COMPLETE' as const,
    }
  }, [
    forWhomType,
    introduction,
    isEnd,
    memoryRouterInitialOptions,
    pathologies,
    preferences,
    prefilledConceptIdOrName,
    prefilledForWhomType,
  ])

  return <FormFlowsContext.Provider value={value}>{children}</FormFlowsContext.Provider>
}

const FormFlowsProviderChangeTherapistPreferences = ({ children, formFlows, onError }: ProvidersProps) => {
  const [forWhomType, setForWhomType] = useState<FormFlow['forWhomType'] | null>(null)
  const preferences = formFlows.find(({ type }) => type === 'PREFERENCES_CHANGE_THERAPIST')
  const therapyId = useTherapyIdLocationParam()
  const { therapies } = usePatientTherapies()
  const therapy = useMemo(() => therapies.find((therapy) => therapy.id === therapyId), [therapies, therapyId])
  const prefilledConceptIdOrName = usePrefilledConceptIdOrName()
  const memoryRouterInitialOptions = useMemoryRouterInitialOptions({
    formFlows,
    initialEntries: [getFormFlowRoute('/welcome')],
  })

  useEffect(() => {
    if (!preferences) {
      onError()

      return
    }
  }, [onError, preferences])

  useEffect(() => {
    if (therapy?.therapyPath?.type && !forWhomType) {
      setForWhomType(getFormWhomTypeFromTherapy(therapy?.therapyPath?.type))
    }
  }, [forWhomType, therapy])

  const value = useMemo(() => {
    if (!preferences) {
      return defaultContext
    }

    return {
      ...defaultContext,
      ...memoryRouterInitialOptions,
      forWhomType,
      flows: [preferences],
      loading: false,
      prefilledConceptIdOrName,
      type: 'PREFERENCES_CHANGE_THERAPIST' as const,
    }
  }, [forWhomType, memoryRouterInitialOptions, preferences, prefilledConceptIdOrName])

  return <FormFlowsContext.Provider value={value}>{children}</FormFlowsContext.Provider>
}

const FormFlowsProviderChangeTherapist = ({ children, formFlows, onError }: ProvidersProps) => {
  const [forWhomType, setForWhomType] = useState<FormFlow['forWhomType'] | null>(null)
  const pathologies = formFlows.filter(({ type }) => type === 'PATHOLOGY').filter(isNeitherNullNorUndefined)
  const preferences = formFlows.find(({ type }) => type === 'PREFERENCES_CHANGE_THERAPIST')
  const therapyId = useTherapyIdLocationParam()
  const { therapies } = usePatientTherapies()
  const therapy = useMemo(() => therapies.find((therapy) => therapy.id === therapyId), [therapies, therapyId])
  const prefilledConceptIdOrName = usePrefilledConceptIdOrName()
  const memoryRouterInitialOptions = useMemoryRouterInitialOptions({
    formFlows,
    initialEntries: [getFormFlowRoute('/welcome')],
  })

  useEffect(() => {
    if (!pathologies?.length || !preferences) {
      onError()

      return
    }
  }, [onError, pathologies?.length, preferences])

  useEffect(() => {
    if (therapy?.therapyPath?.type && !forWhomType) {
      setForWhomType(getFormWhomTypeFromTherapy(therapy?.therapyPath?.type))
    }
  }, [forWhomType, therapy])

  const value = useMemo(() => {
    if (!preferences) {
      return defaultContext
    }

    return {
      ...defaultContext,
      ...memoryRouterInitialOptions,
      forWhomType,
      flows: [...pathologies, preferences],
      prefilledConceptIdOrName,
      loading: false,
      type: 'COMPLETE_CHANGE_THERAPIST' as const,
    }
  }, [forWhomType, memoryRouterInitialOptions, pathologies, preferences, prefilledConceptIdOrName])

  return <FormFlowsContext.Provider value={value}>{children}</FormFlowsContext.Provider>
}

export const FormFlowsProvider = ({ children, onError, type }: Props) => {
  const { id, loading: loadingCurrentUser } = useCurrentUserNullable()

  const prefilledConceptIdOrName = usePrefilledConceptIdOrName()

  const {
    data,
    error,
    loading: loadingFormFlows,
  } = useQuery<FormFlowsQuery, FormFlowsQueryVariables>(QUERY, {
    variables: { prefilledConceptIdOrName },
  })

  const formFlows = data?.formFlows || []

  useEffect(() => {
    if (error || (!formFlows.length && !loadingFormFlows)) {
      onError()
    }
  }, [error, formFlows.length, loadingFormFlows, onError])

  if (loadingCurrentUser || loadingFormFlows) {
    return <CenteredLoader />
  }

  if (!loadingCurrentUser && !!id && type === 'PREFERENCES_CHANGE_THERAPIST') {
    return (
      <PatientTherapiesProvider>
        <FormFlowsProviderChangeTherapistPreferences formFlows={formFlows} onError={onError}>
          {children}
        </FormFlowsProviderChangeTherapistPreferences>
      </PatientTherapiesProvider>
    )
  }

  if (!loadingCurrentUser && !!id && type === 'COMPLETE_CHANGE_THERAPIST') {
    return (
      <PatientTherapiesProvider>
        <FormFlowsProviderChangeTherapist formFlows={formFlows} onError={onError}>
          {children}
        </FormFlowsProviderChangeTherapist>
      </PatientTherapiesProvider>
    )
  }

  return (
    <FormFlowsProviderAnonymous formFlows={formFlows} onError={onError}>
      {children}
    </FormFlowsProviderAnonymous>
  )
}

export const useFormFlowsNullable = () => {
  const formFlows = useContext(FormFlowsContext)

  if (!formFlows) {
    throw new Error('The `useFormFlowsNullable` should be wrapped with `FormFlowsProvider`.')
  }

  return formFlows
}

export const useFormFlows = () => {
  const formFlows = useFormFlowsNullable()

  return formFlows
}
