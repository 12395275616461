import { Flex, type FlexProps } from 'cdk'
import {
  BORDER_RADIUS_SM,
  BORDER_RADIUS_XS,
  ELEVATION_SM,
  ELEVATION_XS,
  SPACING_LG,
  SPACING_MD,
  SPACING_SM,
  SPACING_XS,
} from 'design-tokens'
import { useBreakpoints } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useCurrentUserProfessionTypeValue } from '~/hooks/useCurrentUserProfessionTypeValue'
import { getRoute } from '~/utils/getRoute'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { patientNavigationLinks } from '../constants/patientNavigationLinks'
import { therapistNavigationLinks } from '../constants/therapistNavigationLinks'
import { useTabNavigationMenu } from '../hooks/useTabNavigationMenu'
import { FlexWithTransition } from './common/FlexWithTransition'
import { LinkGesto } from './custom/LinkGesto'
import { LinkMenu } from './custom/LinkMenu'
import { LinkNotification } from './custom/LinkNotification'
import { LinkSupport } from './custom/LinkSupport'
import { PatientTabMenu } from './PatientTabMenu'
import { SideBarFooter } from './SideBarFooter'
import { SideBarHeader } from './SideBarHeader'
import { SideBarLinks } from './SideBarLinks'
import { TherapistTabMenu } from './TherapistTabMenu'

const IS_EXTENDED = 264
const IS_MINIFIED = 64

type SideBarContainerProps = { $width: number }

const SideBarContainer = styled(FlexWithTransition)
  .attrs<SideBarContainerProps>(({ $width }) => ({
    $backgroundColorName: 'lighter',
    $borderRadius: BORDER_RADIUS_SM,
    $elevationName: 'xs',
    $justify: 'space-between',
    $overflow: 'hidden',
    $px: SPACING_SM,
    $py: SPACING_LG,
    $basis: $width,
    $maxWidth: $width,
    $minWidth: $width,
  }))
  .withConfig({})`
    flex: 1;
  `

/**
 * TODO (amin-khayam)
 * - remove this components when the new page /settings is ready:
 *   - SideMenuWrapper
 *   - SideMenuWrapperFullScreen
 *   - SideMenuWrapperOverlayContainer
 *   - SideMenuWrapperOverlay
 */

const SideMenuWrapper = styled(Flex)<FlexProps>`
  overflow: hidden;
  height: calc(100vh - ${SPACING_MD} * 2);
  margin-top: 16px;
  border-radius: ${BORDER_RADIUS_XS};
  box-shadow: ${ELEVATION_XS};
`

type SideMenuWrapperFullScreenProps = FlexProps & { extended: boolean }

const SideMenuWrapperFullScreen = styled(Flex)<SideMenuWrapperFullScreenProps>`
  ${({ extended }) => css`
    position: fixed;
    inset: ${extended ? '0 0 0 288px' : '0 0 0 86px'};
    z-index: 100;
    transition: all 0.2s;
    elevation: ${ELEVATION_SM};
  `};
`

const SideMenuWrapperOverlayContainer = styled(Flex).attrs({ $grow: 1, $shrink: 1 })<FlexProps>`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 10%) 10%,
    rgb(0 0 0 / 20%) 25%,
    rgb(0 0 0 / 35%) 100%
  );
`

const SideMenuWrapperOverlay = ({ handleAutoClose }: { handleAutoClose: () => void }) => {
  const { closeMenu } = useTabNavigationMenu()

  const handleCloseClick = useCallback(() => {
    handleAutoClose()
    closeMenu()
  }, [closeMenu, handleAutoClose])

  return (
    <SideMenuWrapperOverlayContainer>
      <Flex $grow={1} $shrink={1} onClick={handleCloseClick} />
    </SideMenuWrapperOverlayContainer>
  )
}

export const SideBar = () => {
  const { isLarge } = useBreakpoints()
  const { isNutritionist } = useCurrentUserProfessionTypeValue()
  const { open } = useTabNavigationMenu()
  const { pathname } = useLocation()

  const forceSidebarClosed = !!matchPath(pathname, { path: [getRoute('/chat')], exact: false })

  const defaultExtendedState = isLarge ? 'extended' : 'minified'
  const storageDesktopNavigation = getLocalStorage('desktop-navigation') ?? defaultExtendedState
  const [isExtended, setIsExtended] = useState(storageDesktopNavigation === 'extended')
  const [collapseListener, setCollapseListener] = useState(isLarge)

  const handleExtended = useCallback(() => {
    setIsExtended((value) => !value)
    setLocalStorage('desktop-navigation', isExtended ? 'minified' : 'extended')
  }, [isExtended])

  const sideWidth = isExtended ? IS_EXTENDED : IS_MINIFIED

  const isTherapist = useUserActingAsGroup() === 'therapist'
  const links = isTherapist ? therapistNavigationLinks : patientNavigationLinks()

  const handleAutoClose = useCallback(() => {
    if (isExtended && !isLarge) {
      handleExtended()
    }
  }, [handleExtended, isExtended, isLarge])

  // When the view goes from large to small, the sidebar should be collapsed
  useEffect(() => {
    if (collapseListener && isExtended && !isLarge) {
      handleExtended()
      setCollapseListener(false)
    }

    if (isLarge && !collapseListener) {
      setCollapseListener(true)
    }
  }, [collapseListener, handleExtended, isExtended, isLarge])

  useEffect(() => {
    if (matchPath(pathname, { path: [getRoute('/chat')], exact: false }) && isExtended) {
      handleExtended()
    }
    // Note (amin-khayam) this effect has to be triggered *only* when the pathname changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <>
      <Flex
        $minHeight="100vh"
        $p={SPACING_MD}
        $position={!forceSidebarClosed && isLarge ? 'relative' : 'fixed'}
        $pr={0}
        $zIndex={1}
        as="nav"
      >
        <SideBarContainer $width={sideWidth}>
          <SideBarHeader extended={isExtended} />
          <Flex $gap={SPACING_XS} $py={SPACING_MD}>
            <SideBarLinks extended={isExtended} links={links} onLinkClick={handleAutoClose} />
            <LinkMenu extended={isExtended} />
          </Flex>

          <Flex $py={SPACING_XS}>
            <LinkSupport extended={isExtended} onLinkClick={handleAutoClose} />
            {isTherapist && <LinkNotification extended={isExtended} onLinkClick={handleAutoClose} />}
            {isTherapist && !isNutritionist && <LinkGesto extended={isExtended} onLinkClick={handleAutoClose} />}
          </Flex>

          <SideBarFooter
            extended={isExtended}
            onAvatarClick={handleAutoClose}
            onIconClick={handleExtended}
            showIcon={true}
          />
        </SideBarContainer>
      </Flex>

      {open && (
        <SideMenuWrapperFullScreen $direction="row" extended={isExtended}>
          <SideMenuWrapper $backgroundColorName="lighter" $basis="360px" $zIndex={2}>
            {isTherapist ? (
              <TherapistTabMenu handleAutoClose={handleAutoClose} />
            ) : (
              <PatientTabMenu handleAutoClose={handleAutoClose} />
            )}
          </SideMenuWrapper>

          <SideMenuWrapperOverlay handleAutoClose={handleAutoClose} />
        </SideMenuWrapperFullScreen>
      )}
    </>
  )
}
